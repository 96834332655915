import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (worker: { waiting: { postMessage: (arg0: { type: string }) => void } }) => {
    if (worker && worker.waiting) {
      worker.waiting.postMessage({ type: 'SKIP_WAITING' }); // TODO add prompt for new version
    }
  },
});
