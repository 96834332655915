import { Button, FormControlLabel, FormGroup, Switch, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ShutdownPolicy as ShutdownPolicyOptions } from '../../api/deployments';
import { Form, ActionContainer } from './styledComponents';
import { buttonColor, colors, sizes } from '../../theme/designtoken';

type ShutdownPolicyProps = {
  onSuccess: (policy: ShutdownPolicyOptions) => void;
  onPrevious: () => void;
  shutdownPolicy?: ShutdownPolicyOptions;
  previousButtonText?: string;
  successButtonText?: string;
};

function ShutdownPolicy({
  onSuccess,
  onPrevious,
  shutdownPolicy,
  previousButtonText = 'Back',
  successButtonText = 'Next',
}: ShutdownPolicyProps) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm<{
    enabled: boolean;
    inactiveTimeInMinutes: number;
    dateBasedShutdown: boolean;
    daysForShutdown: ShutdownPolicyOptions['daysForShutdown'];
    timeOfShutdown: Date;
  }>({
    defaultValues: {
      enabled: shutdownPolicy?.enabled || false,
      inactiveTimeInMinutes: shutdownPolicy?.inactiveTimeInMinutes || 120,
      dateBasedShutdown: shutdownPolicy?.dateBasedShutdown || false,
      daysForShutdown: shutdownPolicy?.daysForShutdown || ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
      timeOfShutdown: shutdownPolicy?.hourForShutdown
        ? new Date(Date.UTC(1970, 1, 1, shutdownPolicy.hourForShutdown, shutdownPolicy.minuteForShutdown))
        : new Date('1970-01-01T20:00:00'),
    },
  });
  const [locale, setLocale] = useState<Locale>();
  const isIdleEnabled = watch('enabled');
  const isDailyEnabled = watch('dateBasedShutdown');

  useEffect(() => {
    // dynamically import locale
    const importLocaleFile = async () => {
      try {
        const localeToSet = await import(`date-fns/locale/${navigator.language}/index.js`);
        setLocale(localeToSet.default);
      } catch (_) {}
    };
    if (process.env.NODE_ENV !== 'test') {
      importLocaleFile();
    }
  }, []);

  return (
    <Form
      onSubmit={handleSubmit((values) =>
        onSuccess({
          enabled: values.enabled,
          inactiveTimeInMinutes: Number(values.inactiveTimeInMinutes),
          dateBasedShutdown: values.dateBasedShutdown,
          daysForShutdown: values.daysForShutdown,
          hourForShutdown: values.timeOfShutdown.getUTCHours(),
          minuteForShutdown: values.timeOfShutdown.getUTCMinutes(),
        }),
      )}
    >
      <LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
        <Controller
          name="enabled"
          control={control}
          render={({ field }) => (
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={field.value} {...field} color="success" />}
                label="Enable idle shutdown"
              />
            </FormGroup>
          )}
        />
        <Controller
          name="inactiveTimeInMinutes"
          control={control}
          rules={{ required: 'Please select a time', min: 30, max: 1440 }}
          render={({ field }) => (
            <TextField
              label="Inactive time (minutes)"
              type="number"
              {...field}
              error={!!errors['inactiveTimeInMinutes']}
              helperText={errors['inactiveTimeInMinutes']?.message}
              disabled={!isIdleEnabled}
            />
          )}
        />
        <Controller
          name="dateBasedShutdown"
          control={control}
          render={({ field }) => (
            <FormGroup>
              <FormControlLabel
                control={<Switch color="success" checked={field.value} {...field} />}
                label="Enable daily shutdown"
              />
            </FormGroup>
          )}
        />
        <Controller
          name="timeOfShutdown"
          control={control}
          render={({ field }) => (
            <TimePicker
              label="Shutdown time"
              disabled={!isDailyEnabled}
              {...field}
              renderInput={(params) => <TextField {...params} sx={{ marginTop: 1, marginBottom: 1 }} />}
            />
          )}
        />
        <Controller
          name="daysForShutdown"
          control={control}
          render={({ field }) => (
            <ToggleButtonGroup
              {...field}
              onChange={(event, val) => {
                setValue('daysForShutdown', val);
              }}
              disabled={!isDailyEnabled}
              color="standard"
            >
              <ToggleButton value="mon">Mon</ToggleButton>
              <ToggleButton value="tue">Tue</ToggleButton>
              <ToggleButton value="wed">Wed</ToggleButton>
              <ToggleButton value="thu">Thu</ToggleButton>
              <ToggleButton value="fri">Fri</ToggleButton>
              <ToggleButton value="sat">Sat</ToggleButton>
              <ToggleButton value="sun">Sun</ToggleButton>
            </ToggleButtonGroup>
          )}
        />

        <ActionContainer>
          <Button variant="outlined" onClick={() => onPrevious()} sx={{ bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}>
            {previousButtonText}
          </Button>
          <Button type="submit" variant="outlined" sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}>
            {successButtonText}
          </Button>
        </ActionContainer>
      </LocalizationProvider>
    </Form>
  );
}

export default ShutdownPolicy;
