import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { startCase } from 'lodash';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Permission, useCreateUserRole, usePermissions } from '../../../api/user';
import Loading from '../../../common/Loading';
import { useSnackbar } from '../../../contexts/Snackbar';
import { buttonColor, colors, purpleColor, radious } from '../../../theme/designtoken';

type CreateRoleForm = {
  name: string;
  description: string;
  roleType: 'global' | 'project';
  permissions: Record<string, Permission>;
};

type CreateRoleDialogProps = {
  open: boolean;
  onClose: () => void;
};
function CreateRoleDialog({ open, onClose }: CreateRoleDialogProps) {
  const { control, handleSubmit, watch, setValue } = useForm<CreateRoleForm>({
    defaultValues: { name: '', description: '', permissions: {}, roleType: 'global' },
  });
  const roleType = watch('roleType');
  const { data, isLoading } = usePermissions({ scope: roleType });
  const { openSnackbar } = useSnackbar();
  const { mutate, isLoading: isMutating } = useCreateUserRole({
    onSuccess: () => {
      openSnackbar('Role created successfully', { alert: 'success' });
      onClose();
    },
    onError: () => openSnackbar('Failed to create role', { alert: 'error' }),
  });
  const selectedPermissions = watch('permissions');

  useEffect(() => {
    setValue('permissions', {});
  }, [roleType, setValue]);

  function toggleSelectedPermission(permission: Permission) {
    const updatedPermission = { ...selectedPermissions };
    if (selectedPermissions[permission['value']]) {
      delete updatedPermission[permission['value']];
      return setValue('permissions', updatedPermission, { shouldValidate: true });
    }
    updatedPermission[permission['value']] = permission;
    return setValue('permissions', updatedPermission, { shouldValidate: true });
  }

  return (
    <Dialog open={open} maxWidth="lg" scroll="paper">
      <DialogTitle>Create Role</DialogTitle>
      <form onSubmit={handleSubmit((values) => mutate({ ...values, permissions: Object.keys(values.permissions) }))}>
        <DialogContent sx={{ minWidth: '500px', display: 'flex', flexDirection: 'column', gap: 1, pt: 1 }}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: 'Name is required',
              minLength: { value: 2, message: 'Must be at least 2 characters' },
              maxLength: { value: 40, message: 'Must be at less than 40 characters' },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label="Name"
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{
              required: 'Description is required',
              minLength: { value: 2, message: 'Must be at least 2 characters' },
              maxLength: { value: 256, message: 'Must be at less than 256 characters' },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label="Description"
                multiline
                rows={4}
              />
            )}
          />
          <Controller
            name="roleType"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl>
                <FormLabel id="role-type">Type</FormLabel>
                <RadioGroup aria-labelledby="role-type" {...field} row>
                  <FormControlLabel value="global" control={<Radio color="default" />} label="Global" />
                  <FormControlLabel value="project" control={<Radio color="default" />} label="Project" />
                </RadioGroup>
              </FormControl>
            )}
          />
          <FormLabel>Select permissions for this role</FormLabel>

          {isLoading ? (
            <Loading />
          ) : (
            <Controller
              name="permissions"
              control={control}
              rules={{
                validate: (vl) => (vl && Object.keys(vl).length > 0 ? true : 'At least one permission is required'),
              }}
              render={({ field, fieldState }) => (
                <>
                  {fieldState.error?.message && (
                    <Typography variant="body2" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )}
                  <List sx={{ overflow: 'auto', maxHeight: fieldState.error?.message ? '42vh' : '49vh' }}>
                    {data?.permissionCategories.map((category) => (
                      <li key={category.name}>
                        <ul>
                          <ListSubheader>{startCase(category.name)}</ListSubheader>
                          {category.permissions.map((permission) => (
                            <ListItem key={permission.value}>
                              <ListItemButton onClick={() => toggleSelectedPermission(permission)} dense>
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={!!selectedPermissions[permission.value]}
                                    tabIndex={-1}
                                    disableRipple
                                    color="success"
                                  />
                                </ListItemIcon>
                                <ListItemText>{startCase(permission.permission)}</ListItemText>
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </List>
                </>
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => onClose()} disabled={isMutating} sx={{ bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" color="primary" disabled={isMutating} sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }}>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CreateRoleDialog;
