import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useUsers, useRoles, useUpdateUserRole, useUser } from '../../../api/user';
import Loading from '../../../common/Loading';
import { useSnackbar } from '../../../contexts/Snackbar';

function EditUsersRoles() {
  const { data: currentUser } = useUser();
  const { data: users, isLoading: isLoadingUsers } = useUsers();
  const { data: roles, isLoading: isLoadingRoles } = useRoles({ roleType: 'global' });
  const [selectedRole, setSelectedRole] = useState('');
  const [warningUser, setWarningUser] = useState<{
    email: string;
    roleUUID: string | null;
  }>();
  const { openSnackbar } = useSnackbar();
  const { mutate, isLoading: isUpdating } = useUpdateUserRole({
    onSuccess: () => openSnackbar('User role updated!', { alert: 'success' }),
    onError: () => openSnackbar('Failed to update user role', { alert: 'error' }),
  });

  useEffect(() => {
    if (roles && selectedRole === '') {
      setSelectedRole(roles[0].UUID);
    }
  }, [roles, selectedRole]);

  function updateRole(
    user: {
      email: string;
      roleUUID: string | null;
    },
    roleValue: string | null,
    warnCurrentUser = true,
  ) {
    if (warnCurrentUser && currentUser?.email === user.email) {
      return setWarningUser(user);
    }
    if (user.roleUUID === roleValue) {
      return mutate({ user: user.email, roleUUID: null });
    }
    return mutate({ user: user.email, roleUUID: roleValue });
  }

  if (isLoadingUsers || isLoadingRoles) {
    return <Loading />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} aria-label="Assign global roles">
      <RadioGroup
        row
        value={selectedRole}
        onChange={(event) => setSelectedRole((event.target as HTMLInputElement).value)}
        sx={{ pl: 0.5 }}
        aria-labelledby="role"
      >
        {roles?.map((role) => (
          <FormControlLabel
            label={role.roleName}
            value={role.UUID}
            key={role.UUID}
            control={<Radio color="default" />}
          />
        ))}
      </RadioGroup>
      <Box sx={{ overflowY: 'auto', flexGrow: '1', display: 'flex', minHeight: 1, maxHeight: 1 }}>
        <List dense sx={{ width: '100%', maxHeight: '40vh' }}>
          {users?.map((user) => (
            <ListItem
              key={user.email}
              disablePadding
              secondaryAction={
                user.roleUUID && (
                  <Chip
                    label={roles?.find((role) => role.UUID === user.roleUUID)?.roleName}
                    size="small"
                    variant="outlined"
                  />
                )
              }
            >
              <ListItemButton
                onClick={() => updateRole(user, selectedRole)}
                dense
                sx={{ pl: 0.5 }}
                disabled={isUpdating}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={user.roleUUID === selectedRole}
                    tabIndex={-1}
                    disableRipple
                    color="default"
                  />
                </ListItemIcon>
                <ListItemText>
                  {user.firstName && user.lastName && `${user.firstName} ${user.lastName} - ${user.email}`}
                  {user.firstName && !user.lastName && `${user.firstName} - ${user.email}`}
                  {!user.firstName && !user.lastName && user.email}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Dialog open={!!warningUser}>
        <DialogTitle>Change your user role?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You're about to change your own role. This may lead to loss of functionality and you may not be able to
            reverse this yourself. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWarningUser(undefined)}>Cancel</Button>
          <Button
            onClick={() => {
              warningUser && updateRole(warningUser, selectedRole, false);
              setWarningUser(undefined);
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default EditUsersRoles;
