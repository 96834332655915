import { CircularProgress, Box } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { Route as ReactRoute, Redirect, useHistory, RouteProps as RouterProps } from 'react-router-dom';
import { useUser } from '../api/user';
import Layout from '../layout/Layout';
import { useSnackbar } from '../contexts/Snackbar';
import { useApi } from '../contexts/Api';
import { checkPermissions, Permission } from '../utils/permissions';

type RouteProps = {
  privateRoute?: boolean;
  requiredPermissions?: Permission;
} & RouterProps;

export const Route: FunctionComponent<RouteProps> = ({
  children,
  privateRoute = true,
  requiredPermissions,
  ...props
}) => {
  const history = useHistory();
  const { isAuthenticated } = useApi();
  const { isLoading, data: user } = useUser();
  const { openSnackbar } = useSnackbar();
  const [hasPermission, setHasPermission] = useState(true);

  useEffect(() => {
    if (hasPermission && user && requiredPermissions) {
      const hasPermissions = checkPermissions(user.permissions, requiredPermissions);
      if (!hasPermissions) {
        openSnackbar('You do not have permission to access this page', { alert: 'error' });
        setHasPermission(false);
      }
    }
    if (!hasPermission && !requiredPermissions) {
      setHasPermission(true); // reset after redirect
    }
  }, [hasPermission, openSnackbar, requiredPermissions, user]);

  if (privateRoute && !isAuthenticated) {
    history.push('/login');
  }

  if (isLoading || !user) {
    return (
      <ReactRoute {...props}>
        <Layout>
          <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', height: '100%' }}>
            <CircularProgress aria-label="Loading route" color="primary" />
          </Box>
        </Layout>
      </ReactRoute>
    );
  }

  if (!hasPermission) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <ReactRoute {...props}>
      <Layout>{children}</Layout>
    </ReactRoute>
  );
};

export default Route;
