import { FunctionComponent, useState } from 'react';
import {
  Paper,
  Typography,
  CardHeader,
  Card,
  CardContent,
  CircularProgress,
  Chip,
  IconButton,
  useMediaQuery,
  Box,
  styled,
} from '@mui/material';

import { DesktopWindows, Edit, Delete, PlayArrow, Stop, Sync, Article } from '@mui/icons-material';
import { useHistory, useParams } from 'react-router-dom';
import { startCase } from 'lodash';
import axios from 'axios';
import {
  useDeploymentMetrics,
  useUpdateDeploymentPowerStateAdmin,
  useDeploymentEvents,
  useDeployment,
} from '../../../api/deployments';
import EditWorkstation from '../edit/EditWorkstation';
import DeleteWorkstation from '../DeleteWorkstation';
import { useSnackbar } from '../../../contexts/Snackbar';
import MetricsLineChart from '../../../common/TimeSeriesLineChart';
import EventTable from './EventTable';

const runningStateStyle = { color: 'white', backgroundColor: 'success.main' } as const;
const stoppedStateStyle = { color: 'error.contrastText', backgroundColor: 'error.main' } as const;
const chartContainer = { minHeight: '300px', display: 'flex', flexDirection: 'column' } as const;
const chartContainerSpan = { ...chartContainer, gridColumnEnd: 'span 2' } as const;
const chartContent = {
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  overflowY: 'auto',
  pt: 0,
} as const;
const actionProgress = { position: 'absolute', zIndex: 1, left: 6, top: 6 } as const;
const Image = styled('img')({});

export const WorkstationDetails: FunctionComponent<{ isAdmin: boolean }> = ({ isAdmin }) => {
  const { workstationId } = useParams<{ workstationId: string }>();
  const [powerStateChanged, setPowerStateChanged] = useState(false);
  const { data: deployment } = useDeployment(workstationId, isAdmin, {
    refetchInterval: powerStateChanged ? 60000 : false,
    refetchIntervalInBackground: true,
    onError: (err) => {
      if (err.response?.status === 404) {
        openSnackbar("Sorry this deployment doesn't exist", { alert: 'error' });
        history.push(isAdmin ? '/admin/deployments' : '/workstations');
      }
    },
  });
  const { isLoading: isMetricsLoading, data: metricsData } = useDeploymentMetrics(workstationId, isAdmin, {
    refetchInterval: powerStateChanged ? 60000 : false,
    refetchIntervalInBackground: true,
  });
  const { isLoading: isEventsLoading, data: eventData } = useDeploymentEvents(workstationId, isAdmin, {
    refetchInterval: powerStateChanged ? 60000 : false,
    refetchIntervalInBackground: true,
  });
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const {
    isLoading: powerStateUpdating,
    mutate: updatePowerState,
    variables,
  } = useUpdateDeploymentPowerStateAdmin(isAdmin, {
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const errData = err?.response?.data;
        if (errData?.message) {
          return openSnackbar(errData.message, { alert: 'error' });
        }
      }
      openSnackbar('Sorry there was a problem updating the power state', { alert: 'error' });
    },
  });
  const largeScreen = useMediaQuery('(min-width:950px)');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexGrow: 1, paddingBottom: 2 }}>
      <Paper
        sx={{ display: 'flex', height: '50px', alignItems: 'center', pl: 2, pr: 2, justifyContent: 'space-between' }}
        elevation={0}
        variant="outlined"
        square
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <DesktopWindows />
          <Typography variant="h6">{deployment?.name}</Typography>
          <Chip
            label={startCase(deployment?.powerState)}
            size="small"
            sx={
              deployment?.powerState === 'running'
                ? runningStateStyle
                : deployment?.powerState === 'stopped'
                ? stoppedStateStyle
                : {}
            }
          />
        </Box>
        {deployment && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconButton
              onClick={() => {
                updatePowerState({ UUID: deployment.UUID, powerState: 'start' });
                setPowerStateChanged(true);
              }}
              color="primary"
              aria-label="Start workstation"
              title="Start workstation"
              disabled={powerStateUpdating || deployment.powerState === 'running' || deployment.powerState === 'stopping' || deployment.powerState==='pending'}
              size="large"
            >
              <>
                <PlayArrow />
                {powerStateUpdating && variables?.powerState === 'start' && (
                  <CircularProgress sx={actionProgress} size={24} />
                )}
              </>
            </IconButton>
            <IconButton
              onClick={() => {
                updatePowerState({ UUID: deployment.UUID, powerState: 'stop' });
                setPowerStateChanged(true);
              }}
              color="primary"
              aria-label="Stop workstation"
              title="Stop workstation"
              disabled={
                powerStateUpdating || deployment.powerState === 'stopped' || deployment.powerState === 'stopping' || deployment.powerState==='pending'
              }
              size="large"
            >
              <>
                <Stop />
                {powerStateUpdating && variables?.powerState === 'stop' && (
                  <CircularProgress sx={actionProgress} size={24} />
                )}
              </>
            </IconButton>
            <IconButton
              onClick={() => {
                updatePowerState({ UUID: deployment.UUID, powerState: 'reboot' });
                setPowerStateChanged(true);
              }}
              color="primary"
              disabled={powerStateUpdating || deployment.powerState === 'stopped' || deployment.powerState === 'stopping' || deployment.powerState==='pending'}
              title="Restart workstation"
              size="large"
            >
              <>
                <Sync />
                {powerStateUpdating && variables?.powerState === 'reboot' && (
                  <CircularProgress sx={actionProgress} size={24} />
                )}
              </>
            </IconButton>

            {isAdmin && (
              <>
                <IconButton
                  onClick={() => history.push(`${workstationId}/logs`)}
                  color="primary"
                  aria-label="View logs"
                  title="View logs"
                  size="large"
                >
                  <Article />
                </IconButton>
              </>
            )}
          </Box>
        )}
      </Paper>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'minmax(325px, 1fr) repeat(auto-fit, minmax(317px, 2fr))',
          gap: 2,
          flexGrow: 1,
        }}
      >
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gridRowEnd: !!(eventData?.pages[0] && eventData?.pages[0].events.length > 4) ? 'span 2' : 1,
          }}
        >
          <CardHeader title="Events" titleTypographyProps={{ align: 'center' }} />
          <CardContent
            sx={{
              display: 'flex',
              overflowY: 'auto',
              justifyContent: !!eventData?.pages[0].events.length ? 'flex-start' : 'center',
              alignItems: !!eventData?.pages[0].events.length ? 'flex-start' : 'center',
              flexGrow: 1,
              pt: 0,
            }}
          >
            {isEventsLoading ? (
              <CircularProgress />
            ) : (
              <>
                {eventData?.pages[0].events.length ? (
                  <EventTable data={eventData?.pages[0].events} />
                ) : (
                  <Typography align="center">No timeline events available</Typography>
                )}
              </>
            )}
          </CardContent>
        </Card>
        {deployment?.originalURL && (
          <Card sx={chartContainer}>
            <CardHeader title="Preview" titleTypographyProps={{ align: 'center' }} />
            <CardContent>
              <Image
                src={deployment.originalURL}
                alt="Workstation Preview"
                sx={{ width: '100%', height: '100%', objectFit: 'scale-down' }}
              />
            </CardContent>
          </Card>
        )}
        <Card
          sx={
            metricsData?.cpu.MetricDataResults[0].datapoints.length && largeScreen ? chartContainerSpan : chartContainer
          }
        >
          <CardHeader title="CPU Utilization" titleTypographyProps={{ align: 'center', gutterBottom: false }} />
          <CardContent sx={chartContent} aria-label="CPU Utilization">
            {isMetricsLoading ? (
              <CircularProgress />
            ) : (
              <>
                {metricsData?.cpu.MetricDataResults[0].datapoints.length ? (
                  <MetricsLineChart
                    data={metricsData?.cpu.MetricDataResults[0].datapoints}
                    startTime={new Date(`${metricsData?.cpu.MetricDataResults[0].datapoints[0].timeStamp}`).getTime()}
                    endTime={new Date(`${metricsData?.cpu.endTime}Z`).getTime()}
                    yAxisUnit={metricsData?.cpu.unit === 'percent' ? '%' : metricsData?.cpu.unit}
                    yAxisLabel="CPU"
                  />
                ) : (
                  <Typography align="center">No cpu data available</Typography>
                )}
              </>
            )}
          </CardContent>
        </Card>
        <Card
          sx={
            metricsData?.memory.MetricDataResults[0].datapoints.length && largeScreen
              ? chartContainerSpan
              : chartContainer
          }
        >
          <CardHeader title="Memory Utilization" titleTypographyProps={{ align: 'center' }} />
          <CardContent sx={chartContent}>
            {isMetricsLoading ? (
              <CircularProgress />
            ) : (
              <>
                {metricsData?.memory.MetricDataResults[0].datapoints.length ? (
                  <MetricsLineChart
                    data={metricsData?.memory.MetricDataResults[0].datapoints}
                    startTime={new Date(
                      `${metricsData?.memory.MetricDataResults[0].datapoints[0].timeStamp}`,
                    ).getTime()}
                    endTime={new Date(`${metricsData?.memory.endTime}Z`).getTime()}
                    yAxisUnit={metricsData?.memory.unit}
                    yAxisLabel="Memory"
                  />
                ) : (
                  <Typography align="center">No memory data available</Typography>
                )}
              </>
            )}
          </CardContent>
        </Card>
        <Card
          sx={
            metricsData?.networkIn.MetricDataResults[0].datapoints.length && largeScreen
              ? chartContainerSpan
              : chartContainer
          }
        >
          <CardHeader title="Network In" titleTypographyProps={{ align: 'center' }} />
          <CardContent sx={chartContent}>
            {isMetricsLoading ? (
              <CircularProgress />
            ) : (
              <>
                {metricsData?.networkIn.MetricDataResults[0].datapoints.length ? (
                  <MetricsLineChart
                    data={metricsData.networkIn.MetricDataResults[0].datapoints}
                    startTime={new Date(
                      `${metricsData?.networkIn.MetricDataResults[0].datapoints[0].timeStamp}`,
                    ).getTime()}
                    endTime={new Date(`${metricsData?.networkIn.endTime}Z`).getTime()}
                    yAxisUnit={metricsData?.networkIn.unit}
                    yAxisLabel="Network In"
                  />
                ) : (
                  <Typography align="center">No network data available</Typography>
                )}
              </>
            )}
          </CardContent>
        </Card>
        <Card
          sx={
            metricsData?.networkOut.MetricDataResults[0].datapoints.length && largeScreen
              ? chartContainerSpan
              : chartContainer
          }
        >
          <CardHeader title="Network Out" titleTypographyProps={{ align: 'center' }} />
          <CardContent sx={chartContent}>
            {isMetricsLoading ? (
              <CircularProgress />
            ) : (
              <>
                {metricsData?.networkOut.MetricDataResults[0].datapoints.length ? (
                  <MetricsLineChart
                    data={metricsData.networkOut.MetricDataResults[0].datapoints}
                    startTime={new Date(
                      `${metricsData?.networkOut.MetricDataResults[0].datapoints[0].timeStamp}`,
                    ).getTime()}
                    endTime={new Date(`${metricsData.networkOut.endTime}Z`).getTime()}
                    yAxisUnit={metricsData?.networkOut.unit}
                    yAxisLabel="Network out"
                  />
                ) : (
                  <Typography align="center">No network data available</Typography>
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Box>
      {isAdmin && deployment && openEdit && (
        <EditWorkstation deployment={deployment} open={openEdit} onClose={() => setOpenEdit(false)} />
      )}
      {isAdmin && deployment && openDelete && (
        <DeleteWorkstation
          deployment={deployment}
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          onSuccess={() => {
            openSnackbar('Deployment deleted successfully', { alert: 'success' });
            history.push('/admin/deployments');
          }}
        />
      )}
    </Box>
  );
};

export default WorkstationDetails;
