import { FunctionComponent } from 'react';
import { Dialog, DialogContent, Typography, Button, DialogActions, DialogTitle, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { red } from '@mui/material/colors';
import { Deployment, useDeleteDeployment } from '../../api/deployments';
import { useSnackbar } from '../../contexts/Snackbar';

type DeleteWorkstationDialog = {
  open: boolean;
  onClose: () => void;
  deployment: Deployment;
  onSuccess?: () => void;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    height: '100%',
    width: '100%',
  },
  actions: {
    paddingRight: 12,
  },
}));

const RedButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

export const DeleteWorkstation: FunctionComponent<DeleteWorkstationDialog> = ({
  open,
  onClose,
  deployment,
  onSuccess,
}) => {
  const classes = useStyles();
  const { openSnackbar } = useSnackbar();
  const type = deployment.type === 'dcv' ? 'deployment' : deployment.type;
  const { mutate, isLoading: isDeleting } = useDeleteDeployment({
    onSuccess: () => {
      onSuccess && onSuccess();
      onClose();
    },
    onError: (error) => {
      if (error.response?.data.message) {
        return openSnackbar(error.response?.data.message);
      }
      openSnackbar(`Sorry there was a problem deleting the ${type}`);
    },
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" aria-label="Delete workstation">
      <DialogTitle>Delete {type}</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this {type}?</Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={() => onClose()} variant="contained" disabled={isDeleting}>
          Cancel
        </Button>
        <RedButton onClick={() => mutate(deployment.UUID)} variant="contained" color="primary" disabled={isDeleting}>
          {isDeleting ? `Deleting ${type}...` : `Delete ${type}`}
        </RedButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteWorkstation;
