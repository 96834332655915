import axios, { CancelToken } from 'axios';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { matchPath, useHistory, useLocation } from 'react-router';
import { useApi, Get, Post, Put, Delete } from '../contexts/Api';
import { useSnackbar } from '../contexts/Snackbar';

type Project = {
  UUID: string;
  createdBy: { userName: string; ip: string };
  dateAddedInMicroSeconds: number;
  description: string;
  projectName: string;
};

type ProjectsResponse = {
  lastKey: null | { UUID: string; dateAddedInMicroSeconds: number; project: 'project' };
  projects: Project[];
};

export type ProjectsSearchResponse = {
  marker: null | { UUID: string; dateAddedInMicroSeconds: number; project: 'project' };
  response: {
    current: number;
    hits: Project[];
    total: number;
  };
};

async function fetchProjects(
  get: Get,
  post: Post,
  searchTerm?: string,
  lastKey?: ProjectsResponse['lastKey'],
  cancelToken?: CancelToken,
) {
  if (searchTerm) {
    const body = {
      queryString: `*${searchTerm}*`,
      queryFields: ['projectName_lowercase.keyword', 'description.keyword'],
      showDeleted: false,
      itemType: 'project',
    };
    const { data } = await post<ProjectsSearchResponse>('/projects/search', body, {
      params: { count: false, lastKey },
      cancelToken,
    });
    return { lastKey: data.marker, projects: data.response.hits };
  }
  const { data } = await get<ProjectsResponse>('/projects', { params: { lastKey }, cancelToken });
  return data;
}

type CancelablePromise<T> = Promise<T> & { cancel?: () => void };

export function useProjects(searchTerm?: string): UseInfiniteQueryResult<ProjectsResponse> {
  const { get, post } = useApi();
  return useInfiniteQuery<ProjectsResponse>(
    ['projects', searchTerm],
    ({ pageParam }) => {
      const source = axios.CancelToken.source();
      const promise: CancelablePromise<ProjectsResponse> = fetchProjects(
        get,
        post,
        searchTerm,
        pageParam,
        source.token,
      );
      promise.cancel = () => {
        source.cancel('Query cancelled by React Query');
      };
      return promise;
    },
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 10,
      getNextPageParam: (lastPage) => lastPage.lastKey,
    },
  );
}

export type SearchResponse<T> = {
  marker: null | { UUID: string; dateAddedInMicroSeconds: number; project: 'project' };
  response: {
    current: number;
    hits: T[];
    total: number;
  };
};

type ElasticSearchParams = {
  post: Post;
  searchQuery: Record<string, unknown>;
  lastKey?: null | { UUID: string; dateAddedInMicroSeconds: number; project: 'project' };
  cancelToken?: CancelToken;
};

async function queryElasticSearch<T>({ post, searchQuery, cancelToken, lastKey }: ElasticSearchParams) {
  const { data } = await post<SearchResponse<T>>('/projects/search', searchQuery, {
    params: { count: false, lastKey },
    cancelToken,
  });
  return data;
}

type SearchParams = {
  queryString?: string;
  queryFields?: string[];
  showDeleted: boolean;
  itemType: 'project' | 'job' | 'version';
  startRange?: number;
  endRange?: number;
};

export function useSearch<T>(
  searchQuery: SearchParams,
  options: { enabled: boolean } = { enabled: true },
): UseInfiniteQueryResult<SearchResponse<T>> {
  const { post } = useApi();
  return useInfiniteQuery<SearchResponse<T>>(
    ['search', searchQuery],
    ({ pageParam }) => {
      const source = axios.CancelToken.source();
      const promise: CancelablePromise<SearchResponse<T>> = queryElasticSearch({
        post,
        searchQuery,
        lastKey: pageParam,
        cancelToken: source.token,
      });
      promise.cancel = () => {
        source.cancel('Query cancelled by React Query');
      };
      return promise;
    },
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 10,
      getNextPageParam: (lastPage) => lastPage.marker,
      enabled: options.enabled,
    },
  );
}

export type ProjectBuckets = {
  default: {
    buckets: string[];
  };
  [key: string]: {
    buckets: string[];
  };
};

type Groups = {
  [key: string]: {
    roles: { name: string; id: number }[];
  };
};

type Integrations = {
  [key: string]: {
    enabled: boolean;
    project?: Record<string, unknown>;
  };
};

export type ProjectDetails = {
  UUID: string;
  buckets: ProjectBuckets;
  createdBy: { userName: string; ip: string };
  dateAddedInMicroSeconds: number;
  description: string;
  groups: Groups;
  integrations: Integrations;
  isDeleted: boolean;
  project: 'project';
  projectName: string;
  projectName_lowercase: string;
  projectUUID: string;
  type: string;
  projectBuckets: string[];
  projectRegions: string[];
};

export type CreateProjectBuckets = {
  [key: string]: {
    buckets: string[];
  };
};

export type CreateProjectBody = {
  projectName: string;
  description: string;
  buckets: CreateProjectBuckets;
  integrations: {
    frameio: { enabled: boolean; project?: { approvalsFolderID: string; id: string } };
    moxion: { enabled: boolean };
  };
  roleAssignment?: { email: string; roleUUID: string; roleName?: string }[];
};

async function addProject(post: Post, project: CreateProjectBody) {
  const { data } = await post<{ project: { UUID: string } }>('/project', project);
  return data;
}

export function useCreateProject(
  options?: UseMutationOptions<
    {
      project: {
        UUID: string;
      };
    },
    unknown,
    CreateProjectBody,
    unknown
  >,
): UseMutationResult<
  {
    project: {
      UUID: string;
    };
  },
  unknown,
  CreateProjectBody,
  unknown
> {
  const { post } = useApi();
  const queryClient = useQueryClient();
  return useMutation((project) => addProject(post, project), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('projects');
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

function flattenBucketList(bucketObject: ProjectDetails['buckets']) {
  const buckets = Object.keys(bucketObject).flatMap((key) => {
    if (key !== 'default') {
      return bucketObject[key].buckets;
    }
    return [];
  });
  return buckets;
}

function getProjectRegionsFromBuckets(bucketObject: ProjectDetails['buckets']) {
  return Object.keys(bucketObject).reduce<string[]>((result, key) => {
    if (key !== 'default') {
      result.push(key);
    }
    return result;
  }, []);
}

async function fetchProject(get: Get, id?: string) {
  if (!id) {
    return undefined;
  }
  const { data } = await get<{ project: ProjectDetails }>(`/project/${id}`);
  const projectBuckets = flattenBucketList(data.project.buckets);
  const projectRegions = getProjectRegionsFromBuckets(data.project.buckets);
  return { ...data.project, projectBuckets, projectRegions };
}

export function useProject(id?: string): UseQueryResult<ProjectDetails | undefined> {
  const { get } = useApi();
  return useQuery(['project', id], () => fetchProject(get, id), {
    cacheTime: 1000 * 60 * 10, //10mins
    staleTime: 1000 * 60 * 5, //5mins
  });
}

export function useCurrentProject(noIdRedirect = true): UseQueryResult<ProjectDetails | undefined> {
  const location = useLocation();
  const match = matchPath<{ projectId?: string }>(location.pathname, { path: '/project/:projectId', exact: false });
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  if (!match?.params.projectId) {
    noIdRedirect && history.push('/');
  }
  const project = useProject(match?.params.projectId);
  if (project.isError && noIdRedirect && project.error) {
    const error = project.error as Error;
    if (error.message.includes('404')) {
      openSnackbar('Project does not exist');
      history.push('/');
    }
  }
  return project;
}

async function fetchProjectPermissions(get: Get, id?: string) {
  const { data } = await get<string[]>(`/project/${id}/permissions`);
  return data;
}

export function useProjectPermissions(id?: string) {
  const { get } = useApi();
  return useQuery(['projectPermissions', id], () => fetchProjectPermissions(get, id), {
    cacheTime: 1000 * 60 * 60 * 24, // 24hrs
    staleTime: 1000 * 60 * 60 * 24, // 24hrs
    enabled: !!id,
  });
}

export type EditProjectData = { projectUUID: string } & Partial<CreateProjectBody>;

type EditProjectResponse = { message: string };

async function editProject(put: Put, data: EditProjectData) {
  const { data: response } = await put<EditProjectResponse>(`/project/${data.projectUUID}`, {
    ...data,
    projectUUID: undefined,
  });
  return response;
}

export function useEditProject(
  options?: UseMutationOptions<EditProjectResponse, unknown, EditProjectData, unknown>,
): UseMutationResult<EditProjectResponse, unknown, EditProjectData, unknown> {
  const { put } = useApi();
  const queryClient = useQueryClient();
  return useMutation((project) => editProject(put, project), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['project', variables.projectUUID]);
      queryClient.invalidateQueries('projects');
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

type DeleteProjectData = { projectUUID: string };

type DeleteProjectResponse = { message: string };

async function deleteProject(deleteApi: Delete, data: DeleteProjectData) {
  const { data: response } = await deleteApi<DeleteProjectResponse>(`/project/${data.projectUUID}`);
  return response;
}

export function useDeleteProject(
  options?: UseMutationOptions<DeleteProjectResponse, unknown, DeleteProjectData, unknown>,
): UseMutationResult<DeleteProjectResponse, unknown, DeleteProjectData, unknown> {
  const { delete: deleteApi } = useApi();
  const queryClient = useQueryClient();
  return useMutation((project) => deleteProject(deleteApi, project), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['project', variables.projectUUID]);
      queryClient.invalidateQueries('projects');
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

export type ProjectUser = {
  dateAddedInMicroSeconds: number;
  roleUUID: string;
  user_projectUUID: string;
  projectUUID_roleUUID: string;
  user: string;
  UUID: string;
  projectUUID_email: string;
  roleName: string;
  type: 'user_project';
};

async function fetchProjectUsers(get: Get, uuid: string) {
  const { data } = await get<{ users: ProjectUser[] }>(`/project/${uuid}/users`);
  return data.users;
}

export function useProjectUsers(uuid: string) {
  const { get } = useApi();
  return useQuery(['projectUsers', uuid], () => fetchProjectUsers(get, uuid), {
    cacheTime: 1000 * 60 * 10, //10mins
    staleTime: 1000 * 60 * 5, //5mins
  });
}

export type EditUser = { email: string; roleUUID: string; roleName: string };
type EditProjectUserData = { users: EditUser[]; projectUUID: string };
async function editProjectUser(put: Put, data: EditProjectUserData) {
  const { data: response } = await put<EditProjectResponse>(`/project/${data.projectUUID}/users`, {
    users: data.users,
  });
  return response;
}

export function useEditProjectUsers(
  options?: UseMutationOptions<EditProjectResponse, unknown, EditProjectUserData, unknown>,
) {
  const { put } = useApi();
  const queryClient = useQueryClient();
  return useMutation((data) => editProjectUser(put, data), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['projectUsers', variables.projectUUID]);
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

type DeleteProjectUserData = { projectUUID: string; userEmail: string };
async function deleteProjectUser(deleteApi: Delete, data: DeleteProjectUserData) {
  const { data: response } = await deleteApi<EditProjectResponse>(
    `/project/${data.projectUUID}/user/${data.userEmail}`,
  );
  return response;
}

export function useDeleteProjectUser(
  options?: UseMutationOptions<EditProjectResponse, unknown, DeleteProjectUserData, unknown>,
) {
  const { delete: deleteApi } = useApi();
  const queryClient = useQueryClient();
  return useMutation((data) => deleteProjectUser(deleteApi, data), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['projectUsers', variables.projectUUID]);
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}
