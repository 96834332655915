import { styled } from '@mui/material';

export const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  height: '100%',
});

export const ActionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: 8,
});
