
export const buttonColor = {
  bgcolor: '#7202C6',
};

export const colors = {
    backgroundColor: '#FFFFFF',
    neutral100: '#FCFDFDFF',
    neutral200: '#F6F7F7FF',
    neutral300: '#E8EDEDFF',
    neutral400: '#B8C0C6FF',
    neutral500: '#7C858DFF',
    neutral600: '#444B52FF',
    neutral700: '#212529FF',
  };

  export const fontFamily = {
    typographyfontfamily1: 'Montserrat, sans-serif',
  };

  export const fontSizes = {
    /* Typography - Font Size */
    typographyfontsize1: '12px',
    typographyfontsize2: '14px',
    typographyfontsize3: '16px',
    typographyfontsize4: '18px',
    typographyfontsize5: '24px',
    typographyfontsize6: '29px',
    typographyfontsize7: '36px',
  };

  export const fontWeight = {
    typographyfontweight1: '500',
    typographyfontweight2: '600',
    typographyfontweight3: '700',
  };

  export const purpleColor = {
    purple1 : '#B15EFF',
  };
  

  export const radious = {
    borderradius1: '4',
    borderradius2: '8',
    borderradius3: '16',
    borderradius4: '24',
    borderradius5: '32',
    borderradius6: '40',
  };

  export const sizes = {
    sizes1: '4',
    sizes2: '8',
    sizes3: '16',
    sizes4: '24',
    sizes5: '32',
    sizes6: '40',
  };

  export const shadows = {
    shadow1: '0px 2px 8px 0px rgba(33, 37, 41, 0.12)',
    shadow2: '0px 4px 12px 0px rgba(33, 37, 41, 0.16)',
    shadow3: '0px 8px 16px 0px rgba(33, 37, 41, 0.12)',
    shadow4: '0px 8px 32px 0px rgba(33, 37, 41, 0.16)',
  };

  export const typography = {
    typographyfontsize1: '12px',
    typographyfontsize2: '14px',
    typographyfontsize3: '16px',
    typographyfontsize4: '18px',
    typographyfontsize5: '24px',
    typographyfontsize6: '29px',
    typographyfontsize7: '36px',
  };
  
  const themeValues = {
    buttonColor,
    colors,
    fontFamily,
    fontSizes,
    fontWeight,
    purpleColor,
    radious,
    sizes,
    shadows,
    typography,
  };
  
  export default themeValues;
  