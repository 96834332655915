import { FunctionComponent, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Step,
  Stepper,
  StepLabel,
  IconButton,
  Typography,
  Box,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { CreateProjectBody, useCreateProject, CreateProjectBuckets } from '../../../api/projects';
import { useApi } from '../../../contexts/Api';
import { useSnackbar } from '../../../contexts/Snackbar';
import BasicInfo from './steps/BasicInfo';
import Buckets from './steps/Buckets';
import Integrations from './steps/Integrations';
import Review from './steps/Review';
import Users from './steps/Users';
import { buttonColor, colors, purpleColor, radious, sizes } from '../../../theme/designtoken';

type CreateProjectDialogProps = {
  open: boolean;
  onClose: () => void;
};

export type ProjectFormValues = {
  projectName: string;
  description: string;
  buckets: { region: string; regionLabel: string; bucket: string }[];
  reviewPlatforms: {
    frameio: {
      enabled: boolean;
      userDefinedToken: boolean;
      token: string;
      team?: string;
      projectName?: string;
    };
    moxion: { enabled: boolean };
  };
  users: Record<string, { email: string; roleUUID: string; roleName: string }>;
};

export const CreateProjectDialog: FunctionComponent<CreateProjectDialogProps> = ({ open, onClose }) => {
  const { openSnackbar } = useSnackbar();
  const { post } = useApi();
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Basic Info', 'Buckets', 'Integrations', 'Users', 'Review'];
  const form = useForm<ProjectFormValues>({
    mode: 'onChange',
    shouldUnregister: false,
  });
  const { mutate: createProject } = useCreateProject({
    onSuccess: () => {
      openSnackbar('Project Created Succesfully!', { alert: 'success' });
      onClose();
    },
    onError: (error) => {
      const err = error as { response?: { data?: { message: string } } };
      openSnackbar(err?.response?.data?.message || 'Failed to create project. Please try again!');
    },
  });
  const { handleSubmit, formState } = form;
  const { dirtyFields, isSubmitting } = formState;

  const onSubmit = handleSubmit(async (values) => {
    const buckets: CreateProjectBuckets = {};
    for (const bucket of values.buckets) {
      if (!buckets[bucket.region]) {
        buckets[bucket.region] = { buckets: [] };
      }
      buckets[bucket.region].buckets = [...buckets[bucket.region].buckets, bucket.bucket];
    }
    const roleAssignment = values.users && Object.values(values.users);
    const newProject: CreateProjectBody = {
      projectName: values.projectName,
      description: values.description,
      buckets,
      integrations: {
        frameio: { enabled: values.reviewPlatforms?.frameio?.enabled || false },
        moxion: { enabled: values.reviewPlatforms?.moxion?.enabled || false },
      },
      roleAssignment,
    };
    if (values.reviewPlatforms?.frameio?.enabled) {
      if (dirtyFields.reviewPlatforms?.frameio?.projectName || values.reviewPlatforms?.frameio?.userDefinedToken) {
        const createProjectData: { teamID?: string; name?: string; token?: string } = {
          teamID: values.reviewPlatforms.frameio.team,
          name: values.reviewPlatforms.frameio.projectName,
        };

        if (values.reviewPlatforms?.frameio?.userDefinedToken) {
          createProjectData.token = values.reviewPlatforms.frameio.token;
        }
        try {
          const { data } = await post<{ project: { approvalsFolderID: string; id: string } }>(
            '/integrations/frameio/project',
            createProjectData,
          );

          newProject.integrations.frameio.project = data.project;
        } catch (err) {
          return openSnackbar('There was a problem with FrameIo. Please review and try again');
        }
      }
    }

    createProject(newProject);
  });

  function renderStep() {
    switch (activeStep) {
      case 1:
        return <Buckets form={form} />;
      case 2:
        return <Integrations form={form} />;
      case 3:
        return <Users form={form} />;
      case 4:
        return <Review form={form} />;
      default:
        return <BasicInfo form={form} />;
    }
  }

  return (
    <Dialog open={open} aria-label="create-project-dialog" fullWidth scroll="paper">
      <form onSubmit={onSubmit}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '16px 24px',
          }}
        >
          <Typography variant="h6" color="#7202C6">Create Projects</Typography>
          <IconButton onClick={onClose} color="primary" aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Stepper alternativeLabel activeStep={activeStep} sx={{ paddingTop: 0, color:`${colors.backgroundColor}` }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {renderStep()}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={activeStep === 0 || !formState.isValid || isSubmitting}
            onClick={() => setActiveStep(activeStep - 1)}
            color="primary"
            style={{ marginRight: 0 }}
            key="nextButton"
            sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}
          >
            Back
          </Button>
          {activeStep === 4 ? (
            <Button
              variant="contained"
              sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}
              type="submit"
              key="submitButton"
              disabled={!formState.isValid || isSubmitting}
            >
              {isSubmitting ? 'Creating...' : 'Create Project'}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => setActiveStep(activeStep + 1)}
              disabled={!formState.isValid}
              sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}
            >
              Next
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateProjectDialog;
