
export const leftnavVariables = {
    fontColor: '#370060',
    fontFamily: 'Montserrat !important, sans-serif',
    fontSize: '16px',
    fontWeight: 'bold', 
    lineHeight: '24px',
    letterSpacing:'0px',
    paddingLeft: 4,
    leftMenuBGColor:'#F6F7F7',
  };
  