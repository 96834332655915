import axios, { AxiosError, CancelToken } from 'axios';
import { RegisterOptions } from 'react-hook-form';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from 'react-query';
import { useApi, Get, Post, Delete, Put } from '../contexts/Api';

type DaysOfWeek = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export type ShutdownPolicy = {
  enabled: boolean;
  inactiveTimeInMinutes: number;
  dateBasedShutdown: boolean;
  daysForShutdown: DaysOfWeek[];
  hourForShutdown: number;
  minuteForShutdown: number;
};

export type NonDVCInputParams = {
  AsgDesired: {
    v: number;
  };
  AsgMax: {
    v: number;
  };
  AsgMin: {
    v: number;
  };
};
export type Deployment = {
  UUID: string;
  dateAddedInMicroSeconds: number;
  cf_resources: Record<string, unknown>;
  deployment: number;
  fqdnUsername: string;
  name: string;
  ownerID: string;
  ownerID_nd: string;
  powerState: 'running' | 'stopped' | 'stopping' | 'pending';
  platformOS: 'windows' | 'linux';
  regionName: string;
  showConnect: boolean;
  status: 'created' | 'deploying' | string; //find possible statuses Deploying Created
  type: 'dcv' | 'transcoder' | 'gateway' | string;
  username: string;
  viewGroup: string;
  viewGroup_created: string;
  thumbURL?: string;
  originalURL?: string;
  shutdownPolicy?: ShutdownPolicy;
  paramInputs?: NonDVCInputParams;
};

export type DeploymentsResponse = {
  lastKey: null | string;
  deployments: Deployment[];
};

async function fetchDeployments(get: Get, group?: string, lastKey?: DeploymentsResponse['lastKey']) {
  if (!group) {
    return {
      lastKey: null,
      deployments: [],
    };
  }
  const params = { lastKey };
  const { data } = await get<DeploymentsResponse>(`/user/view/deployments/${group}`, { params });
  return data;
}

export function useDeployments(group?: string): UseInfiniteQueryResult<DeploymentsResponse> {
  const { get } = useApi();
  return useInfiniteQuery<DeploymentsResponse>(
    ['deployments', group],
    ({ pageParam }) => fetchDeployments(get, group, pageParam),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 10,
      getNextPageParam: (lastPage) => lastPage.lastKey,
    },
  );
}
type DeploymentFilter = { type: Deployment['type'] };

async function fetchUserDeployments(get: Get, filter?: DeploymentFilter, lastKey?: DeploymentsResponse['lastKey']) {
  const params = { lastKey };
  const { data } = await get<DeploymentsResponse>('/user/deployments', { params });
  if (filter) {
    data.deployments = data.deployments.filter((deployment) => deployment.type === filter.type);
  }
  return data;
}

export function useUserDeployments(
  filter?: DeploymentFilter,
  options?: UseInfiniteQueryOptions<
    DeploymentsResponse,
    AxiosError<{ message?: string }>,
    DeploymentsResponse,
    DeploymentsResponse,
    string[]
  >,
) {
  const { get } = useApi();
  return useInfiniteQuery(
    ['userDeployments', filter?.type || ''],
    ({ pageParam }) => fetchUserDeployments(get, filter, pageParam),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 10,
      getNextPageParam: (lastPage) => lastPage.lastKey,
      ...options,
    },
  );
}

async function fetchAdminDeployments(get: Get, lastKey?: DeploymentsResponse['lastKey']) {
  const params = { lastKey };
  const { data } = await get<DeploymentsResponse>('/admin/deployments', { params });
  return data;
}

export function useAdminDeployments(
  options?: UseInfiniteQueryOptions<
    DeploymentsResponse,
    AxiosError<{ message?: string }>,
    DeploymentsResponse,
    DeploymentsResponse,
    'adminDeployments'
  >,
) {
  const { get } = useApi();
  return useInfiniteQuery('adminDeployments', ({ pageParam }) => fetchAdminDeployments(get, pageParam), {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 10,
    getNextPageParam: (lastPage) => lastPage.lastKey,
    ...options,
  });
}

async function fetchDeployment(get: Get, id: string, admin: boolean) {
  const { data } = await get<{ deployment: Deployment }>(`/${admin ? 'admin' : 'user'}/deployment/${id}`);
  return data.deployment;
}

export function useDeployment(
  id: string,
  admin: boolean,
  options?: UseQueryOptions<Deployment, AxiosError<{ message?: string }>, Deployment, string[]>,
): UseQueryResult<Deployment> {
  const queryOptions = {
    cacheTime: 1000 * 60 * 10, //10mins
    staleTime: 1000 * 60 * 1, //1mins
    ...options,
  };
  const { get } = useApi();
  return useQuery(['deployment', id, admin.toString()], () => fetchDeployment(get, id, admin), queryOptions);
}

type GroupsResponse = {
  groups: string[];
};

async function fetchGroups(get: Get) {
  const { data } = await get<GroupsResponse>('/user/groups');
  return data.groups;
}

export function useGroups(): UseQueryResult<string[]> {
  const { get } = useApi();
  return useQuery('groups', () => fetchGroups(get), {
    cacheTime: 1000 * 60 * 10, //10mins
    staleTime: 1000 * 60 * 5, //5mins
  });
}

type GenericField = {
  name: string;
  label: string;
  type: 'string' | 'number' | 'password';
  defaultValue?: string | number;
  helperText?: string;
  validation?: RegisterOptions;
};

type SelectField = {
  name: string;
  label: string;
  type: 'select';
  defaultValue?: string | number | { value: string; label: string };
  valueOptions: { value: string; label: string }[];
  multiSelect?: boolean;
  helperText?: string;
  validation?: RegisterOptions;
};

export type Field = GenericField | SelectField;

export type TemplateDetails = {
  name: string;
  UUID: string;
  dateAddedInMicroSeconds: number;
  deploymentTemplate: number;
};

type TemplatesResponse = {
  templates: TemplateDetails[];
  lastKey: string | null;
};

async function fetchTemplates(get: Get) {
  const { data } = await get<TemplatesResponse>('/deployments/v2/templates');
  return data.templates;
}

export function useDeploymentTemplates(): UseQueryResult<Template[]> {
  const { get } = useApi();
  return useQuery('deploymentTemplates', () => fetchTemplates(get), {
    cacheTime: 1000 * 60 * 10, //10mins
    staleTime: 1000 * 60 * 5, //5mins
  });
}

type templateParams = {
  templateId: string;
  regionName: string;
  vpcId: string;
};

export type Template = {
  name: string;
  UUID: string;
  dateAddedInMicroSeconds: number;
  deploymentTemplate: number;
  fields: Field[];
  type: 'dcv' | 'transcoder';
};

async function fetchTemplate(get: Get, { templateId, vpcId, regionName }: templateParams) {
  const { data } = await get<Template>(`/deployments/v2/template/${templateId}`, {
    params: { regionName, vpcID: vpcId },
  });
  return data;
}

export function useDeploymentTemplate(templateParams: templateParams): UseQueryResult<Template> {
  const { get } = useApi();
  return useQuery(
    ['deploymentTemplates', templateParams.templateId, templateParams.regionName, templateParams.vpcId],
    () => fetchTemplate(get, templateParams),
    {
      cacheTime: 1000 * 60 * 10, //10mins
      staleTime: 1000 * 60 * 5, //5mins
    },
  );
}

type Regions = {
  [Key: string]: string;
};

async function fetchRegions(get: Get) {
  const { data } = await get<Regions>('/deployments/regions');
  return data;
}

export function useRegions(): UseQueryResult<Regions> {
  const { get } = useApi();
  return useQuery('deploymentRegions', () => fetchRegions(get), {
    cacheTime: 1000 * 60 * 10, //10mins
    staleTime: 1000 * 60 * 5, //5mins
  });
}

type Vpc = {
  id: string;
  cidr: string;
  default: boolean;
  name: string;
};

async function fetchVpcs(get: Get, region?: string) {
  if (!region) {
    return undefined;
  }
  const { data } = await get<{ vpc: Vpc[] }>('/admin/deploy/vpc', { params: { regionName: region } });
  return data.vpc;
}

export function useVpcs(region?: string): UseQueryResult<Vpc[]> {
  const { get } = useApi();
  return useQuery(['deploymentVpcs', region], () => fetchVpcs(get, region), {
    cacheTime: 1000 * 60 * 10, //10mins
    staleTime: 1000 * 60 * 5, //5mins
  });
}

export type DeploymentBody = {
  deploymentTemplateUUID: string;
  vpcID: string;
  regionName: string;
  acceptWarning: boolean;
  [x: string]:
    | string
    | boolean
    | string[]
    | ShutdownPolicy
    | {
        value: string;
        label: string;
      };
};

async function createDeployment(post: Post, deployment: DeploymentBody) {
  const body = { ...deployment, acceptWarning: undefined };
  const { data } = await post<{ deployment: { UUID: string } }>('/admin/v2/deploy', body, {
    params: { acceptWarning: deployment.acceptWarning },
  });
  return data;
}

export function useCreateDeployment(
  options?: UseMutationOptions<
    {
      deployment: {
        UUID: string;
      };
    },
    unknown,
    DeploymentBody,
    unknown
  >,
) {
  const { post } = useApi();
  const queryClient = useQueryClient();
  return useMutation((deployment) => createDeployment(post, deployment), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('userDeployments');
      queryClient.invalidateQueries('adminDeployments');
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

export type EditDeploymentBody = {
  UUID: string;
  values: {
    [key: string]: string | unknown[] | { value: string; label: string } | number | boolean;
  };
};

async function editDeployment(put: Put, body: EditDeploymentBody) {
  const { data } = await put<{ deployment: { UUID: string } }>(`/admin/deployment/${body.UUID}`, body.values);
  return data;
}

export function useEditDeployment(
  options?: UseMutationOptions<
    {
      deployment: {
        UUID: string;
      };
    },
    AxiosError<{ message: string; field?: string }>,
    EditDeploymentBody,
    unknown
  >,
): UseMutationResult<
  {
    deployment: {
      UUID: string;
    };
  },
  AxiosError<{ message: string; field?: string }>,
  EditDeploymentBody,
  unknown
> {
  const { put } = useApi();
  const queryClient = useQueryClient();
  return useMutation((deployment) => editDeployment(put, deployment), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('userDeployments');
      queryClient.invalidateQueries('adminDeployments');
      queryClient.invalidateQueries(['deployment', variables.UUID, 'false']);
      queryClient.invalidateQueries(['deployment', variables.UUID, 'true']);
      queryClient.invalidateQueries(['deploymentProperties', variables.UUID]);
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}
type EditShutdownResponse = { deployment: { UUID: string } };
type EditShutdownProps = { UUID: string; values: ShutdownPolicy };
async function editShutdownPolicy(put: Put, { UUID, values }: EditShutdownProps) {
  const { data } = await put<{ deployment: { UUID: string } }>(`/admin/deployment/${UUID}/policy`, {
    shutdownPolicy: values,
  });
  return data;
}

export function useEditShutdownPolicy(
  options?: UseMutationOptions<EditShutdownResponse, AxiosError<{ message: string }>, EditShutdownProps, unknown>,
) {
  const { put } = useApi();
  const queryClient = useQueryClient();
  return useMutation((data) => editShutdownPolicy(put, data), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('adminDeployments');
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

async function deleteDeployment(deleteApi: Delete, UUID: string) {
  const { data } = await deleteApi<{ deployment: { UUID: string } }>(`/admin/deployment/${UUID}`);
  return data;
}

export function useDeleteDeployment(
  options?: UseMutationOptions<
    {
      deployment: {
        UUID: string;
      };
    },
    AxiosError<{ message?: string }>,
    string,
    unknown
  >,
): UseMutationResult<
  {
    deployment: {
      UUID: string;
    };
  },
  AxiosError<{ message?: string }>,
  string,
  unknown
> {
  const { delete: deleteApi } = useApi();
  const queryClient = useQueryClient();
  return useMutation((id) => deleteDeployment(deleteApi, id), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('userDeployments');
      queryClient.invalidateQueries('adminDeployments');
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

type MetricData = {
  Id: string;
  Label: string;
  StatusCode: string;
  datapoints: { value: number; timeStamp: string; timestamp: number }[];
};

type Metric = {
  Messages: string[];
  MetricDataResults: MetricData[];
  endTime: string;
  period: number;
  startTime: string;
  statistics: 'Average';
  unit: 'percent' | 'bytes';
};

export type MetricResponse = {
  cpu: Metric;
  memory: Metric;
  networkIn: Metric;
  networkOut: Metric;
  EBSReadOps: Metric;
  // error: boolean;
};

function setTimestamp(data: MetricResponse) {
  const updatedResponse = { ...data };
  Object.keys(data).forEach((key) => {
    if (!data[key as keyof MetricResponse].MetricDataResults) {
      return;
    }
    updatedResponse[key as keyof MetricResponse].MetricDataResults = data[
      key as keyof MetricResponse
    ].MetricDataResults.map((metricData) => {
      return {
        ...metricData,
        datapoints: metricData.datapoints.map((datapoint) => ({
          ...datapoint,
          timestamp: new Date(datapoint.timeStamp).getTime(),
        })),
      };
    });
  });
  return updatedResponse;
}

async function fetchDeploymentMetrics(get: Get, id: string, admin: boolean) {
  const { data } = await get<MetricResponse>(`/${admin ? 'admin' : 'user'}/deployment/${id}/metrics`);
  return setTimestamp(data);
}

export function useDeploymentMetrics(
  id: string,
  admin: boolean,
  options?: UseQueryOptions<MetricResponse, unknown, MetricResponse, string[]>,
): UseQueryResult<MetricResponse> {
  const { get } = useApi();
  const queryOptions = {
    cacheTime: 1000 * 60 * 10, //10mins
    staleTime: 1000 * 60 * 5, //5mins
    retry: 1,
    ...options,
  };
  return useQuery(
    ['deploymentMetrics', id, admin.toString()],
    () => fetchDeploymentMetrics(get, id, admin),
    queryOptions,
  );
}

export type Event = {
  UUID: string;
  action: 'deploymentRequest' | 'powerStateChange';
  dateAddedInMicroSeconds: number;
  deploymentUUID_event: string;
  eventMessage: string;
  eventTitle: string;
  eventUserName: string;
};

type DeploymentEventResponse = {
  events: Event[];
  lastKey: string | null;
};

async function fetchDeploymentEvents(get: Get, id: string, admin: boolean, lastKey?: DeploymentsResponse['lastKey']) {
  const params = { lastKey };
  const { data } = await get<DeploymentEventResponse>(`/${admin ? 'admin' : 'user'}/deployment/${id}/events`, {
    params,
  });
  return data;
}

type AdminDeploymentEventsQueryOptions = UseInfiniteQueryOptions<DeploymentEventResponse>;

export function useDeploymentEvents(
  id: string,
  admin: boolean,
  options?: AdminDeploymentEventsQueryOptions,
): UseInfiniteQueryResult<DeploymentEventResponse> {
  const { get } = useApi();
  const queryOptions: AdminDeploymentEventsQueryOptions = {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 10,
    getNextPageParam: (lastPage) => lastPage.lastKey,
    retry: 1,
    ...options,
  };
  return useInfiniteQuery<DeploymentEventResponse>(
    ['deploymentEvents', id, admin.toString()],
    ({ pageParam }) => fetchDeploymentEvents(get, id, admin, pageParam),
    queryOptions,
  );
}

type PowerStateResponse = {
  deployment: {
    UUID: string;
  };
};

type PowerStateBody = {
  powerState: 'on' | 'off' | 'stop' | 'start' | 'reboot';
  UUID: string;
};

async function updatePowerState(put: Put, body: PowerStateBody, isAdmin: boolean) {
  const { data } = await put<PowerStateResponse>(`/${isAdmin ? 'admin' : 'user'}/deployment/${body.UUID}/powerstate`, {
    powerState: body.powerState,
  });
  return data;
}

export function useUpdateDeploymentPowerStateAdmin(
  isAdmin: boolean,
  options?: UseMutationOptions<PowerStateResponse, AxiosError<{ message?: string }>, PowerStateBody, unknown>,
): UseMutationResult<PowerStateResponse, AxiosError<{ message?: string }>, PowerStateBody, unknown> {
  const { put } = useApi();
  const queryClient = useQueryClient();
  return useMutation((powerState) => updatePowerState(put, powerState, isAdmin), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('userDeployments');
      queryClient.invalidateQueries('adminDeployments');
      const newPowerState = variables.powerState === 'start' ? 'running' : 'stopping';
      queryClient.setQueryData<Deployment>(['deployment', variables.UUID, isAdmin.toString()], (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            powerState: newPowerState,
          };
        }
        return {} as Deployment;
      });
      queryClient.invalidateQueries(['deployment', variables.UUID, !isAdmin.toString()]);
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

type SearchResponse<T> = {
  marker?: null | { UUID: string; dateAddedInMicroSeconds: number; project: 'project' };
  hits: number;
  results: T[];
  totalHits: number;
};

type ElasticSearchParams = {
  post: Post;
  searchQuery: Record<string, unknown>;
  lastKey?: null | { UUID: string; dateAddedInMicroSeconds: number; project: 'project' };
  cancelToken?: CancelToken;
};

type CancelablePromise<T> = Promise<T> & { cancel?: () => void };

async function queryElasticSearch<T>({ post, searchQuery, cancelToken, lastKey }: ElasticSearchParams) {
  const { data } = await post<SearchResponse<T>>('/admin/deployments/search', searchQuery, {
    params: { lastKey },
    cancelToken,
  });
  return data;
}

export function useDeploymentSearch<T>(
  searchQuery: Record<string, unknown>,
  options?: UseQueryOptions<T>,
): UseInfiniteQueryResult<SearchResponse<T>> {
  const { post } = useApi();
  return useInfiniteQuery<SearchResponse<T>>(
    ['deploymentSearch', searchQuery],
    ({ pageParam }) => {
      const source = axios.CancelToken.source();
      const promise: CancelablePromise<SearchResponse<T>> = queryElasticSearch({
        post,
        searchQuery,
        lastKey: pageParam,
        cancelToken: source.token,
      });
      promise.cancel = () => {
        source.cancel('Query cancelled by React Query');
      };
      return promise;
    },
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 10,
      getNextPageParam: (lastPage) => lastPage.marker,
      enabled: options?.enabled,
    },
  );
}

type DeploymentProperties = { inputs: Field[] };

async function getDeploymentProperties({ deploymentId, get }: { deploymentId: string; get: Get }) {
  const { data } = await get<DeploymentProperties>(`/admin/deployment/${deploymentId}/properties`);
  return data;
}

export function useDeploymentProperties(deploymentId: string, options?: UseQueryOptions<DeploymentProperties>) {
  const { get } = useApi();
  return useQuery<DeploymentProperties>(
    ['deploymentProperties', deploymentId],
    () => getDeploymentProperties({ deploymentId, get }),
    options,
  );
}

export type DeploymentLogParams = {
  deploymentID: string;
  logName?: string;
  startTime?: number;
  endTime?: number;
  searchTerm?: string;
  instanceID?: string;
};
export type LogEvents = { message: string; timestamp: number; ingestionTime: number };
export type DeploymentLogResponse = { nextForwardToken?: string; nextBackwardToken?: string; events: LogEvents[] };

async function fetchDeploymentLogs(
  get: Get,
  { deploymentID, logName, startTime, endTime, searchTerm, instanceID }: DeploymentLogParams,
  pageParam?: { nextForwardToken?: string; nextBackwardToken?: string },
) {
  const params = {
    logName,
    nextForwardToken: pageParam?.nextForwardToken,
    nextBackwardToken: pageParam?.nextBackwardToken,
    startTime,
    endTime,
    filterPattern: searchTerm,
    instanceID,
  };
  const { data } = await get<{ logs: DeploymentLogResponse }>(`/admin/deployment/${deploymentID}/logs`, { params });
  const events = data.logs.events.map((event) => ({
    ...event,
    message: event.message
      .replaceAll('\r', '\n')
      .replaceAll('\n\n', '\n')
      .split('\n')
      .map((line) => line.trim())
      .join('\n'),
  }));
  return { ...data.logs, events };
}

export function useDeploymentLogs(params: DeploymentLogParams) {
  const { get } = useApi();
  return useInfiniteQuery(
    [
      'deploymentLog',
      params.deploymentID,
      params.logName,
      params.startTime,
      params.endTime,
      params.searchTerm,
      params.instanceID,
    ],
    ({ pageParam }) => fetchDeploymentLogs(get, params, pageParam),
    {
      keepPreviousData: true,
      cacheTime: params.searchTerm ? 0 : 10 * 60 * 5, // dont use cache for search
      getNextPageParam: (lastPage) =>
        lastPage.nextForwardToken ? { nextForwardToken: lastPage.nextForwardToken } : undefined,
      getPreviousPageParam: (firstPage) =>
        firstPage.nextBackwardToken ? { nextBackwardToken: firstPage.nextBackwardToken } : undefined,
    },
  );
}

export type AutoscalingInstance = {
  AvailabilityZone: string;
  HealthStatus: string;
  InstanceId: string;
  InstanceType: string;
  LaunchConfigurationName: string;
  LifecycleState: string;
  ProtectedFromScaleIn: boolean;
};

export type AutoscalingDeployment = {
  MaxSize: number;
  MinSize: number;
  desiredCapacity: number;
  instances: AutoscalingInstance[];
};

async function getAutoscalingDeployment(get: Get, deploymentId: string) {
  const { data } = await get<{ autoscaling: AutoscalingDeployment }>(`/admin/deployment/${deploymentId}/autoscaling`);
  return data.autoscaling;
}

export function useAutoscalingDeployment(deploymentId: string) {
  const { get } = useApi();
  return useQuery(['autoscalingDeployment', deploymentId], () => getAutoscalingDeployment(get, deploymentId));
}

export type LogGroups = { value: string; label: string }[];
async function getDeploymentLogList({ deploymentId, get }: { deploymentId: string; get: Get }) {
  const { data } = await get<{ logGroups: LogGroups }>(`/admin/deployment/${deploymentId}/logs/list`);
  return data.logGroups;
}

export function useDeploymentLogList(deploymentId: string, options?: UseQueryOptions<LogGroups>) {
  const { get } = useApi();
  return useQuery<LogGroups>(
    ['deploymentLogList', deploymentId],
    () => getDeploymentLogList({ deploymentId, get }),
    options,
  );
}

async function getDeploymentGateways({ deploymentUUID, get }: { deploymentUUID: string; get: Get }) {
  const { data } = await get<{ gateways: LogGroups }>('/admin/gateways', { params: { deploymentUUID } });
  return data.gateways;
}

export function useDeploymentGateways(deploymentUUID: string, options?: UseQueryOptions<LogGroups>) {
  const { get } = useApi();
  return useQuery<LogGroups>(
    ['deploymentGateways', deploymentUUID],
    () => getDeploymentGateways({ deploymentUUID, get }),
    options,
  );
}
