import { FunctionComponent, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormControlLabel, Switch, Typography, TextField, Checkbox, CircularProgress, MenuItem } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useSnackbar } from '../../../../contexts/Snackbar';
import { useApprovalPlatforms, useFrameIoTeams, useIntegrationDefaults } from '../../../../api/integrations';
import { ProjectFormValues } from '../CreateProjectDialog';
import { useApi, Post } from '../../../../contexts/Api';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));

export async function validateFrameIoToken(post: Post, token: string, required: boolean) {
  if (!required) {
    return true;
  }

  if (token.length < 10) {
    return 'Token must be at least 10 characters';
  }

  try {
    await post<{ message: string }>('/integrations/frameio/token/validate', { token });
    return true;
  } catch (e) {
    return 'Error validating token';
  }
}

type IntegrationProps = {
  form: UseFormReturn<ProjectFormValues>;
  updateProject?: boolean;
};

export const Integrations: FunctionComponent<IntegrationProps> = ({ form, updateProject = false }) => {
  const styles = useStyles();
  const { openSnackbar } = useSnackbar();
  const { post } = useApi();
  const approvalPlatforms = useApprovalPlatforms();
  const {
    watch,
    control,
    formState: { errors, isValidating, isValid },
    setValue,
    trigger,
  } = form;

  const [userFrameIoToken, setUserFrameIoToken] = useState<string>();
  const [tokenValidationResult, setTokenValidationResult] =
    useState<{ result: string | true; token: string; userDefinedToken: boolean }>();
  const frameIoEnabled = watch('reviewPlatforms.frameio.enabled', false);
  const userDefinedToken = watch('reviewPlatforms.frameio.userDefinedToken', false);
  const customToken = watch('reviewPlatforms.frameio.token', '');
  const frameIoErrors = errors.reviewPlatforms?.frameio;

  const frameIoDefaultTeams = useFrameIoTeams(userFrameIoToken);
  const frameIoDefaults = useIntegrationDefaults('frameio');

  const isLoading = approvalPlatforms.isLoading || frameIoDefaultTeams.isLoading || frameIoDefaults.isLoading;

  if (frameIoDefaultTeams.isError || frameIoDefaults.isError) {
    openSnackbar('Sorry there was problem loading your FrameIO data. Please go back and try again');
  }

  useEffect(() => {
    async function handleValidCustomToken(token: string) {
      setUserFrameIoToken(token); // set token so we only fetch once
      setValue('reviewPlatforms.frameio.team', '');
      setValue('reviewPlatforms.frameio.projectName', '');
      setValue('reviewPlatforms.frameio.team', '');
      trigger('reviewPlatforms.frameio.projectName');
    }
    if (userDefinedToken && customToken && customToken !== userFrameIoToken && !isValidating && !frameIoErrors?.token) {
      handleValidCustomToken(customToken);
    }
  }, [
    customToken,
    isValid,
    isValidating,
    frameIoErrors,
    openSnackbar,
    setValue,
    trigger,
    userDefinedToken,
    userFrameIoToken,
  ]);

  useEffect(() => {
    if (frameIoEnabled && !userDefinedToken && userFrameIoToken && frameIoDefaults.data) {
      // reset form to frameio defaults
      setUserFrameIoToken(undefined);
      setValue('reviewPlatforms.frameio.team', frameIoDefaults.data.team.id);
      setValue('reviewPlatforms.frameio.projectName', frameIoDefaults.data.project.name);
      trigger('reviewPlatforms.frameio.projectName');
    }
  }, [
    frameIoDefaultTeams,
    frameIoDefaults.data,
    frameIoEnabled,
    setValue,
    trigger,
    userDefinedToken,
    userFrameIoToken,
  ]);

  useEffect(() => {
    // when userDefinedToken changes validate the token field
    if (!userDefinedToken) {
      return setValue('reviewPlatforms.frameio.token', '');
    }
    trigger('reviewPlatforms.frameio.token');
  }, [setValue, trigger, userDefinedToken]);

  return (
    <div className={styles.container}>
      <Typography>Enable integrations</Typography>
      {isLoading ? (
        <CircularProgress size={24} color="primary" aria-label="Loading" />
      ) : (
        <>
          {approvalPlatforms.data?.moxion.enabled && (
            <Controller
              name="reviewPlatforms.moxion.enabled"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={(e) => onChange(e.target.checked)} />}
                    label="Moxion"
                  />
                );
              }}
            />
          )}
          {approvalPlatforms.data?.frameio.enabled && (
            <Controller
              name="reviewPlatforms.frameio.enabled"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControlLabel
                    control={<Switch checked={value} onChange={(e) => onChange(e.target.checked)} />}
                    label="Frame.io"
                  />
                );
              }}
            />
          )}
          {!approvalPlatforms.data?.moxion?.enabled && !approvalPlatforms.data?.frameio.enabled && (
            <Typography>No integrations have been enabled for this deployment</Typography>
          )}
          {frameIoEnabled && (
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="reviewPlatforms.frameio.userDefinedToken"
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox onChange={(e) => onChange(e.target.checked)} checked={value} />
                  )}
                />
              }
              label={
                <>
                  Change account token{' '}
                  <Typography variant="caption" color="primary">
                    Requires new frame.io project
                  </Typography>
                </>
              }
            />
          )}
          <Controller
            name="reviewPlatforms.frameio.token"
            control={control}
            rules={{
              validate: async (value) => {
                if (
                  !tokenValidationResult ||
                  tokenValidationResult?.token !== value ||
                  tokenValidationResult?.userDefinedToken !== userDefinedToken
                ) {
                  const result = await validateFrameIoToken(post, value, userDefinedToken);
                  setTokenValidationResult({ token: value, userDefinedToken, result });
                  return result;
                }
                return tokenValidationResult.result;
              },
            }}
            render={({ field }) => (
              <TextField
                label="Account token"
                id="accountToken"
                fullWidth
                variant="outlined"
                margin="dense"
                type="password"
                helperText={frameIoErrors?.token ? frameIoErrors?.token.message : null}
                error={!!frameIoErrors?.token}
                InputProps={{
                  endAdornment: <>{isValidating ? <CircularProgress size={20} aria-label="Validating" /> : null}</>,
                }}
                style={{ display: userDefinedToken && !isLoading && frameIoEnabled ? 'block' : 'none' }}
                {...field}
              />
            )}
          />
          {((updateProject && userDefinedToken && frameIoEnabled) || (!updateProject && frameIoEnabled)) && (
            <>
              <Controller
                control={control}
                name="reviewPlatforms.frameio.team"
                render={({ field }) => (
                  <TextField
                    select
                    fullWidth
                    label="Team"
                    variant="outlined"
                    margin="dense"
                    id="team"
                    disabled={!!frameIoErrors?.token && userDefinedToken}
                    {...field}
                  >
                    {frameIoDefaultTeams.data?.map((team) => (
                      <MenuItem key={team.id} value={team.id}>
                        {team.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                rules={{ required: true }}
                defaultValue={frameIoDefaults.data?.team.id}
              />
              <Controller
                name="reviewPlatforms.frameio.projectName"
                control={control}
                defaultValue={frameIoDefaults.data?.project?.name}
                rules={{
                  required: 'Project name is required',
                }}
                render={({ field }) => (
                  <TextField
                    label="Project Name"
                    id="frameioProjectName"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    helperText={frameIoErrors?.projectName ? frameIoErrors?.projectName.message : null}
                    error={!!frameIoErrors?.projectName}
                    disabled={!!frameIoErrors?.token && userDefinedToken}
                    {...field}
                  />
                )}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Integrations;
