import { useEffect, useState } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useApi, Get } from '../contexts/Api';
import { TimeFilter } from '../routes/insights/DateTimePickerDialog';
import { useDeploymentSearch } from './deployments';

export type BillingData = {
  TimePeriod: {
    Start: string;
    End: string;
  };
  Groups: {
    Keys: string[];
    Metrics: {
      UnblendedCost: { Amount: number; Unit: 'USD' };
    };
  }[];
  Total: Record<string, never>;
  Estimated: boolean;
};

export type BillingResponse = {
  billingData: BillingData[];
  totalValue: number;
  totalValueUnit: 'USD';
};

type DeployBillingParams = {
  groupByTag?: string;
  granularity?: 'daily' | 'weekly' | 'monthly' | 'yearly';
  startDate?: string;
  endDate?: string;
  getTotalCost: boolean;
  filter?: Record<string, unknown>;
};

async function getBillingData(get: Get, params: DeployBillingParams) {
  const { data } = await get<BillingResponse>('/integrations/billing/aws', { params });
  return data;
}

export function useBilling(
  queryParams: DeployBillingParams,
  options?: UseQueryOptions<BillingResponse, unknown, BillingResponse, (string | DeployBillingParams)[]>,
): UseQueryResult<BillingResponse> {
  const { get } = useApi();
  return useQuery(['billing', queryParams], () => getBillingData(get, queryParams), {
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 5,
    ...options,
  });
}

type TranscoderDeployment = {
  itemType: 'deployment';
  connectable: false;
  regionName: string;
  stackID: string;
  stackName: string;
  type: 'transcoder';
  viewGroup: null;
  dateAddedInMicroSeconds: number;
  name: string;
  UUID: string;
  status: 'deleted' | 'created';
  nameLowerCase: string;
  lastUpdatedInMicroSeconds: number;
  isDeleted?: number;
  ownerID_deleted?: string;
  powerState?: 'shutting-down' | 'stopped' | 'running' | 'starting';
};

type DCVDeployment = {
  itemType: 'deployment';
  regionName: string;
  stackID: string;
  stackName: string;
  fullUsername: string;
  ownerID_nd: string;
  type: 'dcv';
  viewGroup: string;
  dateAddedInMicroSeconds: number;
  hasGateway: boolean;
  name: string;
  connection: {
    gateway: {
      endpoint: string;
    };
    ipAddress: string;
  };
  UUID: string;
  fqdnUsername: string;
  status: 'deleted';
  username: string;
  nameLowerCase: string;
  EC2Instance: string;
  cf_resources: {
    EC2Instance: string;
    InstanceSecurityGroup: string;
  };
  cf_outputs: {
    endpoint: string;
    InstanceId: string;
    ipAddress: string;
    AZ: string;
  };
  lastUpdatedInMicroSeconds: number;
  powerState: 'shutting-down' | 'stopped' | 'running' | 'starting';
  isDeleted?: number;
  ownerID_deleted?: string;
  workstation_gatewayUUID_deleted: string;
  gatewayRegionName?: string;
  gateway?: true;
  securityGroupID?: string;
};

type Deployment = DCVDeployment | TranscoderDeployment;

type Billing = { timePeriod: { Start: string; End: string }; amount: number; unit: 'USD' };
type DCVDeploymentWithBilling = DCVDeployment & { billingData: Billing[]; totalCost: number };
type TranscoderDeploymentWithBilling = TranscoderDeployment & { billingData: Billing[]; totalCost: number };
export type DeploymentWithBilling = DCVDeploymentWithBilling | TranscoderDeploymentWithBilling;

type Query = {
  query: {
    bool: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      must: Record<string, any>[];
    };
  };
};

export function useDeploymentBilling({ timeFilter }: { timeFilter: TimeFilter['filter'] }) {
  const startTime = 'gte' in timeFilter && timeFilter.gte;
  const endTime = 'lte' in timeFilter && timeFilter.lte;

  const [data, setData] = useState<{ deploymentBillingData: DeploymentWithBilling[]; totalCost: number }>();
  const deploymentQuery: Query = {
    query: {
      bool: {
        must: [
          {
            match: {
              itemType: 'deployment',
            },
          },
          {
            bool: {
              should: [
                {
                  bool: {
                    must_not: { exists: { field: 'isDeleted' } },
                  },
                },
              ],
            },
          },
        ],
      },
    },
  };

  if (startTime && deploymentQuery.query.bool.must[1] && deploymentQuery.query.bool.must[1].bool) {
    deploymentQuery.query.bool.must[1].bool.should.push({ range: { isDeleted: { gte: startTime } } });
  }

  if (endTime) {
    deploymentQuery.query.bool.must.push({ range: { dateAddedInMicroSeconds: { lte: endTime } } });
  }

  const deployments = useDeploymentSearch<Deployment>(deploymentQuery);

  const startDate = 'gte' in timeFilter && new Date(timeFilter.gte / 1000).toISOString().split('T')[0];
  const endDate = 'lte' in timeFilter && new Date(timeFilter.lte / 1000).toISOString().split('T')[0];
  const billing = useBilling({
    groupByTag: 'UUID',
    getTotalCost: true,
    startDate: startDate || undefined,
    endDate: endDate || undefined,
  });

  const isLoading = billing.isLoading || deployments.isLoading;
  const isError = billing.isError || deployments.isError;

  useEffect(() => {
    if (deployments.data && billing.data) {
      const deploymentData = deployments.data.pages.flatMap((page) => page.results);
      let totalCost = 0;
      const combinedData: DeploymentWithBilling[] = deploymentData.map((deployment) => {
        const deploymentBillingData: Billing[] = [];
        let totalDeploymentCost = 0;
        billing.data.billingData.forEach((bill) => {
          const groups = bill.Groups.filter((group) => group.Keys.includes(`UUID$${deployment.UUID}`));
          groups.map((gr) => {
            const amount = Number(gr.Metrics.UnblendedCost.Amount);
            totalCost += amount;
            totalDeploymentCost += amount;
            return deploymentBillingData.push({
              timePeriod: bill.TimePeriod,
              amount: Number(gr.Metrics.UnblendedCost.Amount),
              unit: gr.Metrics.UnblendedCost.Unit,
            });
          });
        });
        return { ...deployment, billingData: deploymentBillingData, totalCost: totalDeploymentCost };
      });
      setData({ deploymentBillingData: combinedData, totalCost });
    }
  }, [deployments.data, billing.data]);

  return {
    isLoading,
    isError,
    data: data?.deploymentBillingData,
    totalCost: data?.totalCost || 0,
    billingResponse: billing.data,
  };
}
