import { Typography, Box } from '@mui/material';
import ErrorImage from './404.png';

const Page404 = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: 6, justifyContent: 'center', alignItems: 'center' }}>
      <img src={ErrorImage} alt="404" />
      <Typography variant="h4">Sorry, the page you are looking for does not exist</Typography>
    </Box>
  );
};

export default Page404;
