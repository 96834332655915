import { useState, FunctionComponent } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory, useParams } from 'react-router-dom';
import { Avatar, Chip, Tooltip, Typography, Tabs, Tab, Button } from '@mui/material';
import BackIcon from '@mui/icons-material/KeyboardBackspace';
import RegionIcon from '@mui/icons-material/LanguageSharp';
import { useJob } from '../../api/job';
import { useConfig } from '../../api/config';
import Loading from '../../common/Loading';
import Files from './files/Files';
import Overview from './overview/Overview';
import Timeline from './timeline/Timeline';
import Versions from './versions/Versions';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  headerTitle: {
    marginTop: 4,
    marginLeft: 10,
  },
  headerAvatar: {
    color: theme.palette.primary.main,
  },
  headerActions: {
    marginLeft: 'auto',
  },
  tabPanel: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  tabsRoots: {
    backgroundColor: theme.palette.background.paper,
  },
  tabsIndicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  tabRoot: {
    textTransform: 'none',
    opacity: 0.4,
    fontWeight: theme.typography.fontWeightBold,
    '&:focus': {
      opacity: 1,
    },
  },
}));

const TabPanel: FunctionComponent<{ value: string; index: number }> = ({ children, value, index }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== history.location.pathname}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanel}
    >
      {value === history.location.pathname && children}
    </div>
  );
};

function Job() {
  const history = useHistory();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(history.location.pathname);
  const { projectId, jobId } = useParams<{ projectId: string; jobId: string }>();
  const { data: config } = useConfig();
  const { isLoading, data: job, isError } = useJob(jobId);

  const getJobRegionName = (regionName: string) => {
    return config?.awsRegionsMap[regionName] || regionName;
  };

  if (isLoading) {
    return <Loading aria-label="Loading job" />;
  }

  if (!job || isError) {
    return (
      <Typography variant="h6">
        Sorry there was been a problem loading your job please go back and try again.
      </Typography>
    );
  }

  return (
    <>
      <div className={classes.header}>
        <Avatar className={classes.headerAvatar}>
          {job.jobName
            .split(' ')
            .map((w) => w.charAt(0))
            .join('')
            .substring(0, 2)
            .toUpperCase()}
        </Avatar>
        <Typography variant="h6" className={classes.headerTitle}>
          {job.jobName}
        </Typography>
        <div style={{ display: 'flex', marginLeft: 10, alignItems: 'center', justifyContent: 'center' }}>
          <Tooltip title="Region">
            <Chip
              label={getJobRegionName(job.regionName)}
              size="small"
              color="primary"
              icon={<RegionIcon />}
              variant="outlined"
            />
          </Tooltip>
        </div>
        <div className={classes.headerActions}>
          <Button
            variant="outlined"
            onClick={() => history.push(`/project/${projectId}`)}
            startIcon={<BackIcon />}
            sx={{ maxHeight: '35px', alignSelf: 'center' }}
          >
            Back
          </Button>
        </div>
      </div>
      <div>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => {
            setSelectedTab(newValue);
            history.push(newValue);
          }}
          indicatorColor="secondary"
          TabIndicatorProps={{ children: <span /> }}
          classes={{ root: classes.tabsRoots, indicator: classes.tabsIndicator }}
        >
          <Tab
            label="Overview"
            value={`/project/${projectId}/job/${jobId}/overview`}
            classes={{ root: classes.tabRoot }}
            disableRipple
          />
          <Tab
            label="Files"
            value={`/project/${projectId}/job/${jobId}/files`}
            classes={{ root: classes.tabRoot }}
            disableRipple
          />
          <Tab
            label="Versions"
            value={`/project/${projectId}/job/${jobId}/versions`}
            classes={{ root: classes.tabRoot }}
            disableRipple
          />
          <Tab
            label="Timeline"
            value={`/project/${projectId}/job/${jobId}/timeline`}
            classes={{ root: classes.tabRoot }}
            disableRipple
          />
        </Tabs>

        <TabPanel value={`/project/${projectId}/job/${jobId}/overview`} index={0}>
          <Overview job={job} />
        </TabPanel>

        <TabPanel value={`/project/${projectId}/job/${jobId}/files`} index={1}>
          <Files />
        </TabPanel>

        <TabPanel value={`/project/${projectId}/job/${jobId}/versions`} index={2}>
          <Versions />
        </TabPanel>

        <TabPanel value={`/project/${projectId}/job/${jobId}/timeline`} index={3}>
          <Timeline />
        </TabPanel>
      </div>
    </>
  );
}

export default Job;
