import { useQuery, UseQueryResult } from 'react-query';
import { useApi, Post } from '../contexts/Api';
import { getIdToken, getAccessToken, getRefreshToken } from './token';

export type LoginCodeResponse = {
  result: { code: string };
};

async function fetchLoginCode(post: Post) {
  const body = {
    accessToken: getAccessToken(),
    idToken: getIdToken(),
    refreshToken: getRefreshToken(),
  };
  const { data } = await post<LoginCodeResponse>('/code', body);
  return data.result.code;
}

export function useLoginCode(): UseQueryResult<string> {
  const { post } = useApi();
  return useQuery('code', () => fetchLoginCode(post), {
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 5,
  });
}
