import MaterialSkeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';

const Skeleton = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', width: '100%', padding: 1, justifyContent: 'space-between' }}>
        <MaterialSkeleton animation="wave" variant="text" width="20%" />
        <MaterialSkeleton animation="wave" variant="text" width="20%" />
      </Box>
      <MaterialSkeleton animation="wave" variant="rectangular" width="100%" height="72px" />
      <MaterialSkeleton animation="wave" variant="rectangular" width="100%" height="72px" />
    </Box>
  );
};

export default Skeleton;
