import { FunctionComponent } from 'react';
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import prettyBytes from 'pretty-bytes';
import getLocalDateTime from '../utils/getLocalDateTime';

type Props = {
  data: { timestamp: number; value: number }[];
  startTime: number;
  endTime: number;
  yAxisUnit?: string;
  yAxisLabel?: string;
  tickFormatter?: { xAxis: Intl.DateTimeFormatOptions; yAxis: (value: number, unit?: string) => string };
};

export function valueFormatter(value: number | string, unit?: string) {
  if (!unit) {
    return value.toString();
  }

  if (unit?.toLowerCase() === 'percent') {
    return `${value}%`;
  }

  if (unit?.toLowerCase() === 'bytes') {
    return `${prettyBytes(value as number)}`;
  }

  return `${value} ${unit}`;
}

export const TimeSeriesLineChart: FunctionComponent<Props> = ({
  data,
  startTime,
  endTime,
  yAxisLabel,
  yAxisUnit,
  tickFormatter = { xAxis: { hour: 'numeric', minute: 'numeric' }, yAxis: valueFormatter },
}) => {
  return (
    <ResponsiveContainer width={'100%'} minHeight={300}>
      <LineChart data={data}>
        <XAxis
          dataKey="timestamp"
          name="Time"
          scale="time"
          domain={[startTime, endTime]}
          type="number"
          tickFormatter={(unixTime: number) => getLocalDateTime(unixTime, tickFormatter.xAxis)}
          interval="preserveStartEnd"
          label={{ value: 'Time', position: 'insideBottom', offset: -5 }}
        />
        <YAxis
          type="number"
          name={yAxisLabel}
          interval="preserveStartEnd"
          tickFormatter={(value) => tickFormatter.yAxis(value, yAxisUnit)}
        />
        <Tooltip
          formatter={(value: number, name: string) => [tickFormatter.yAxis(value, yAxisUnit), yAxisLabel]}
          labelFormatter={(value: number) => getLocalDateTime(value, tickFormatter.xAxis)}
        />
        <Line type="monotone" dataKey="value" stroke="#8884d8" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TimeSeriesLineChart;
