import {
  S3Client,
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
  CompletedMultipartUpload,
} from '@aws-sdk/client-s3';
import { add, isFuture } from 'date-fns';
import { md5 } from 'hash-wasm';
import { Get } from '../../../contexts/Api';

export type FileWithPath = File & { webkitRelativePath?: string };

type Credentials = {
  AWS_ACCESS_KEY_ID: string;
  AWS_SECRET_ACCESS_KEY: string;
  AWS_DEFAULT_REGION: string;
  AWS_SESSION_TOKEN: string;
  timeToExpireInSeconds: number;
};

const defaultPartSize = 5242880;

function splitFileIntoParts(file: File, partSize = defaultPartSize) {
  const parts = [];
  let pointer = 0;
  while (pointer < file.size) {
    let endPointer = pointer + partSize;
    if (endPointer > file.size) {
      endPointer = file.size;
    }
    parts.push(file.slice(pointer, endPointer));
    pointer = endPointer;
  }
  return parts;
}
type UploadPartParams = {
  part: Blob;
  client: S3Client;
  uploadId: string;
  bucket: string;
  key: string;
  partNumber: number;
  md5Hash?: string;
  // abortSignal: AbortSignal;
};

function hexToBase64(hexStr: string) {
  let base64 = '';
  for (let i = 0; i < hexStr.length; i++) {
    base64 += !((i - 1) & 1) ? String.fromCharCode(parseInt(hexStr.substring(i - 1, i + 1), 16)) : '';
  }
  return btoa(base64);
}

async function uploadPart({ uploadId, partNumber, part, bucket, key, client, md5Hash }: UploadPartParams) {
  const command = new UploadPartCommand({
    UploadId: uploadId,
    PartNumber: partNumber,
    Body: part,
    Bucket: bucket,
    Key: key,
    ContentMD5: md5Hash ? hexToBase64(md5Hash) : undefined,
  });
  return client.send(command);
}

export type UploadProgress =
  | {
      file: FileWithPath;
      index: number;
      loaded: number;
      part: number;
      isError: false;
      isComplete?: boolean;
    }
  | { isError: true; file: FileWithPath; index: number; errorMessage?: string; isComplete?: boolean };

type UploadFileProps = {
  file: FileWithPath;
  key: string;
  bucket: string;
  useAccelerateEndpoint: boolean;
  index: number;
};

type UploadProps = {
  files: FileWithPath[];
  path: string;
  bucket: string;
};

type ProgressEventHandler = (props: UploadProgress) => void;

export class Upload {
  files: FileWithPath[];
  progress: Record<string, UploadProgress>;
  onProgress?: ProgressEventHandler;
  bucket = '';
  private get: Get;
  private clients: Record<string, { client: S3Client; expiry: Date }> = {};
  private accelerationEnabled = false;

  constructor(get: Get) {
    this.files = [];
    this.get = get;
    this.progress = {};
  }

  setClient = ({ key, client, expiry }: { key: string; client: S3Client; expiry: Date }) => {
    this.clients[key] = { client, expiry };
  };

  getClient = async (key: string) => {
    if (this.clients[key] && isFuture(this.clients[key].expiry)) {
      return this.clients[key].client;
    }
    const queryParams = { objectName: key, bucketName: this.bucket, action: 'PUT', base64: false };
    const { data } = await this.get<Credentials>('/s3/credentials', { params: queryParams });
    const s3Client = new S3Client({
      credentials: {
        accessKeyId: data.AWS_ACCESS_KEY_ID,
        secretAccessKey: data.AWS_SECRET_ACCESS_KEY,
        sessionToken: data.AWS_SESSION_TOKEN,
      },
      region: data.AWS_DEFAULT_REGION,
      useAccelerateEndpoint: this.accelerationEnabled,
    });
    this.setClient({ key, client: s3Client, expiry: add(Date.now(), { seconds: data.timeToExpireInSeconds - 30 }) });
    return s3Client;
  };

  private uploadFile = async ({ key, bucket, file, index }: UploadFileProps) => {
    // const abortController = new AbortController();
    try {
      let s3 = await this.getClient(key);

      const createCommand = new CreateMultipartUploadCommand({
        Bucket: bucket,
        Key: key,
        ServerSideEncryption: 'AES256',
        ContentType: file.type,
        Metadata: { 'x-amz-meta-uploaded-from': 'web' },
      });
      const upload = await s3.send(createCommand);
      const parts = splitFileIntoParts(file);
      const completedPartsMap: CompletedMultipartUpload = { Parts: [] };
      let bytesLoaded = 0;
      for (let pi = 0; pi < parts.length; pi++) {
        const part = parts[pi];
        const partNumber = pi + 1;
        const view = new Uint8Array(await part.arrayBuffer());
        const hashValue = await md5(view);
        const s3Client = await this.getClient(key);
        const result = await uploadPart({
          key,
          bucket,
          part,
          client: s3Client,
          partNumber,
          uploadId: upload.UploadId || '',
          md5Hash: hashValue,
          // abortSignal: abortController.signal,
        });

        completedPartsMap.Parts && completedPartsMap.Parts.push({ ETag: result.ETag, PartNumber: partNumber });

        bytesLoaded += part.size;
        this.onProgress &&
          this.onProgress({
            file,
            index,
            loaded: bytesLoaded,
            part: partNumber,
            isError: false,
            isComplete: partNumber === parts.length,
          });
      }

      const completeCommand = new CompleteMultipartUploadCommand({
        Bucket: bucket,
        Key: key,
        UploadId: upload.UploadId,
        MultipartUpload: completedPartsMap,
      });
      s3 = await this.getClient(key);
      const complete = await s3.send(completeCommand);
      return complete;
    } catch (err) {
      const error = err as { message: string };
      this.onProgress &&
        this.onProgress({
          file,
          index,
          isError: true,
          errorMessage: error.message,
        });
    }
  };

  startUpload = async ({ files, path, bucket }: UploadProps) => {
    try {
      this.bucket = bucket;
      const { data } = await this.get<{ accelerationEnabled: boolean; endpoint: string }>(
        `/s3/bucket/${bucket}/endpoint`,
      );
      this.accelerationEnabled = data.accelerationEnabled;
      const uploadPromises = files.map((file, index) =>
        this.uploadFile({
          file,
          key: `${path ? path + '/' : ''}${file.webkitRelativePath || file.name}`,
          bucket,
          index,
          useAccelerateEndpoint: data.accelerationEnabled,
        }),
      );
      return Promise.all(uploadPromises);
    } catch (err) {
      console.log('🚀 ~ file: upload.ts ~ line 125 ~ startUpload ~ err', err);
    }
  };
}

export default Upload;
