import { Typography, Button, Box } from '@mui/material';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useUserDeployments } from '../../api/deployments';
import Loading from '../../common/Loading';
import DeploymentsTable from './WorkstationTable';

export const MyWorkStations: FunctionComponent = () => {
  const [shouldPollDeployments, setShouldPollDeployments] = useState(false);
  const { isLoading, data, isError, hasNextPage, isFetchingNextPage, fetchNextPage } = useUserDeployments(
    { type: 'dcv' },
    {
      refetchInterval: shouldPollDeployments ? 30000 : false,
      refetchIntervalInBackground: true,
    },
  );

  const deploymentPages = data?.pages;
  const deployments = useMemo(() => {
    return deploymentPages ? deploymentPages.flatMap((page) => page.deployments) : [];
  }, [deploymentPages]);

  useEffect(() => {
    if (deployments.length) {
      let updatingState = false;
      deployments.forEach((deployment) => {
        if (deployment.powerState === 'pending' || deployment.status === 'deploying') {
          updatingState = true;
          return;
        }
      });
      setShouldPollDeployments(updatingState);
    }
  }, [deployments]);

  if (isLoading) {
    return <Loading aria-label="Loading workstations" />;
  }

  if (isError) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 80, width: '100%' }}>
        <Typography>Sorry there has been a problem fetching your workstations. Please reload and try again</Typography>
      </Box>
    );
  }

  if (!deployments.length) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 80, width: '100%' }}>
        <Typography>No workstations available</Typography>
      </Box>
    );
  }

  return (
    <>
      <DeploymentsTable data={deployments} accessType="owner" showAdditional={false} />
      {hasNextPage && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 80, width: '100%' }}>
          <Button variant="outlined" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
            {isFetchingNextPage ? 'Loading...' : 'Load More'}
          </Button>
        </Box>
      )}
    </>
  );
};

export default MyWorkStations;
