import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Chip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useRoles, useUsers } from '../../../../api/user';
import Loading from '../../../../common/Loading';
import { ProjectFormValues } from '../CreateProjectDialog';

type UserProps = {
  form: UseFormReturn<ProjectFormValues>;
};

export function Users({ form }: UserProps) {
  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = form;
  const userRoles = watch('users', {});
  const { data: users, isLoading: isLoadingUsers } = useUsers();
  const { data: roles, isLoading: isLoadingRoles } = useRoles({ roleType: 'project' });
  const [selectedRole, setSelectedRole] = useState('0');
  const currentRole = roles && roles[Number(selectedRole)];

  const handleToggle = (email: string) => () => {
    if (currentRole) {
      const updatedUserRole = { ...userRoles };
      if (updatedUserRole[email]) {
        delete updatedUserRole[email];
      } else {
        updatedUserRole[email] = { email, roleUUID: currentRole.UUID, roleName: currentRole.roleName };
      }

      setValue('users', updatedUserRole, { shouldDirty: true, shouldValidate: true });
    }
  };

  function isUserSelected(email: string) {
    if (currentRole) {
      if (userRoles && userRoles[email] && userRoles[email].roleUUID === currentRole.UUID) {
        return true;
      }
    }
    return false;
  }

  function isUserDisabled(email: string) {
    if (currentRole) {
      if (userRoles && userRoles[email] && userRoles[email].roleUUID !== currentRole.UUID) {
        return true;
      }
    }
    return false;
  }

  if (isLoadingUsers || isLoadingRoles) {
    return <Loading />;
  }

  if (roles?.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 1, pt: 2 }}>
        <Typography>Sorry, no project roles have been set up yet.</Typography>
        <Typography>To create a new project there must be at least 1 project role</Typography>
        <Typography>Please speak to your adminstrator.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexDirection: 'column', flexGrow: '1', display: 'flex' }}>
      <Typography sx={{ pb: 1, pt: 1 }}>
        Assign roles to users by selecting the role and then the users. Only one role per user is permitted
      </Typography>
      <FormLabel sx={{ paddingLeft: 0 }}>Roles</FormLabel>
      <RadioGroup
        row
        value={selectedRole}
        onChange={(event) => setSelectedRole((event.target as HTMLInputElement).value)}
        sx={{ pl: 0.5 }}
      >
        {roles?.map((role, index) => (
          <FormControlLabel label={role.roleName} value={index} key={role.UUID} control={<Radio color="default" />} />
        ))}
      </RadioGroup>
      {errors.users && (
        <Typography variant="body2" color="error">
          {errors.users.message}
        </Typography>
      )}
      <Box sx={{ overflowY: 'scroll', flexGrow: '1', display: 'flex' }}>
        <Controller
          name="users"
          control={control}
          rules={{
            validate: (vl) => (vl && Object.keys(vl).length > 0 ? true : 'At least one user is required'),
          }}
          render={({ field }) => (
            <List dense sx={{ width: '100%' }}>
              {users?.map((user) => (
                <ListItem
                  key={user.email}
                  disablePadding
                  secondaryAction={
                    isUserDisabled(user.email) && (
                      <Chip
                        label={userRoles[user.email].roleName}
                        size="small"
                        variant="outlined"
                        onDelete={handleToggle(user.email)}
                      />
                    )
                  }
                >
                  <ListItemButton
                    onClick={handleToggle(user.email)}
                    dense
                    disabled={isUserDisabled(user.email)}
                    sx={{ pl: 0.5 }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={isUserSelected(user.email)}
                        tabIndex={-1}
                        disableRipple
                        color="default"
                      />
                    </ListItemIcon>
                    <ListItemText>{user.email}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        />
      </Box>
    </Box>
  );
}

export default Users;
