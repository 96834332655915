import { FunctionComponent, useState } from 'react';
import { Box, Dialog, DialogContent, Tab, Tabs, Typography } from '@mui/material';
import { Deployment } from '../../../api/deployments';
import EditProperties from './EditProperties';
import EditShutdownPolicy from './EditShutdownPolicy';

type EditWorkstationDialog = {
  open: boolean;
  onClose: () => void;
  deployment: Deployment;
};

export const EditWorkstation: FunctionComponent<EditWorkstationDialog> = ({ open, onClose, deployment }) => {
  const [selectedTab, setTab] = useState('details');
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" aria-label="Edit workstation">
      <DialogContent>
        <Typography variant="h6" sx={{ paddingBottom: 2 }}>
          Edit Workstation
        </Typography>
        {deployment.type === 'dcv' && (
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
            <Tabs value={selectedTab} onChange={(_, tab) => setTab(tab)} aria-label="basic tabs example">
              <Tab label="Properties" value="details" />
              <Tab label="Shutdown policy" value="shutdown" />
            </Tabs>
          </Box>
        )}
        {selectedTab === 'details' && <EditProperties onCancel={onClose} onSuccess={onClose} deployment={deployment} />}
        {selectedTab === 'shutdown' && (
          <EditShutdownPolicy onCancel={onClose} onSuccess={onClose} deployment={deployment} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EditWorkstation;
