import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import axios, { CancelToken } from 'axios';
import { Get, Put, useApi } from '../contexts/Api';
import { ProjectDetails, useCurrentProject } from './projects';

type SignedUrlResponse = {
  url: string;
};
async function fetchSignedUrl(get: Get, bucket: string, path?: string, projectUUID?: string) {
  const { data } = await get<SignedUrlResponse>(`/s3/bucket/${bucket}/signedurl`, {
    params: { objectName: path, projectUUID },
  });
  return data.url;
}

export function useSignedUrl(bucket: string, path?: string): UseQueryResult<ProjectDetails | undefined> {
  const { data: project } = useCurrentProject(false);
  const { get } = useApi();
  return useQuery(['signedUrl', bucket, path, project?.UUID], () => fetchSignedUrl(get, bucket, path, project?.UUID), {
    cacheTime: 1000 * 60 * 5, //5mins
    staleTime: 1000 * 60 * 3, //3mins
    retry: 1,
    enabled: !!path,
  });
}

async function getBuckets(get: Get) {
  const { data } = await get<string[]>('/buckets');
  return data;
}

export function useBuckets() {
  const { get } = useApi();
  return useQuery(['buckets'], () => getBuckets(get));
}

export type File = {
  mediaType: 'video' | 'image' | 'audio' | 'text' | 'unknown';
  ETag: string;
  Key: string;
  LastModified: string;
  Size: number;
  StorageClass: string;
  scrubURL: string;
  thumbURL: string;
  fileName: string;
  playable: boolean;
};

export type BucketResponse = {
  files: File[];
  folders: string[];
  marker: null | string;
};

type SearchResponse = {
  files: File[];
  marker: null | string;
};

export async function fetchBucketContents(
  get: Get,
  bucket: string,
  prefix: string,
  query?: string | Record<string, unknown>,
  projectUUID?: string,
  marker?: BucketResponse['marker'],
  cancelToken?: CancelToken,
): Promise<BucketResponse> {
  const params: {
    prefix: string;
    marker?: BucketResponse['marker'];
    query?: string;
    projectUUID?: string;
    showTags: true;
    advancedSearch?: Record<string, unknown>;
  } = { prefix, marker, showTags: true, projectUUID };
  if (query) {
    if (typeof query === 'string') {
      // * used to return all children of a prefix
      params.query = query === '*' ? undefined : query;
    } else {
      params.advancedSearch = query;
    }
    const { data } = await get<SearchResponse>(`/s3/bucket/${bucket}/search`, { params, cancelToken });
    if (data.marker && data.files.length === 0) {
      return fetchBucketContents(get, bucket, prefix, query, projectUUID, data.marker, cancelToken);
    }
    return { ...data, folders: [] };
  }
  const { data } = await get<BucketResponse>(`/s3/bucket/${bucket}`, { params, cancelToken });
  return data;
}

type CancelablePromise<T> = Promise<T> & { cancel?: () => void };

export function useS3Bucket({
  bucket,
  prefix,
  searchQuery,
}: {
  bucket: string;
  prefix: string;
  searchQuery?: string | Record<string, unknown>;
}): UseInfiniteQueryResult<BucketResponse> {
  const { data: project } = useCurrentProject(false);
  const { get } = useApi();
  return useInfiniteQuery<BucketResponse>(
    [bucket, prefix, searchQuery],
    ({ pageParam }) => {
      const source = axios.CancelToken.source();
      const promise: CancelablePromise<BucketResponse> = fetchBucketContents(
        get,
        bucket,
        prefix,
        searchQuery,
        project?.UUID,
        pageParam,
        source.token,
      );
      promise.cancel = () => {
        source.cancel('Query cancelled by React Query');
      };
      return promise;
    },
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 10,
      getNextPageParam: (lastPage) => lastPage.marker,
      enabled: !!bucket,
    },
  );
}

async function fetchTags(get: Get) {
  const { data } = await get<{ tags: string[] }>('/files/tag');
  return data.tags;
}

export function useTags(): UseQueryResult<string[]> {
  const { get } = useApi();
  return useQuery('tags', () => fetchTags(get), {
    cacheTime: 1000 * 60 * 5, // 5mins
    staleTime: 1000 * 60 * 3, // 3mins
  });
}

type EditTagBody = {
  tags: string[];
  files: { bucketName: string; objectName: string }[];
  tagAction: 'add';
};

async function editTags(put: Put, body: EditTagBody) {
  const { data } = await put<{ message: string }>('/files/tag', body);
  return data;
}

export function useEditTags(
  options?: UseMutationOptions<{ message: string }, unknown, EditTagBody, unknown>,
): UseMutationResult<{ message: string }, unknown, EditTagBody, unknown> {
  const { put } = useApi();
  const queryClient = useQueryClient();
  return useMutation((body) => editTags(put, body), {
    ...options,
    onSuccess: (data, variables, ctx) => {
      queryClient.invalidateQueries([variables.files[0].bucketName]);
      queryClient.invalidateQueries('tags');
      options?.onSuccess && options.onSuccess(data, variables, ctx);
    },
  });
}

export type JobFile = {
  hasProxy: boolean;
  fileName: string;
  originalExtension: string;
  originalBucket: string;
  originalFile: string;
  projectUUID_fileID: string;
  userName: string;
  UUID: string;
  createdBy: {
    userName: string;
    ip: string;
  };
  dateAddedInMicroSeconds: number;
  fileID: string;
  original_fullS3Path: string;
  file_jobUUID: string;
  type: 'file';
  jobUUID_fileID: string;
};

export type JobFilesResponse = {
  lastKey: { file_jobUUID: string; UUID: string } | null;
  files: JobFile[];
};

export async function fetchJobFiles(
  get: Get,
  jobUUID: string,
  lastKey?: JobFilesResponse['lastKey'],
): Promise<JobFilesResponse> {
  const params = { jobUUID, lastKey };
  const { data } = await get<JobFilesResponse>('/files', { params });
  return data;
}

export function useJobFiles({ jobId }: { jobId: string }): UseInfiniteQueryResult<JobFilesResponse> {
  const { get } = useApi();
  return useInfiniteQuery<JobFilesResponse>(
    ['jobFiles', jobId],
    ({ pageParam }) => fetchJobFiles(get, jobId, pageParam),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 10,
      getNextPageParam: (lastPage) => lastPage.lastKey,
    },
  );
}
