import { Link } from 'react-router-dom';
import { IconButton, TableContainer, useTheme } from '@mui/material';
import PreviewIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import CommentsIcon from '@mui/icons-material/MessageOutlined';
import { Table, Column } from '@nekta-temp/component-library.table';
import { startCase } from 'lodash';
import { getLocalDateTime } from '../../../utils/getLocalDateTime';
import { Version } from '../../../api/versions';
import { useSnackbar } from '../../../contexts/Snackbar';
import { useApi } from '../../../contexts/Api';

export function VersionTable({
  versions,
  openComments,
  openPreview,
  type,
}: {
  versions: Version[];
  openComments: (version: Version) => void;
  openPreview: (versionId: string) => void;
  type: 'approval' | 'submission';
}) {
  const theme = useTheme();
  const { openSnackbar } = useSnackbar();
  const { put } = useApi();

  const retriggerConform = async (versionUUID: string) => {
    try {
      await put<{ message?: string }>(`/version/${versionUUID}/retrigger`, {});
      return openSnackbar('Conform process initiated succesfully!');
    } catch (err) {
      return openSnackbar('An error occured while trying to re trigger conform process.');
    }
  };

  const columns: Column<Version>[] = [
    {
      Header: 'Name',
      accessor: 'versionName',
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
      Cell: ({ value }) => value.userName,
    },
    {
      Header: 'Created Date',
      accessor: 'dateAddedInMicroSeconds',
      Cell: ({ value }) => getLocalDateTime(value / 1000, { timeStyle: 'short', dateStyle: 'short' }),
    },
    {
      Header: 'Status',
      accessor: 'versionStatus',
      Cell: ({ value }) => startCase(value),
    },
    {
      Header: 'Platform',
      accessor: 'platform',
      Cell: ({ value }) => {
        if (value && value?.uniqueIdentifier?.link) {
          return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              href="#"
              aria-label="version-external-link"
              onClick={(e) => {
                e.preventDefault();
                window.open(value?.uniqueIdentifier?.link);
              }}
              to=""
              style={{ textDecoration: 'none', color: theme.palette.secondary.main }}
            >
              {startCase(value?.name) || 'N/A'}
            </Link>
          );
        }
        return startCase(value?.name) || 'N/A';
      },
    },
    {
      Header: 'Comments',
      accessor: 'commentsExist',
      Cell: ({ value, row }) => {
        if (value) {
          return (
            <IconButton
              edge="end"
              aria-label="comments"
              onClick={() => openComments(row.original)}
              disableRipple
              size="large"
            >
              <CommentsIcon color="primary" />
            </IconButton>
          );
        }
        return null;
      },
    },
    {
      Header: 'Preview',
      accessor: 'mediaExists',
      Cell: ({ value, row }) => {
        if (value) {
          return (
            <IconButton edge="end" aria-label="preview" onClick={() => openPreview(row.original.UUID)} size="large">
              <PreviewIcon color="primary" />
            </IconButton>
          );
        }
        return null;
      },
    },
    {
      Header: 'Retrigger',
      accessor: 'versionState', // unused accessor for typing error
      Cell: ({ row }) => {
        if (
          row.original.versionStatus.toLowerCase() === 'failed to encode' ||
          row.original.versionStatus.toLowerCase() === 'failed'
        ) {
          return (
            <IconButton
              edge="end"
              aria-label="retrigger"
              onClick={() => retriggerConform(row.original.UUID)}
              size="large"
            >
              <ReplayIcon color="primary" />
            </IconButton>
          );
        }
        return null;
      },
    },
  ];

  if (type === 'submission') {
    columns.splice(5, 1); // remove comments column
  }

  return (
    <TableContainer>
      <Table<Version> columns={columns} data={versions} />
    </TableContainer>
  );
}

export default VersionTable;
