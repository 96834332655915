import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { useApi, Get, Post, Put } from '../contexts/Api';

export type TranscodeJob = {
  UUID: string;
  createdBy: { userName: string; ip: string };
  dateAddedInMicroSeconds: number;
  height: number;
  width: number;
  outputType: string;
  transcodeJob: number;
  transcodeJobName: string;
  transcodeJobStatus: 'inQueue';
  transcodeJobUUID: string;
  lut?: { bucketName: string; s3Key: string };
};
type TranscodeJobsResponse = {
  transcodeJobs: TranscodeJob[];
  lastKey: string | null;
};
async function fetchTranscodeJobs(get: Get, lastKey?: TranscodeJobsResponse['lastKey']) {
  const params = { lastKey };
  const { data } = await get<TranscodeJobsResponse>('/transcode/jobs', { params });
  return data;
}

export function useTranscodeJobs(): UseInfiniteQueryResult<TranscodeJobsResponse> {
  const { get } = useApi();
  return useInfiniteQuery<TranscodeJobsResponse>(
    'transcodeJobs',
    ({ pageParam }) => fetchTranscodeJobs(get, pageParam),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 10,
      getNextPageParam: (lastPage) => lastPage.lastKey,
    },
  );
}

async function fetchTranscodeJob(get: Get, jobId: string) {
  const { data } = await get<TranscodeJob>(`/transcode/job/${jobId}`);
  return data;
}

export function useTranscodeJob({ jobId }: { jobId: string }): UseQueryResult<TranscodeJob> {
  const { get } = useApi();
  return useQuery(['transcodeJob', jobId], () => fetchTranscodeJob(get, jobId), {
    cacheTime: 1000 * 60 * 10, //10mins
    staleTime: 1000 * 60 * 5, //5mins
    enabled: !!jobId,
  });
}

type TranscodeOutputResponse = {
  formats: { name: string; value: string }[];
};

async function fetchTranscodeOutputs(get: Get) {
  const { data } = await get<TranscodeOutputResponse>('/transcode/output/formats');
  return data?.formats;
}

export function useTranscodeOutputs(): UseQueryResult<TranscodeOutputResponse['formats']> {
  const { get } = useApi();
  return useQuery('transcodeOutputs', () => fetchTranscodeOutputs(get), {
    cacheTime: 1000 * 60 * 10, //10mins
    staleTime: 1000 * 60 * 5, //5mins
  });
}

type TranscodeJobBody = {
  name: string;
  height: number;
  width: number;
  outputType: string;
  lut?: { bucketName: string; s3Key: string };
};

type CreateTranscodeJobResponse = {
  message: {
    UUID: string;
    createdBy: { userName: string; ip: string };
    dateAddedInMicroSeconds: number;
    height: number;
    outputType: string;
    transcodeJob: number;
    transcodeJobName: string;
    transcodeJobUUID: string;
    width: number;
  };
};

async function createTranscodeJob(post: Post, job: TranscodeJobBody) {
  const { data } = await post<CreateTranscodeJobResponse>('/transcode/job', job);
  return data.message;
}

export function useCreateTranscodeJob(): UseMutationResult<
  CreateTranscodeJobResponse['message'],
  unknown,
  TranscodeJobBody,
  unknown
> {
  const { post } = useApi();
  const queryClient = useQueryClient();
  return useMutation((job) => createTranscodeJob(post, job), {
    onSuccess: () => {
      queryClient.invalidateQueries('transcodeJobs');
    },
  });
}

export type TranscodeJobFile = {
  UUID: string;
  bucketName: string;
  counter: number;
  createdBy: { userName: string; ip: string };
  dateAddedInMicroSeconds: number;
  encoder: string;
  extension: string;
  fullS3Key: string;
  parts: string[];
  proxyBucket: string;
  regionName: string;
  s3Key: string;
  statusDetails: { label: string; statusMessage: string; statusPercent: number; statusTag: string }[];
  transcodeJobFileStatusUUID: string;
  'transcodeJobUUID-file': string;
  transcodeStatus: 'pending' | 'addedToQueue' | 'inProgress' | 'completed';
};

type LastKey = {
  UUID: string;
  dateAddedInMicroSeconds: number;
  'transcodeJobUUID-file': string;
};

type TranscodeFileResponse = { files: TranscodeJobFile[]; lastKey: LastKey | null };

async function fetchTranscodeJobFiles(get: Get, jobId: string, lastKey: LastKey) {
  const params = { lastKey };
  const { data } = await get<TranscodeFileResponse>(`/transcode/job/${jobId}/files`, { params });
  return data;
}

export function useTranscodeJobFiles({
  jobId,
  refetchInterval = false,
}: {
  jobId: string;
  refetchInterval?: number | false;
}): UseInfiniteQueryResult<TranscodeFileResponse> {
  const { get } = useApi();
  return useInfiniteQuery(
    ['transcodeJobFiles', jobId],
    ({ pageParam }) => fetchTranscodeJobFiles(get, jobId, pageParam),
    {
      cacheTime: 1000 * 60 * 10, //10mins
      staleTime: 1000 * 60 * 5, //5mins
      enabled: !!jobId,
      refetchInterval,
      getNextPageParam: (lastPage) => lastPage.lastKey,
    },
  );
}

async function fetchTranscodeJobFile(get: Get, { jobId, fileId }: { jobId: string; fileId: string }) {
  const { data } = await get<{ file: TranscodeJobFile }>(`/transcode/job/${jobId}/file/${fileId}`);
  return data.file;
}

export function useTranscodeJobFile({
  jobId,
  fileId,
  enabled,
  refetchInterval = false,
}: {
  jobId: string;
  fileId: string;
  enabled: boolean;
  refetchInterval?: number | false;
}): UseQueryResult<TranscodeJobFile> {
  const { get } = useApi();
  return useQuery(['transcodeJobFile', fileId], () => fetchTranscodeJobFile(get, { jobId, fileId }), {
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 10,
    enabled,
    refetchInterval,
  });
}

type FilesBody = {
  jobUUID: string;
  files: { bucketName: string; s3Key: string }[];
};

async function addTranscodeJobFiles(post: Post, body: FilesBody) {
  const { data } = await post<{ job: { UUID: string } }>(`/transcode/job/${body.jobUUID}/file`, {
    files: body.files,
  });
  return data;
}

export function useAddTranscodeJobFiles(): UseMutationResult<
  {
    job: {
      UUID: string;
    };
  },
  unknown,
  FilesBody,
  unknown
> {
  const queryClient = useQueryClient();
  const { post } = useApi();
  return useMutation((files) => addTranscodeJobFiles(post, files), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['transcodeJobFiles', variables.jobUUID]);
    },
  });
}

type RunJobResponse = {
  message: string;
};

async function runTranscodeJob(put: Put, jobId: string) {
  const { data } = await put<RunJobResponse>(`/transcode/async/job/${jobId}/run`, {});
  return data;
}

export function useRunTranscodeJob(
  options?: UseMutationOptions<RunJobResponse, { response?: { data?: RunJobResponse } }, string, unknown>,
): UseMutationResult<RunJobResponse, unknown, string, unknown> {
  const { put } = useApi();
  const queryClient = useQueryClient();
  return useMutation((jobId) => runTranscodeJob(put, jobId), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['transcodeJobFiles', variables]);
      queryClient.invalidateQueries('transcodeJobFile');
      queryClient.invalidateQueries('transcodeJobs');
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

type RunTranscodeJobFileProps = {
  jobId: string;
  jobFileId: string;
  s3Key: string;
  bucketName: string;
};

async function runTranscodeJobFile(put: Put, { jobId, jobFileId, s3Key, bucketName }: RunTranscodeJobFileProps) {
  const { data } = await put<RunJobResponse>(`/transcode/job/${jobId}/file/${jobFileId}/run`, {
    s3Key,
    bucketName,
  });
  return data;
}

export function useRunTranscodeJobFile(
  options?: UseMutationOptions<
    RunJobResponse,
    { response?: { data?: RunJobResponse } },
    RunTranscodeJobFileProps,
    unknown
  >,
): UseMutationResult<RunJobResponse, unknown, RunTranscodeJobFileProps, unknown> {
  const { put } = useApi();
  const queryClient = useQueryClient();
  return useMutation((file) => runTranscodeJobFile(put, file), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['transcodeJobFiles', variables.jobId]);
      queryClient.invalidateQueries(['transcodeJobFile', variables.jobFileId]);
      queryClient.invalidateQueries('transcodeJobs');
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}
