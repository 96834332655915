import { Box, CircularProgress } from '@mui/material';

export function Loading(props: { 'aria-label'?: string }) {
  return (
    <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', flexGrow: '1' }}>
      <CircularProgress color="primary" aria-label={props['aria-label'] || 'Loading'} />
    </Box>
  );
}

export default Loading;
