import { useEffect, useMemo, useState } from 'react';
import { Job } from '../../../api/job';
import { ProjectDetails, useSearch } from '../../../api/projects';
import { Version } from '../../../api/versions';

export type EditJob = Job & {
  project: ProjectDetails;
  versions: Version[];
  isOverdue: boolean;
  timeSpentInBusinessDaySeconds: number | undefined;
};

export type TypeFilter = 'inProgress' | 'overdue' | 'completed';
export type TimeFilter = { gte: number } | { lte: number } | { gte: number; lte: number };

// Adds time padding to convert 8 hours to a 24 hour day
function getSecondsInBusinessDay(timeSpentSeconds: number) {
  const hoursSpent = timeSpentSeconds / 60 / 60;
  if (hoursSpent >= 8) {
    const daysSpent = Math.floor(hoursSpent / 8); // assuming 8 working hours per day
    const hoursNotInWholeDay = hoursSpent % 8;
    const daysSpentSeconds = daysSpent * 24 * 60 * 60;
    const totalTimeSpentSeconds = daysSpentSeconds + hoursNotInWholeDay * 60 * 60;
    return { timeSpentSeconds: totalTimeSpentSeconds, daysSpent, hoursNotInWholeDay };
  }

  return { timeSpentSeconds };
}

export function useEditJobDashboard({ typeFilter, timeFilter }: { typeFilter?: TypeFilter; timeFilter?: TimeFilter }) {
  const [isLoading, setLoading] = useState(true);
  const [projectIds, setProjectIds] = useState<string[]>();
  const [projectData, setProjectData] = useState<{ projects: ProjectDetails[]; total: number }>();
  const [jobData, setJobData] = useState<{
    jobRaw: Job[];
    total: number;
    editJobs?: EditJob[];
    inProgressJobs?: EditJob[];
    lateJobs?: number;
  }>();
  const [versionData, setVersionData] = useState<{ versions: Version[]; total: number }>();
  const [editJobs, setEditJobs] = useState<EditJob[]>();

  let startRange: number | undefined = undefined;
  if (timeFilter && 'gte' in timeFilter) {
    startRange = timeFilter.gte;
  }
  let endRange: number | undefined = undefined;
  if (timeFilter && 'lte' in timeFilter) {
    endRange = timeFilter.lte;
  }
  const jobs = useSearch<Job>({
    itemType: 'job',
    showDeleted: false,
    startRange,
    endRange,
  });

  useEffect(() => {
    const hasEditJobData = jobs.data?.pages[0].response;
    if (hasEditJobData) {
      let totalNumberOfJobs = 0;
      const editJobs = jobs.data?.pages.flatMap((page) => {
        totalNumberOfJobs += page.response.total;
        return page.response.hits;
      });
      const projIds = editJobs?.map((job) => job.job_projectUUID);
      if (projIds) {
        setProjectIds(Array.from(new Set(projIds)));
      }
      setJobData({ jobRaw: editJobs || [], total: totalNumberOfJobs });
    }
  }, [jobs.data]);

  const versionSearch = useSearch<Version>({ itemType: 'version', showDeleted: false });

  useEffect(() => {
    const hasVersionsResponse = versionSearch.data?.pages[0].response;
    if (hasVersionsResponse) {
      let total = 0;
      const versions = versionSearch.data?.pages.flatMap((page) => {
        total += page.response.total;
        return page.response.hits;
      });

      setVersionData({ versions: versions || [], total });
    }
  }, [jobs.data, versionSearch.data?.pages]);

  const projectSearch = useSearch<ProjectDetails>(
    { itemType: 'project', showDeleted: false },
    {
      enabled: projectIds ? projectIds?.length > 0 : false,
    },
  );

  useEffect(() => {
    const hasProjectsData = projectSearch.data && projectSearch.data.pages[0]?.response.total > 0;
    if (projectSearch.data && hasProjectsData) {
      let total = 0;
      const projectArray = projectSearch.data.pages.flatMap((page) => {
        total += page.response.total;
        return page.response.hits;
      });

      setProjectData({ projects: projectArray, total });
    }
  }, [projectSearch.data]);

  useEffect(() => {
    // are projects & jobs & versions loaded and does combined view exist
    if (
      jobData?.jobRaw &&
      versionData?.versions &&
      projectData?.projects &&
      (!jobData.editJobs || jobData.editJobs.length !== jobData.total)
    ) {
      let lateJobs = 0;
      const editJobs = jobData.jobRaw
        .map((job) => {
          const versions = versionData.versions
            .filter((version) => version.version_jobUUID === job.UUID)
            .sort((a, b) => b.dateAddedInMicroSeconds - a.dateAddedInMicroSeconds);

          let timeSpentInBusinessDaySeconds: number | undefined = undefined;
          let isOverdue = false;
          if (job.totalTimeSpentInSeconds) {
            const {
              timeSpentSeconds,
              daysSpent,
              hoursNotInWholeDay = 0,
            } = getSecondsInBusinessDay(job.totalTimeSpentInSeconds);
            timeSpentInBusinessDaySeconds = timeSpentSeconds;
            if (!job.isComplete && job.estimateInDays) {
              const timeSpentDays = daysSpent ? daysSpent + hoursNotInWholeDay / 8 : timeSpentSeconds / 60 / 60 / 24;
              isOverdue = timeSpentDays > job.estimateInDays;
              if (isOverdue) {
                lateJobs += 1;
              }
            }
          }

          const project = projectData.projects.find((project) => project.UUID === job.job_projectUUID);
          if (project) {
            return { ...job, project, versions, isOverdue, timeSpentInBusinessDaySeconds };
          }
          return null;
        })
        .filter((job): job is EditJob => !!job)
        .sort((a, b) => a.job_projectUUID.localeCompare(b.job_projectUUID));
      const inProgressJobs = editJobs?.filter((job) => !job.isComplete);
      setJobData({ ...jobData, editJobs, inProgressJobs, lateJobs });
    }
  }, [jobData, projectData, projectSearch, versionData]);

  useEffect(() => {
    if (jobs.isLoading || versionSearch.isLoading || projectSearch.isLoading) {
      return setLoading(true);
    }
    if (!jobData || (jobData.total > 0 && jobData.editJobs?.length !== jobData?.total)) {
      return setLoading(true);
    }

    setLoading(false);
  }, [editJobs, isLoading, jobData, jobs.isLoading, projectSearch.isLoading, versionSearch.isLoading]);

  useEffect(() => {
    if (typeFilter && jobData?.editJobs) {
      if (typeFilter === 'inProgress') {
        const jobs = jobData.editJobs.filter((job) => job.status === 'inProgress');
        return setEditJobs(jobs);
      }
      if (typeFilter === 'completed') {
        const jobs = jobData.editJobs.filter((job) => job.status === 'Completed');
        return setEditJobs(jobs);
      }
      if (typeFilter === 'overdue') {
        const jobs = jobData.editJobs.filter((job) => job.isOverdue);
        return setEditJobs(jobs);
      }
    }

    setEditJobs(jobData?.editJobs);
  }, [typeFilter, jobData?.editJobs]);

  const isError = jobs.isError || versionSearch.isError || projectSearch.isError;

  const value = useMemo(
    () => ({
      data: {
        editJobs: editJobs,
        projects: projectData,
        versions: versionData,
        jobs: jobData,
      },
      isLoading,
      isError,
    }),
    [editJobs, isError, isLoading, jobData, projectData, versionData],
  );
  return value;
}
