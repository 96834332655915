import { Box, Paper, Typography } from '@mui/material';
import Table, { Column } from '@nekta-temp/component-library.table';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useUsers, useRoles, Users } from '../../../api/user';
import Loading from '../../../common/Loading';
import { colors, fontWeight} from '../../../theme/designtoken';

type UsersItem = Users[0];

function UsersAdmin() {
  const { data: users, isLoading: isLoadingUsers } = useUsers();
  const { data: roles, isLoading: isLoadingRoles } = useRoles({ roleType: 'global' });
  const history = useHistory();

  const columns: Column<UsersItem>[] = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Global Role',
        accessor: 'roleUUID',
        Cell: ({ value }) => roles?.find((role) => role.UUID === value)?.roleName || '',
      },
    ],
    [roles],
  );

  if (isLoadingUsers || isLoadingRoles) {
    return <Loading />;
  }

  return (
    <Box sx={{ flexGrow: '1', display: 'flex', gap: 2, flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">Users</Typography>
      </Box>
      <Paper>
        <Table<UsersItem>
          data={users || []}
          headerProps={{ sx: { backgroundColor: `${colors.neutral100}`, fontWeight: `${fontWeight.typographyfontweight3}`} }}
          columns={columns}
          rowProps={(row) => ({
            onClick: () => history.push(`/admin/users/${row.original.email}`),
            hover: true,
            sx: { cursor: 'pointer' },
          })}
          enableSorting
        />
      </Paper>
    </Box>
  );
}

export default UsersAdmin;
