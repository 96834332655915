import { FunctionComponent } from 'react';
import { Paper, TableContainer, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Table, Column } from '@nekta-temp/component-library.table';
import { Link } from 'react-router-dom';
import { startCase } from 'lodash';
import { useClients, Client } from '../../../api/connector';
import { useConfig } from '../../../api/config';
import Loading from '../../../common/Loading';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  groupSelect: {
    minWidth: 350,
  },
  loadMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
    width: '100%',
  },
}));

export const Clients: FunctionComponent = () => {
  const { isLoading, isError, data } = useClients();
  const { data: config, isLoading: configLoading } = useConfig();

  const classes = useStyles();
  const columns: Column<Client>[] = [
    {
      Header: 'Client',
      accessor: 'connectorType',
      Cell: ({ value }) => startCase(value),
    },
    {
      Header: 'User',
      accessor: 'user',
      Cell: ({ value }) => (value ? value : 'Not signed in'),
    },
    {
      Header: 'Project',
      accessor: 'projectUUID',
      Cell: ({ value, row }) => (value ? <Link to={`/project/${value}`}>{row.original.projectName}</Link> : '-'),
    },
    {
      Header: 'Edit Job',
      accessor: 'jobUUID',
      Cell: ({ value, row }) =>
        value ? (
          <Link to={`/project/${row.original.projectUUID}/job/${value}/overview`}>{row.original.jobName}</Link>
        ) : (
          '-'
        ),
    },
    {
      Header: 'Name',
      accessor: 'EC2InstanceId',
    },
    {
      Header: 'Region',
      accessor: 'EC2RegionName',
      Cell: ({ value }) => {
        if (config && value) {
          return config.awsRegionsMap[value] ? config.awsRegionsMap[value] : value;
        }
        return '';
      },
    },
    {
      Header: 'Type',
      accessor: 'EC2InstanceType',
    },
  ];

  return (
    <div className={classes.container}>
      <Typography variant="h6">Clients</Typography>
      {isLoading || configLoading ? (
        <Loading aria-label="Loading clients" />
      ) : (
        <>
          {data?.length ? (
            <TableContainer component={Paper}>
              <Table<Client> columns={columns} data={data} />
            </TableContainer>
          ) : (
            <Typography>No connected clients</Typography>
          )}
          {isError && <Typography>Sorry there was a problem loading the clients</Typography>}
        </>
      )}
    </div>
  );
};

export default Clients;
