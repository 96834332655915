import { AppBar, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar } from '@mui/material';
import BugReport from '@mui/icons-material/BugReport';
import AccountCircle from '@mui/icons-material/AccountCircleSharp';
import HelpIcon from '@mui/icons-material/HelpSharp';
import ExitIcon from '@mui/icons-material/ExitToAppOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { matchPath, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useConfig, Config } from '../api/config';
import { useUser } from '../api/user';
import { useApi } from '../contexts/Api';
import UploadBadge from '../routes/fileBrowser/upload/UploadBadge';
import { useHasPermission } from '../utils/permissions';
import BugReportDialog from './BugReport';
import { ReactComponent as Logo } from './Logo.svg';
import { topbarVariables } from '../theme/topbar';
import { buttonColor } from '../theme/designtoken';

const defaultHelpUrl = 'https://nekta.atlassian.net/wiki/spaces/NCMW/pages/518782977/Cloud+Edit+Help';

function openHelpUrl(path: string, helpUrls?: Config['help']) {
  const isProjectsPage = matchPath(path, { path: '/projects', exact: true });
  const isProjectPage = matchPath(path, { path: '/project/:projectId', exact: true });

  if ((isProjectsPage || isProjectPage) && helpUrls?.projects) {
    return window.open(helpUrls.projects);
  }

  const isJobVersionsPage = matchPath(path, { path: '/project/:projectId/job/:jobId/versions', exact: true });
  if (isJobVersionsPage && helpUrls?.versions) {
    return window.open(helpUrls.versions);
  }

  const isJobPage = matchPath(path, { path: '/project/:projectId/job/:jobId/:tab', exact: true });

  if (isJobPage && helpUrls?.jobs) {
    return window.open(helpUrls.jobs);
  }

  const isFileBrowserPage = path.includes('file-browser');
  if (isFileBrowserPage && helpUrls?.fileBrowser) {
    return window.open(helpUrls.fileBrowser);
  }

  return window.open(defaultHelpUrl);
}

export const TopBarHeight = '48px';

export function TopBar() {
  const history = useHistory();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement>();
  const [showBugReport, setShowBugReport] = useState(false);
  const { logOut } = useApi();
  const { data: config } = useConfig();
  const hasAdminSettingsPermission = useHasPermission('updateAdminSettings');
  const { data: user } = useUser();
  const [customLogo, setCustomLogo] = useState(false);

  useEffect(() => {
    if (config) {
      setCustomLogo(!!config.logoURL);
    }
  }, [config]);

  return (
    <AppBar color="transparent" elevation={4} position="sticky" sx={{ height: TopBarHeight, position: 'sticky' }}>
      <Toolbar variant="dense" aria-label="app-topbar" sx={{ justifyContent: 'space-between', bgcolor:`${topbarVariables.topBarBGColor}` }}>
        {customLogo && config?.logoURL ? (
          <img
            src={config.logoURL}
            alt="Logo"
            title="Logo"
            onError={() => setCustomLogo(false)}
            height="36px"
            width="150px"
            data-testid="custom-logo"
          />
        ) : (
          <Logo height="36px" width="150px" title="Logo"  />
        )}
        <div>
          <UploadBadge />
          <IconButton
            color="inherit"
            aria-label="app-menu"
            size="large"
            onClick={(event) => setMenuAnchor(event.currentTarget)}
          >
            <AccountCircle sx={{color: `${buttonColor.bgcolor}`}} />
          </IconButton>
        </div>
        <Menu open={!!menuAnchor} onClose={() => setMenuAnchor(undefined)} anchorEl={menuAnchor}>
          <MenuItem divider sx={{ cursor: 'default' }} disableRipple>
            {user?.email}
          </MenuItem>
          <MenuItem onClick={() => openHelpUrl(history.location.pathname, config?.help)}>
            <ListItemIcon>
              <HelpIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Help</ListItemText>
          </MenuItem>
          <MenuItem divider={!hasAdminSettingsPermission} onClick={() => setShowBugReport(true)}>
            <ListItemIcon>
              <BugReport fontSize="small" />
            </ListItemIcon>
            <ListItemText>Report Bug</ListItemText>
          </MenuItem>
          {hasAdminSettingsPermission && (
            <MenuItem
              divider
              onClick={() => {
                history.push('/admin/settings');
                setMenuAnchor(undefined);
              }}
            >
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </MenuItem>
          )}
          <MenuItem onClick={() => logOut(true)}>
            <ListItemIcon>
              <ExitIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Sign Out</ListItemText>
          </MenuItem>
        </Menu>
      </Toolbar>
      {showBugReport && <BugReportDialog onClose={() => setShowBugReport(false)} />}
    </AppBar>
  );
}

export default TopBar;
