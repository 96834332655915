import { InsertPhoto } from '@mui/icons-material';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { Config, useConfig, useUpdateConfig } from '../../../api/config';
import { useSnackbar } from '../../../contexts/Snackbar';

function Settings() {
  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();
  const { data: config } = useConfig();
  const { mutate, isLoading } = useUpdateConfig({
    onSuccess: () => openSnackbar('Config successfully updated', { alert: 'success' }),
    onError: () => openSnackbar('Config failed to update', { alert: 'error' }),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Partial<Config>>({ defaultValues: { logoURL: config?.logoURL || '' } });
  const logoURL = watch('logoURL');

  function preview(url: string) {
    const configData = queryClient.getQueryData<Config>('config');
    queryClient.setQueryData('config', { ...configData, logoURL: url });
  }

  function validURL(str?: string) {
    if (!str) {
      return 'URL is required';
    }
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i',
    );
    if (!!pattern.test(str)) {
      return true;
    }
    return 'Please enter a valid URL';
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h5">Settings</Typography>
      <form onSubmit={handleSubmit((values) => mutate(values))}>
        <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 3, padding: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Add a custom logo</Typography>
            <InsertPhoto sx={{ alignSelf: 'center' }} fontSize="large" />
          </Box>
          <Typography>
            To display a custom logo please enter the URL of the logo below. <br /> We recommend that it has a
            transparent background and is one of the following formats SVG, PNG or JPG <br />
            Once you have entered the URL you can preview how the image will look in our header
          </Typography>
          <Controller
            name="logoURL"
            control={control}
            rules={{ validate: (val) => validURL(val) }}
            render={({ field }) => (
              <TextField
                label="Logo URL"
                {...field}
                error={!!errors?.logoURL}
                helperText={errors?.logoURL?.message}
                fullWidth
              />
            )}
          />
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              disabled={!logoURL || !!errors?.logoURL}
              onClick={() => logoURL && preview(logoURL)}
            >
              Preview Logo
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        </Paper>
      </form>
    </Box>
  );
}

export default Settings;
