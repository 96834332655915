import React, { FunctionComponent, useState } from 'react';
import {
  styled,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { Box } from '@mui/system';
import makeStyles from '@mui/styles/makeStyles';
import {
  Videocam as VideoIcon,
  Audiotrack as AudioIcon,
  InsertDriveFile as FileIcon,
  Image as ImageIcon,
  GetApp as DownloadIcon,
} from '@mui/icons-material';
import { File as FileType } from '../../api/files';
import { radious } from '../../theme/designtoken';

const useStyles = makeStyles(() => ({
  card: {
    height: '135px',
  },
  cardContent: (props: { download: boolean }) => ({
    paddingTop: 0,
    paddingLeft: 8,
    paddingRight: props.download ? 0 : 8,
    paddingBottom: 0,
    display: 'flex',
    maxHeight: 28,
    justifyContent: props.download ? 'inherit' : 'center',
  }),
  downloadButton: {
    marginLeft: 'auto',
  },
  cardContentRoot: {
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardMedia: (props: { download: boolean }) => ({
    height: '80%',
    position: 'relative',
    cursor: props.download ? 'pointer' : 'auto',
  }),
}));

const ThumbContainer = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
});

const IconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});

const ScrubberCursorContainer: FunctionComponent<{ lineOffsetX: number }> = ({ children, lineOffsetX }) => (
  <Box
    sx={{
      width: '1px',
      height: '100%',
      background: 'transparent',
      position: 'absolute',
      left: `${lineOffsetX}px`,
      pointerEvents: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: 100,
    }}
  >
    {children}
  </Box>
);

const ScrubberCursorTop = styled('div')({
  width: 0,
  height: 0,
  borderLeft: '6px solid transparent',
  borderRight: '6px solid transparent',
  borderTop: '6px solid #f39b2b',
});

const ScrubberCursorBottom = styled('div')({
  width: 0,
  height: 0,
  borderLeft: '6px solid transparent',
  borderRight: '6px solid transparent',
  borderBottom: '6px solid #f39b2b',
});

type ScrubberImageProps = {
  showScrubber: boolean;
  scrubOffsetTop?: number;
};
const ScrubberImage = styled(
  ({
    showScrubber,
    scrubOffsetTop,
    ...other
  }: ScrubberImageProps & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => (
    <img alt="scrubber" {...other} />
  ),
)(
  {
    position: 'absolute',
    maxWidth: '100%',
  },
  (props: ScrubberImageProps) => ({
    display: props.showScrubber ? 'block' : 'none',
    transform:
      props.showScrubber && !props.scrubOffsetTop ? 'translateY(0px);' : `translateY(-${props.scrubOffsetTop}px)`,
  }),
);

const thumbWidth = process.env.REACT_APP_FILE_SELECT_TILE_VIEW_THUMB_WIDTH || '192';

export const FileThumb: FunctionComponent<{ file: FileType }> = ({ file }) => {
  const [thumbError, setThumbError] = useState(false);
  const [showScrubber, setShowScrubber] = useState(false);
  const [scrubberError, setScrubberError] = useState(false);
  const [scrubberLoaded, setScrubberLoaded] = useState(false);
  const [srubImageDimensions, setScrubImageDimesions] = useState({
    width: 0,
    height: 0,
  });
  const [lineOffsetX, setLineOffsetX] = useState(0);
  const [scrubOffsetTop, setScrubOffsetTop] = useState(0);

  const onScrubberMouseMove = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    if (scrubberLoaded) {
      let x = e.nativeEvent.offsetX;
      if (x === 0) {
        x = -3;
      }
      setLineOffsetX(x);

      const frameHeight = srubImageDimensions.height / 50;
      let frame = Math.floor((x / parseInt(thumbWidth)) * 50);

      if (frame === 50) {
        frame = 49;
      }

      const offset = frameHeight * frame;
      setScrubOffsetTop(offset);
    }
  };

  if (file.thumbURL && !thumbError) {
    return (
      <ThumbContainer
      sx={{ borderRadius:  parseInt(`${radious.borderradius1}`), border: '2px solid #B15EFF'}}
        onMouseLeave={() => {
          if (showScrubber && !scrubberError) {
            setShowScrubber(false);
            setLineOffsetX(0);
            setScrubOffsetTop(0);
          }
        }}
      >
        {showScrubber && (
          <ScrubberCursorContainer lineOffsetX={lineOffsetX} aria-label="scrubber">
            <ScrubberCursorTop />
            <ScrubberCursorBottom />
          </ScrubberCursorContainer>
        )}
        <img
          alt={file.fileName}
          src={file.thumbURL}
          style={{ display: showScrubber ? 'none' : 'block' }}
          width="100%"
          height="100%"
          onError={() => setThumbError(true)}
          onMouseEnter={(e) => {
            if (scrubberLoaded) {
              setShowScrubber(true);
              setLineOffsetX(0);
              setScrubOffsetTop(0);
            }
          }}
        />
        {!scrubberError && (
          <ScrubberImage
            src={file.scrubURL}
            alt={`${file.fileName}-scrubber`}
            width="100%"
            height="auto"
            onError={() => {
              setScrubberError(true);
              setScrubberLoaded(false);
            }}
            scrubOffsetTop={scrubOffsetTop}
            showScrubber={showScrubber}
            onLoad={(e) => {
              const target = e.target as HTMLImageElement;
              setScrubImageDimesions({
                width: target.naturalWidth,
                height: target.naturalHeight,
              });
              setScrubberLoaded(true);
            }}
            onMouseMove={onScrubberMouseMove}
          />
        )}
      </ThumbContainer>
    );
  }

  if (file.mediaType === 'audio') {
    return (
      <IconContainer>
        <AudioIcon fontSize="large" aria-label="audio icon" />
      </IconContainer>
    );
  }
  if (file.mediaType === 'video') {
    return (
      <IconContainer>
        <VideoIcon fontSize="large" aria-label="video icon" />
      </IconContainer>
    );
  }
  if (file.mediaType === 'image') {
    return (
      <IconContainer>
        <ImageIcon fontSize="large" aria-label="image icon" />
      </IconContainer>
    );
  }
  return (
    <IconContainer>
      <FileIcon fontSize="large" aria-label="file icon" />
    </IconContainer>
  );
};

export const File: FunctionComponent<{
  file: FileType;
  openPreview: (file: FileType) => void;
  download?: (file: FileType) => void;
}> = ({ file, openPreview, download }) => {
  const classes = useStyles({ download: !!download });
  const [isDownloading, setIsDownloading] = useState(false);

  const CardDetails = () => (
    <CardContent className={classes.cardContent} classes={{ root: classes.cardContentRoot }}>
      <Tooltip title={file.fileName.length > 14 ? file.fileName : ''}>
        <Typography noWrap align="center" variant="subtitle1">
          {file.fileName}
        </Typography>
      </Tooltip>
      {download && (
        <IconButton
          onClick={async () => {
            setIsDownloading(true);
            await download(file);
            setIsDownloading(false);
          }}
          size="small"
          className={classes.downloadButton}
          disabled={isDownloading}
          title={'Download file'}
        >
          {isDownloading ? (
            <CircularProgress size={20} aria-label="Downloading..." />
          ) : (
            <DownloadIcon fontSize="small" />
          )}
        </IconButton>
      )}
    </CardContent>
  );

  return (
    <Card className={classes.card} aria-label={file.Key}>
      {download ? (
        <>
          <CardMedia className={classes.cardMedia} onClick={() => file.playable && openPreview(file)}>
            <FileThumb file={file} />
          </CardMedia>
          <CardDetails />
        </>
      ) : (
        <CardActionArea onClick={() => openPreview(file)} className={classes.card} disabled={!file.playable}>
          <CardMedia className={classes.cardMedia}>
            <FileThumb file={file} />
          </CardMedia>
          <CardDetails />
        </CardActionArea>
      )}
    </Card>
  );
};

export default File;
