import { Box, Button, Typography, CircularProgress, TextField, IconButton, Paper } from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import copy from 'copy-to-clipboard';
import { useHistory } from 'react-router-dom';
import { useLoginCode } from '../../api/code';
import { useSnackbar } from '../../contexts/Snackbar';
import CodeExpiryCountdown from './CodeExpiryCountdown';

export function PremierProLogin() {
  const { openSnackbar } = useSnackbar();
  const history = useHistory();
  const { data: code, isLoading, isError, refetch } = useLoginCode();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', padding: 4 }}>
      <Typography variant="h2">
        Cloud{' '}
        <Typography variant="h2" component="b" color="secondary.main">
          Edit
        </Typography>
      </Typography>
      {isLoading ? (
        <CircularProgress color="primary" />
      ) : (
        <Paper sx={{ padding: 4, gap: 2, display: 'flex', flexDirection: 'column' }}>
          {code && (
            <>
              <Typography variant="h6" textAlign="center">
                Login code
              </Typography>
              <TextField
                aria-label="login code"
                value={code}
                InputProps={{
                  readOnly: true,
                  sx: { input: { textAlign: 'center' } },
                  endAdornment: (
                    <IconButton
                      aria-label="copy login code"
                      onClick={() => {
                        copy(code);
                        openSnackbar('Login code copied to clipboard!');
                      }}
                    >
                      <CopyIcon />
                    </IconButton>
                  ),
                }}
              />
              <CodeExpiryCountdown requestNewCode={refetch} />
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button variant="outlined" color="primary" onClick={() => history.push('/')}>
                  Go to Projects
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    copy(code);
                    openSnackbar('Login code copied to clipboard!');
                  }}
                >
                  Copy Code
                </Button>
              </Box>
            </>
          )}
          {isError && (
            <>
              <Typography variant="h6" textAlign="center">
                Sorry an error has occured trying to fetch the login code.
              </Typography>
              <Button variant="outlined" color="primary" onClick={() => refetch()}>
                Retry
              </Button>
            </>
          )}
        </Paper>
      )}
    </Box>
  );
}

export default PremierProLogin;
