export function getLocalDateTime(
  timestamp: number | string,
  options?: Intl.DateTimeFormatOptions,
  locale = 'default',
): string {
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat(locale, options).format(date);
}

export default getLocalDateTime;
