import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from '@mui/material';
import { isEqual, startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Loading from '../../../common/Loading';
import {
  EditRoleParams,
  Permission,
  Role,
  RoleDetails,
  useDeleteRole,
  useEditRole,
  usePermissions,
  useRole,
} from '../../../api/user';
import { useSnackbar } from '../../../contexts/Snackbar';
import PermissionWrapper from '../../../common/PermissionWrapper';
import { buttonColor, colors, purpleColor, radious } from '../../../theme/designtoken';

type DialogProps = {
  role: Role;
  open: boolean;
  onSuccess?: () => void;
  onClose?: () => void;
};

type EditRoleProps = {
  role: RoleDetails;
  onSuccess?: () => void;
  onClose?: () => void;
};

type EditRole = {
  roleName: string;
  roleDescription: string;
  permissions: Record<string, Permission>;
};

function EditRoleForm({ role, onSuccess, onClose }: EditRoleProps) {
  const { control, handleSubmit, watch, setValue } = useForm<EditRole>({
    defaultValues: {
      roleName: role.roleName,
      roleDescription: role.roleDescription,
      permissions: {},
    },
  });
  const { data, isLoading } = usePermissions({ scope: role.roleType });
  const { openSnackbar } = useSnackbar();
  const { mutate, isLoading: isMutating } = useEditRole({
    onSuccess: () => {
      openSnackbar('Role edited successfully', { alert: 'success' });
      onSuccess && onSuccess();
    },
    onError: (data) => openSnackbar(data?.response?.data?.message || 'Failed to edit role', { alert: 'error' }),
  });
  const { mutate: deleteRole, isLoading: isDeleting } = useDeleteRole({
    onSuccess: () => {
      setIsDeletingRole(false);
      openSnackbar('Role deleted successfully', { alert: 'success' });
      onSuccess && onSuccess();
    },
    onError: (data) => openSnackbar(data?.response?.data?.message || 'Failed to delete role', { alert: 'error' }),
  });
  const [isDeletingRole, setIsDeletingRole] = useState(false);
  const selectedPermissions = watch('permissions');

  useEffect(() => {
    if (data) {
      const previousPermissions = role.permissions.reduce<EditRole['permissions']>((current, next) => {
        const newValue = { ...current };
        const permission = data.permissions.find((perm) => perm.value === next);
        if (permission) {
          newValue[next] = permission;
        }
        return newValue;
      }, {});
      return setValue('permissions', previousPermissions);
    }
    setValue('permissions', {});
  }, [data, role, setValue]);

  const submitHandler = handleSubmit((values) => {
    const modifiedFields = Object.keys(values).reduce<Record<string, string | string[]>>((current, next) => {
      const value = values[next as keyof EditRole];
      let updatedValue: EditRoleParams['body'][keyof EditRoleParams['body']];

      if (typeof value === 'object') {
        // its permissions
        updatedValue = Object.keys(value);
      } else {
        updatedValue = value;
      }

      if (!isEqual(updatedValue, role[next as keyof RoleDetails])) {
        const nextValue = { ...current };
        nextValue[next] = updatedValue;
        return nextValue;
      }
      return current;
    }, {});
    mutate({ body: modifiedFields, roleUUID: role.UUID });
  });

  function toggleSelectedPermission(permission: Permission) {
    const updatedPermission = { ...selectedPermissions };
    if (selectedPermissions[permission['value']]) {
      delete updatedPermission[permission['value']];
      return setValue('permissions', updatedPermission, { shouldValidate: true });
    }
    updatedPermission[permission['value']] = permission;
    return setValue('permissions', updatedPermission, { shouldValidate: true });
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <form onSubmit={submitHandler}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Controller
          name="roleName"
          control={control}
          rules={{
            required: 'Name is required',
            minLength: { value: 2, message: 'Must be at least 2 characters' },
            maxLength: { value: 40, message: 'Must be at less than 40 characters' },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label="Name"
            />
          )}
        />
        <Controller
          name="roleDescription"
          control={control}
          rules={{
            required: 'Description is required',
            minLength: { value: 2, message: 'Must be at least 2 characters' },
            maxLength: { value: 256, message: 'Must be at less than 256 characters' },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label="Description"
              multiline
              rows={4}
            />
          )}
        />
        <FormLabel>Select permissions for this role</FormLabel>

        {isLoading ? (
          <Loading />
        ) : (
          <Controller
            name="permissions"
            control={control}
            rules={{
              validate: (vl) => (vl && Object.keys(vl).length > 0 ? true : 'At least one permission is required'),
            }}
            render={({ field, fieldState }) => (
              <>
                {fieldState.error?.message && (
                  <Typography variant="body2" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
                <List sx={{ overflow: 'auto', maxHeight: fieldState.error?.message ? '42vh' : '49vh' }}>
                  {data?.permissionCategories.map((category) => (
                    <li key={category.name}>
                      <ul>
                        <ListSubheader>{startCase(category.name)}</ListSubheader>
                        {category.permissions.map((permission) => (
                          <ListItem key={permission.value}>
                            <ListItemButton onClick={() => toggleSelectedPermission(permission)} dense>
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={!!selectedPermissions[permission.value]}
                                  tabIndex={-1}
                                  disableRipple
                                  color="default"
                                />
                              </ListItemIcon>
                              <ListItemText>{startCase(permission.permission)}</ListItemText>
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </ul>
                    </li>
                  ))}
                </List>
              </>
            )}
          />
        )}
        <Box sx={{ display: 'flex' }}>
          <PermissionWrapper requiredPermissions="deleteRole">
            <Button sx={{ bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }} onClick={() => setIsDeletingRole(true)} variant="contained" color="error">
              Delete Role
            </Button>
          </PermissionWrapper>
          <Box sx={{ marginLeft: 'auto', display: 'flex', gap: 2 }}>
            {onClose && (
              <Button sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }} onClick={onClose} disabled={isMutating}>
                Cancel
              </Button>
            )}
            <Button type="submit" variant="contained" color="primary" disabled={isMutating} sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog open={isDeletingRole} maxWidth="lg" scroll="paper" aria-label="Delete role dialog">
        <DialogTitle sx={{ pb: 0 }}>Delete {role.roleName} role</DialogTitle>
        <Box sx={{ minWidth: '500px', padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography>Are you sure you want to delete this role?</Typography>
            <Typography>
              {role.roleType === 'project'
                ? 'All users with this role will loose access to associated projects'
                : ' All users with this role will loose their permissions'}
            </Typography>
          </Box>
          <Box sx={{ marginLeft: 'auto', display: 'flex', gap: 2 }}>
            <Button sx={{ bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }} onClick={() => setIsDeletingRole(false)} disabled={isDeleting}>
              Cancel
            </Button>
            <Button
              sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }}
              onClick={() => deleteRole({ UUID: role.UUID })}
              variant="contained"
              color="error"
              disabled={isDeleting}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Dialog>
    </form>
  );
}

function EditRoleDialog({ role, open, onSuccess, onClose }: DialogProps) {
  const { isLoading, data, isError } = useRole(role.UUID);

  return (
    <Dialog open={open} maxWidth="lg" scroll="paper" aria-label="Edit role dialog">
      <DialogTitle>Edit {role.roleName} role</DialogTitle>
      <Box sx={{ minWidth: '500px', padding: 2 }}>
        {isLoading ? <Loading /> : data && <EditRoleForm role={data} onSuccess={onSuccess} onClose={onClose} />}
        {isError && <Typography>Sorry an has occured loading this role</Typography>}
      </Box>
    </Dialog>
  );
}

export default EditRoleDialog;
