import { AxiosError } from 'axios';
import {
  UseMutationResult,
  useQueryClient,
  useMutation,
  UseMutationOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
  useQuery,
  UseQueryResult,
} from 'react-query';
import { useApi, Post, Get, Delete, Put } from '../contexts/Api';

export type JobBody = {
  projectUUID: string;
  jobName: string;
  description: string;
  regionName: string;
  estimateInDays: number;
};

type CreateJobResponse = { job: { UUID: string } };

async function createJob(post: Post, job: JobBody) {
  const { data } = await post<CreateJobResponse>('/job', job);
  return data;
}

export function useCreateJob(
  options?: UseMutationOptions<CreateJobResponse, unknown, JobBody, unknown>,
): UseMutationResult<CreateJobResponse, unknown, JobBody, unknown> {
  const { post } = useApi();
  const queryClient = useQueryClient();
  return useMutation((job) => createJob(post, job), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['jobs', variables.projectUUID]);
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

export type Jobs = {
  UUID: string;
  isComplete: boolean;
  jobName: string;
  regionName: string;
  dateAddedInMicroSeconds: number;
  createdBy: { userName: string; ip: string };
  estimateInDays: number;
};

export type JobsResponse = {
  jobs: Jobs[];
  lastKey: null | { UUID: string; dateAddedInMicroSeconds: number; job_projectUUID: string };
};

async function fetchJobs(get: Get, projectUUID?: string, lastKey?: JobsResponse['lastKey']) {
  const params = { projectUUID, lastKey };
  const { data } = await get<JobsResponse>('/jobs', { params });
  return data;
}

export function useJobs(projectUUID?: string): UseInfiniteQueryResult<JobsResponse> {
  const { get } = useApi();
  return useInfiniteQuery(['jobs', projectUUID], ({ pageParam }) => fetchJobs(get, projectUUID, pageParam), {
    cacheTime: 1000 * 60 * 10, //10mins
    staleTime: 1000 * 60 * 5, //5mins
    getNextPageParam: (lastPage) => lastPage.lastKey,
    enabled: !!projectUUID,
  });
}

type CompleteJobResponse = { message: string };

type CompleteJobData = {
  jobUUID: string;
  projectUUID: string;
  isComplete: boolean;
};

async function completeJob(put: Put, data: CompleteJobData) {
  const { data: response } = await put<CompleteJobResponse>(`/job/${data.jobUUID}`, {
    isComplete: data.isComplete,
  });
  return response;
}

export function useCompleteJob(
  options?: UseMutationOptions<CompleteJobResponse, unknown, CompleteJobData, unknown>,
): UseMutationResult<CompleteJobResponse, unknown, CompleteJobData, unknown> {
  const { put } = useApi();
  const queryClient = useQueryClient();
  return useMutation((job) => completeJob(put, job), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['jobs', variables.projectUUID]);
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

type DeleteJobData = {
  jobUUID: string;
  projectUUID: string;
};

async function deleteJob(deleteApi: Delete, data: DeleteJobData) {
  const { data: response } = await deleteApi<CreateJobResponse>(`/job/${data.jobUUID}`);
  return response;
}

export function useDeleteJob(
  options?: UseMutationOptions<CreateJobResponse, unknown, DeleteJobData, unknown>,
): UseMutationResult<CreateJobResponse, unknown, DeleteJobData, unknown> {
  const queryClient = useQueryClient();
  const { delete: deleteApi } = useApi();

  return useMutation((job) => deleteJob(deleteApi, job), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['jobs', variables.projectUUID]);
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

export type Job = {
  UUID: string;
  createdBy: { userName: string; ip: string };
  dateAddedInMicroSeconds: number;
  description: string;
  estimateInDays: number;
  isComplete: boolean;
  isDeleted: boolean;
  jobName: string;
  jobUUID: string;
  job_projectUUID: string;
  regionName: string;
  status: 'inProgress' | 'Completed';
  type: 'job';
  totalTimeSpentInSeconds?: number;
};

async function fetchJob(get: Get, id?: string) {
  const { data } = await get<{ job: Job }>(`/job/${id}`);
  return data.job;
}

export function useJob(id?: string): UseQueryResult<Job> {
  const { get } = useApi();
  return useQuery(['job', id], () => fetchJob(get, id), {
    cacheTime: 1000 * 60 * 10, //10mins
    staleTime: 1000 * 60 * 5, //5mins,
    enabled: !!id,
  });
}

type EditJobBody = {
  description?: string;
  jobName?: string;
};

type EditJobParams = {
  id: string;
  body: EditJobBody;
};

type EditJobResponse = { message: string };

async function editJob(put: Put, { id, body }: EditJobParams) {
  const { data } = await put<EditJobResponse>(`/job/${id}`, body);
  return data;
}

export function useEditJob(
  options?: UseMutationOptions<EditJobResponse, AxiosError<{ message?: string }>, EditJobParams, unknown>,
): UseMutationResult<EditJobResponse, AxiosError<{ message?: string }>, EditJobParams, unknown> {
  const queryClient = useQueryClient();
  const { put } = useApi();
  return useMutation((job) => editJob(put, job), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['job', variables.id]);
      queryClient.invalidateQueries('jobs');
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

type Status = {
  UUID: string;
  dateAddedInMicroSeconds: number;
  slug: string;
  message: string;
  statusDetails: string;
  userName: string;
  eventType: string;
  versionName?: string;
  versionUUID?: string;
  progress?: number;
  approvalPlatform?: string;
  conformPlatform?: string;
  conformType?: 'approval' | 'submission';
  statusType: 'success';
  statusUUID: string;
  lastUpdated?: string;
  lastUpdatedInMicroSeconds?: number;
  lastUpdatedInSeconds?: number;
  dateAddedInSeconds: number;
  dateAdded: string;
  status_jobUUID: string;
  type: 'status';
};

export type JobEventResponse = {
  lastKey: null | {
    dateAddedInMicroSeconds: 1615197320879343;
    status_jobUUID: 'test-job-id';
    UUID: '1ce8ddf32e7e40728ec01856a7d47896';
  };
  status: Status[];
};

async function fetchJobEvents(get: Get, jobId?: string, lastKey?: JobEventResponse['lastKey']) {
  const queryParams = { jobUUID: jobId, lastKey };
  const { data } = await get<JobEventResponse>('/status', { params: queryParams });
  return data;
}

export function useJobEvents(jobId?: string): UseInfiniteQueryResult<JobEventResponse> {
  const { get } = useApi();
  return useInfiniteQuery(['jobEvents', jobId], ({ pageParam }) => fetchJobEvents(get, jobId, pageParam), {
    cacheTime: 1000 * 60 * 10, // 10mins
    staleTime: 1000 * 60 * 5, // 5mins,
    getNextPageParam: (lastPage) => lastPage.lastKey,
    enabled: !!jobId,
  });
}
