import {
  useQuery,
  UseQueryResult,
  useMutation,
  UseMutationOptions,
  useQueryClient,
  UseQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from 'react-query';
import { useApi, Get, Put, Delete } from '../contexts/Api';

export type Version = {
  UUID: string;
  approvalPlatform: 'moxion' | 'frameio' | 's3';
  commentsExist: boolean;
  isDeleted: boolean;
  fps?: number;
  versionStatus: string;
  outputBucketName: null | string;
  version_projectUUID: string;
  createdBy: {
    userName: string;
    ip: string;
  };
  dateAddedInMicroSeconds: number;
  version_jobUUID_approval: string;
  mediaExists: boolean;
  regionName: string;
  conformType: 'approval' | 'submission';
  versionName: string;
  versionState: 'failed' | 'completed';
  userName: string;
  source: {
    xmlObjectKey: string;
    sequenceUUID: string;
    bucketName: string;
    projectObjectKey: string;
  };
  lastUpdatedInMicroSeconds: number;
  version_jobUUID: string;
  platform?: {
    name: 'moxion' | 'frameio' | 's3';
    uniqueIdentifier: {
      moxionProjectID?: string;
      moxionBatchID?: string;
      projectID?: string;
      assetID: string;
      link: string;
    };
  };
  backendDestination: {
    bucketName: string;
    s3Key: string;
  };
  conformPlatform: 'adobeMediaEncoder';
  type: 'version';
};

export type VersionResponse = {
  lastKey: null | string;
  versions: Version[];
};

type UseVersionParams = {
  type: 'approval' | 'submission';
  jobId: string;
};

async function getVersions(get: Get, { type, jobId }: UseVersionParams, lastKey?: VersionResponse['lastKey']) {
  const queryParams = { versionType: type, jobUUID: jobId, lastKey };

  const { data } = await get<VersionResponse>('/versions', {
    params: queryParams,
  });
  return data;
}

export function useVersions({ type, jobId }: UseVersionParams): UseInfiniteQueryResult<VersionResponse> {
  const { get } = useApi();
  return useInfiniteQuery<VersionResponse>(
    ['versions', type, jobId],
    ({ pageParam }) => getVersions(get, { jobId, type }, pageParam),
    {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 10,
      getNextPageParam: (lastPage) => lastPage.lastKey,
    },
  );
}

export type FrameIoComment = {
  annotation: string | null;
  anonymous_user_id: null | string;
  anonymous_user?: { inserted_at: string; name: string };
  aspect_ratio: number;
  asset_id: string;
  canMarkComplete: boolean;
  comment_entities: string[];
  completed: boolean;
  completed_at: string;
  completer_id: string;
  deleted_at: null | string;
  duration: null | string;
  fov: number;
  frame: number;
  has_replies: boolean;
  id: string;
  inserted_at: string;
  like_count: number;
  owner?: Record<string, unknown>;
  owner_id: string;
  page: null | string;
  parent_id: null | string;
  pitch: null | string;
  private: boolean;
  reaction_counts: null | string;
  read_count: number;
  review_link_id: null | string;
  start_time: null | string;
  target_asset_id: null | string;
  text: string;
  text_edited_at: null;
  _type?: string;
  thumb: string;
  timestamp: number;
  updated_at: string;
  user_impressions: string[];
  yaw: null | string;
};

export type MoxionComment = {
  asset_id: string;
  canMarkComplete: boolean;
  color: string;
  colorspace: string;
  completed: boolean;
  completed_by_user_id: string;
  created: string;
  creator_email: string;
  creator_name: string;
  creator_role: string;
  description: string;
  has_drawing: boolean;
  has_marker: boolean;
  has_obsolete_drawing: boolean;
  has_reply: boolean;
  height: number;
  id: string;
  inserted_at: string;
  is_expired: boolean;
  is_owner: boolean;
  is_reply: boolean;
  marker_duration: number;
  marker_time: number;
  marker_timecode: string;
  owner: { name: string };
  parent_id: string;
  project_id: string;
  resolution_label: string;
  scope: string;
  text: string;
  type: string;
  user_first_name: string;
  user_id: string;
  user_last_name: string;
  uuid: string;
  width: number;
};

export type Comment = FrameIoComment | MoxionComment;

export type CommentsResponse = { comments: Comment[] };

async function fetchComments(get: Get, versionID: string) {
  const { data } = await get<CommentsResponse>(`/version/${versionID}/comments`);
  return data.comments;
}

export function useComments({ versionID }: { versionID: string }): UseQueryResult<Comment[]> {
  const { get } = useApi();
  return useQuery(['comments', versionID], () => fetchComments(get, versionID), {
    cacheTime: 1000 * 60 * 10, // 10mins
    staleTime: 1000 * 60 * 5, // 5mins
  });
}

type CommentAnnotation = { url: string };

async function fetchCommentAnnotation(get: Get, versionID: string, commentID: string) {
  const { data } = await get<CommentAnnotation>(`/version/${versionID}/comment/${commentID}/annotation`);
  return data;
}

export function useCommentAnnotation(
  {
    versionID,
    commentID,
    enabled,
  }: {
    versionID: string;
    commentID: string;
    enabled: boolean;
  },
  options?: UseQueryOptions<CommentAnnotation, unknown, CommentAnnotation, string[]>,
): UseQueryResult<CommentAnnotation> {
  const { get } = useApi();
  return useQuery(
    ['commentAnnotation', versionID, commentID],
    () => fetchCommentAnnotation(get, versionID, commentID),
    {
      cacheTime: 1000 * 60 * 10, // 10mins
      staleTime: 1000 * 60 * 5, // 5mins,
      enabled,
      ...options,
    },
  );
}

type CompleteCommentProps = {
  versionID: string;
  commentID: string;
  platform: string;
  complete: boolean;
};

async function markCommentAsComplete(
  { put, deleteApi }: { put: Put; deleteApi: Delete },
  { versionID, commentID, platform, complete }: CompleteCommentProps,
) {
  if (complete) {
    const { data } = await put<{ message: string }>(`/version/${versionID}/comment/${commentID}/complete`, {
      platform,
    });
    return data;
  }
  const { data } = await deleteApi<{ message: string }>(`/version/${versionID}/comment/${commentID}/complete`);
  return data;
}

export function useCompleteComment(
  options?: UseMutationOptions<{ message: string }, unknown, CompleteCommentProps, unknown>,
) {
  const { put, delete: deleteApi } = useApi();
  const queryClient = useQueryClient();
  return useMutation((comment) => markCommentAsComplete({ put, deleteApi }, comment), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(['comments', variables.versionID]);
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

async function fetchVersionFPS(get: Get, versionID: string) {
  const { data } = await get<{ metadata: { fps: number } }>(`/version/${versionID}/fps`);
  return data.metadata.fps;
}

export function useVersionFPS({ versionID, enabled }: { versionID: string; enabled: boolean }): UseQueryResult<number> {
  const { get } = useApi();
  return useQuery(['fps', versionID], () => fetchVersionFPS(get, versionID), {
    cacheTime: 1000 * 60 * 30,
    staleTime: 1000 * 60 * 20,
    enabled,
  });
}
