import { useProjectPermissions } from '../api/projects';
import { useUser } from '../api/user';

export const permissions = {
  userWorkstations: ['deployment_user:listDeployments'],
  adminDeployments: ['deployment_admin:listDeployments'],
  adminDeployment: ['deployment_admin:getDeployment'],
  deploymentAutoscaling: ['deployment_admin:getDeploymentAutoscaling'],
  deploymentLogs: ['deployment_admin:getDeploymentLog', 'deployment_admin:listDeploymentLogs'],
  transcodeJobs: ['transcode:listJobs'],
  editJobInsights: [],
  billingInsights: ['integrations:getAWSBilling', 'deployment_admin:searchDeployments'],
  updateAdminSettings: ['config:updateConfigurationSettings'],
  createProject: ['projects:createProject'],
  updateProject: ['projects:updateProject'],
  createEditJob: ['jobs:createJob'],
  uploadFiles: ['files:s3Upload'],
  userAdmin: ['user:listUsers'],
  roleAdmin: ['user:listRoles'],
  createRole: ['user:createRole'],
  updateRole: ['user:updateRole'],
  deleteRole: ['user:deleteRole'],
  updateUserRoles: ['user:updateUserRole'],
};

export type Permission = string[] | keyof typeof permissions;

export function checkPermissions(userPermissions: string[], requiredPermissions: Permission) {
  let required: string[] = [];
  if (!Array.isArray(requiredPermissions)) {
    required = permissions[requiredPermissions];
  } else {
    required = requiredPermissions;
  }

  return required.every((perm) => userPermissions.includes(perm));
}

export function useHasPermission(permission: Permission, projectID?: string) {
  const { data: user } = useUser();
  const { data: projectPermissions } = useProjectPermissions(projectID);

  if (!user) {
    return false;
  }

  let permissions = user.permissions;

  if (projectPermissions && projectID) {
    permissions = projectPermissions;
  }

  const hasPermission = checkPermissions(permissions, permission);

  if (hasPermission) {
    return true;
  }

  return false;
}
