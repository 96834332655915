import React, { FunctionComponent, useState } from 'react';
import {
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Tooltip,
  CircularProgress,
  IconButton,
  CardMedia,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Folder as FolderIcon, GetApp as DownloadIcon } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  card: {
    height: '135px',
  },
  cardContent: (props: { download: boolean }) => ({
    paddingTop: 0,
    paddingLeft: 8,
    paddingRight: props.download ? 0 : 8,
    paddingBottom: 0,
    display: 'flex',
    maxHeight: 28,
    justifyContent: props.download ? 'inherit' : 'center',
  }),
  cardContentRoot: {
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  downloadButton: {
    marginLeft: 'auto',
  },
  cardMedia: (props: { download: boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '80%',
    cursor: props.download ? 'pointer' : 'auto',
  }),
}));

export const Folder: FunctionComponent<{
  name: string;
  onClick: () => void;
  path: string;
  download?: (path: string) => void;
}> = ({ name, onClick, path, download }) => {
  const classes = useStyles({ download: !!download });
  const [isDownloading, setIsDownloading] = useState(false);

  const CardDetails = () => (
    <>
      <CardMedia onClick={() => download && onClick()} className={classes.cardMedia} aria-label="Open folder">
        <FolderIcon fontSize="large" />
      </CardMedia>
      <CardContent className={classes.cardContent} classes={{ root: classes.cardContentRoot }}>
        <Tooltip title={name.length > 19 ? name : ''}>
          <Typography noWrap align="center" variant="subtitle1">
            {name}
          </Typography>
        </Tooltip>
        {download && (
          <IconButton
            onClick={async () => {
              setIsDownloading(true);
              await download(path);
              setIsDownloading(false);
            }}
            size="small"
            className={classes.downloadButton}
            disabled={isDownloading}
            title={'Download folder'}
          >
            {isDownloading ? (
              <CircularProgress size={20} aria-label="Downloading..." />
            ) : (
              <DownloadIcon fontSize="small" />
            )}
          </IconButton>
        )}
      </CardContent>
    </>
  );

  return (
    <Card className={classes.card} aria-label={name}>
      {download ? (
        <CardDetails />
      ) : (
        <CardActionArea onClick={onClick} className={classes.card}>
          <CardDetails />
        </CardActionArea>
      )}
    </Card>
  );
};

export default Folder;
