import { Typography, CircularProgress, Box, TableContainer, Paper, IconButton, Button } from '@mui/material';
import PlayIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import MediaPreview from '@nekta-temp/component-library.mediapreview';
import { Table, Column } from '@nekta-temp/component-library.table';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useJobFiles, JobFile, useSignedUrl } from '../../../api/files';
import getLocalDateTime from '../../../utils/getLocalDateTime';
import { useSnackbar } from '../../../contexts/Snackbar';

// TODO This value should come from the api
type FileType = 'video' | 'image' | 'audio' | 'unknown';
function getFileType(fileExtension: string): FileType {
  switch (fileExtension) {
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'png':
      return 'image';
    case 'mp3':
    case 'wav':
    case 'aac':
      return 'audio';
    case 'mp4':
    case 'webm':
    case 'avi':
      return 'video';
    default:
      return 'unknown';
  }
}

function Files() {
  const { jobId } = useParams<{ jobId: string }>();
  const { isLoading, data, isError, hasNextPage, isFetchingNextPage, fetchNextPage } = useJobFiles({ jobId });
  const { openSnackbar } = useSnackbar();
  const [previewFile, setPreviewFile] = useState<{ type: 'video' | 'audio' | 'image'; bucket: string; path: string }>();

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
        <CircularProgress color="primary" aria-label="Loading job files" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
        <Typography>Sorry an error has occured. Please go back and try again.</Typography>
      </Box>
    );
  }

  if (!data || data.pages[0]?.files.length < 1) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150 }}>
        No files downloaded
      </Box>
    );
  }

  const columns: Column<JobFile>[] = [
    { Header: 'File name', accessor: 'fileName' },
    { Header: 'Bucket', accessor: 'originalBucket' },
    { Header: 'Location', accessor: 'originalFile' },
    { Header: 'Downloaded by', accessor: (file) => file.createdBy.userName },
    {
      Header: 'Date',
      accessor: (file) =>
        getLocalDateTime(file.dateAddedInMicroSeconds / 1000, {
          timeStyle: 'medium',
          dateStyle: 'short',
        }),
    },
    {
      Header: 'Preview',
      id: 'preview',
      accessor: (file) => getFileType(file.originalExtension),
      Cell: ({ row, value }: { row: { original: JobFile }; value: FileType }) => {
        if (value === 'unknown') {
          return null;
        }
        return (
          <IconButton
            aria-label="preview"
            title="Preview file"
            onClick={() =>
              setPreviewFile({ bucket: row.original.originalBucket, path: row.original.originalFile, type: value })
            }
          >
            <PlayIcon color="primary" />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <TableContainer component={Paper}>
        <Table<JobFile> columns={columns} data={data.pages.flatMap((page) => page.files)} />
      </TableContainer>
      {hasNextPage && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 2 }}>
          {isFetchingNextPage ? (
            <CircularProgress />
          ) : (
            <Button variant="outlined" onClick={() => fetchNextPage()}>
              Load more
            </Button>
          )}
        </Box>
      )}
      <MediaPreview
        bucket={previewFile?.bucket || ''}
        filePath={previewFile?.path}
        type={previewFile?.type}
        onClose={() => setPreviewFile(undefined)}
        openSnackbar={openSnackbar}
        queries={{ useSignedUrl }}
      />
    </>
  );
}

export default Files;
