import { Avatar, Box, Paper, Skeleton, Typography, Link, TableContainer, CardActionArea, Chip } from '@mui/material';
import { yellow, pink, grey, blue } from '@mui/material/colors';
import EditJobsIcon from '@mui/icons-material/SlowMotionVideoOutlined';
import ProgressIcon from '@mui/icons-material/PublishedWithChanges';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import { AccessTime, FilterList } from '@mui/icons-material';
import { formatDuration, intervalToDuration } from 'date-fns';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { startCase } from 'lodash';
import { Table, Column } from '@nekta-temp/component-library.table';
import { getLocalDateTime } from '../../../utils/getLocalDateTime';
import { DateTimePickerDialog as DateTimePicker, TimeFilter } from '../DateTimePickerDialog';
import { useEditJobDashboard, EditJob, TypeFilter } from './useEditJobDashboard';
import { colors, fontWeight, typography, radious } from '../../../theme/designtoken';

const infoStyle = {
  minWidth: 250,
  minHeight: 200,
} as const;

//sx={{ borderRadius:  parseInt(`${radious.borderradius1}`), border: '2px solid #B15EFF'}}
const infoActionArea = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 2,
  padding: 2,
  borderRadius: 4,
  border: '2px solid #B15EFF',
} as const;

const SkeletonPage = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    <Skeleton variant="text" width={200} />
    <Box sx={{ display: 'flex', width: '100%', gap: 2, justifyContent: 'space-around' }}>
      <Skeleton variant="rectangular" sx={infoStyle} />
      <Skeleton variant="rectangular" sx={infoStyle} />
      <Skeleton variant="rectangular" sx={infoStyle} />
    </Box>
    <Skeleton variant="rectangular" sx={{ height: '100%' }} />
  </Box>
);

function EditJobsDashboard() {
  const [typeFilter, setTypeFilter] = useState<TypeFilter>();
  const [timeFilter, setTimeFilter] = useState<TimeFilter>();
  const [openTimeFilter, setOpenTimeFilter] = useState(false);
  const { isLoading, isError, data } = useEditJobDashboard({ typeFilter, timeFilter: timeFilter?.filter });

  if (isError) {
    return <Typography>Sorry an error has occured. Please try again</Typography>;
  }

  if (isLoading) {
    return <SkeletonPage />;
  }

  if (!data.editJobs) {
    return (
      <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', flexGrow: '1', fontSize: `${typography.typographyfontsize1}` }}>
        <Typography>No edit jobs found</Typography>
      </Box>
    );
  }

  const columns: Column<EditJob>[] = [
    {
      Header: 'Edit job',
      accessor: 'jobName',
      Cell: ({ value, row }) => (
        <Link
          to={`/project/${row.original.job_projectUUID}/job/${row.original.UUID}/overview`}
          component={RouterLink}
          underline="hover"
        >
          {value}
        </Link>
      ),
    },
    {
      Header: 'Project',
      accessor: 'project',
      Cell: ({ value }) => (
        <Link to={`/project/${value.UUID}`} component={RouterLink} underline="hover">
          {value.projectName}
        </Link>
      ),
      sortType: (rowA, rowB) => (rowA.original.project.projectName > rowB.original.project.projectName ? 1 : -1),
    },
    { Header: 'Status', accessor: 'status', Cell: ({ value }) => startCase(value) },
    {
      Header: 'No. of Versions',
      accessor: 'versions',
      Cell: ({ value }) => value.length,
      sortType: (rowA, rowB) => (rowA.original.versions.length > rowB.original.versions.length ? 1 : -1),
    },
    {
      Header: 'Last version submitted',
      id: 'versionDate',
      accessor: 'versions',
      Cell: ({ value }) =>
        value[0]
          ? getLocalDateTime(value[0].dateAddedInMicroSeconds / 1000, {
              timeStyle: 'medium',
              dateStyle: 'short',
            })
          : 'N/A',
      sortType: (rowA, rowB) => {
        if (rowA.original.versions[0] && rowB.original.versions[0]) {
          return rowA.original.versions[0].dateAddedInMicroSeconds > rowB.original.versions[0].dateAddedInMicroSeconds
            ? 1
            : -1;
        }
        if (!rowB.original.versions[0]) {
          return 1;
        }
        return -1;
      },
    },
    {
      Header: 'Estimate',
      accessor: 'estimateInDays',
      Cell: ({ value }) => `${value} day(s)`,
    },
    {
      Header: 'Time spent',
      accessor: 'timeSpentInBusinessDaySeconds',
      Cell: ({ value }) => {
        if (!value) {
          return 'N/A';
        }
        if (value < 60) {
          return `${value} seconds`;
        }

        return formatDuration(intervalToDuration({ end: new Date(value * 1000), start: new Date(0) }), {
          format: ['days', 'hours', 'minutes'],
        });
      },
    },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">Edit Jobs</Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          {timeFilter ? (
            <Chip label={timeFilter.label} icon={<AccessTime />} onDelete={() => setTimeFilter(undefined)} />
          ) : (
            <Chip label="All" icon={<AccessTime />} clickable onClick={() => setOpenTimeFilter(true)} />
          )}
          {typeFilter && (
            <Chip label={startCase(typeFilter)} onDelete={() => setTypeFilter(undefined)} icon={<FilterList />} />
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', gap: 2, justifyContent: 'space-around' }}>
        <Paper sx={infoStyle} aria-label="Total edit jobs">
          <CardActionArea sx={infoActionArea} onClick={() => setTypeFilter(undefined)} disabled={!typeFilter}>
            <Avatar sx={{ bgcolor: blue[500] }}>
              <EditJobsIcon />
            </Avatar>
            <Typography variant="h3">{data.jobs?.total || 0}</Typography>
            <Typography variant="h6">Total</Typography>
          </CardActionArea>
        </Paper>
        <Paper sx={infoStyle} aria-label="In Progress edit jobs">
          <CardActionArea
            sx={infoActionArea}
            onClick={() => setTypeFilter('inProgress')}
            disabled={typeFilter === 'inProgress'}
          >
            <Avatar sx={{ bgcolor: yellow[700] }}>
              <ProgressIcon />
            </Avatar>
            <Typography variant="h3">{data.jobs?.inProgressJobs ? data.jobs?.inProgressJobs?.length : 0}</Typography>
            <Typography variant="h6">In Progress</Typography>
          </CardActionArea>
        </Paper>
        <Paper sx={infoStyle} aria-label="Overdue edit jobs" variant="elevation">
          <CardActionArea
            sx={infoActionArea}
            onClick={() => setTypeFilter('overdue')}
            disabled={typeFilter === 'overdue'}
          >
            <Avatar sx={{ bgcolor: pink[500] }}>
              <AvTimerIcon />
            </Avatar>
            <Typography variant="h3">{data.jobs?.lateJobs || 0}</Typography>
            <Typography variant="h6">Overdue</Typography>
          </CardActionArea>
        </Paper>
      </Box>
      <TableContainer component={Paper} sx={{ overflow: 'auto' }}>
        <Table<EditJob>
          columns={columns}
          data={data.editJobs}
          tableProps={{ stickyHeader: true }}
          headerProps={{ sx: { backgroundColor: `${colors.neutral100}`, fontWeight: `${fontWeight.typographyfontweight3}`} }}
          enableSorting
        />
      </TableContainer>
      <DateTimePicker
        open={openTimeFilter}
        onClose={() => setOpenTimeFilter(false)}
        setDateTime={(value) => setTimeFilter(value)}
      />
    </Box>
  );
}

export default EditJobsDashboard;
