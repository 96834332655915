import {
  Box,
  Paper,
  Skeleton,
  Typography,
  Avatar,
  TableContainer,
  Link,
  Chip,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import { AccessTime, AddToQueue, Folder, Backup, GroupWork } from '@mui/icons-material';
import { grey, blue, green, orange } from '@mui/material/colors';
import { sub } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { startCase } from 'lodash';
import { Table, Column } from '@nekta-temp/component-library.table';
import { DateTimePickerDialog as DateTimePicker, TimeFilter } from '../DateTimePickerDialog';
import { useDeploymentBilling, DeploymentWithBilling, BillingData, useBilling } from '../../../api/billing';
import TimeSeriesLineChart from '../../../common/TimeSeriesLineChart';
import { colors, fontWeight, fontSizes } from '../../../theme/designtoken';

const infoStyle = {
  minWidth: 250,
  minHeight: 200,
} as const;

const infoActionArea = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 2,
  padding: 2,
  borderRadius: 4,
  border: '2px solid #B15EFF',
} as const;

const SkeletonPage = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
    <Skeleton variant="text" width={200} />
    <Box sx={{ display: 'flex', width: '100%', gap: 2, justifyContent: 'space-evenly' }}>
      <Skeleton variant="rectangular" sx={infoStyle} />
      <Skeleton variant="rectangular" sx={infoStyle} />
      <Skeleton variant="rectangular" sx={infoStyle} />
      <Skeleton variant="rectangular" sx={infoStyle} />
    </Box>
    <Skeleton variant="rectangular" sx={{ height: '30%' }} />
    <Skeleton variant="rectangular" sx={{ height: '30%' }} />
  </Box>
);

function getBillingGraphData(data: BillingData[]) {
  const graphData: { value: number; timestamp: number }[] = data.map((period) => {
    const total = period.Groups.reduce((total, next) => total + Number(next.Metrics.UnblendedCost.Amount), 0);
    return { value: total, timestamp: new Date(period.TimePeriod.Start).getTime() };
  });

  return graphData;
}

function BillingDashboard() {
  const aMonthAgo = sub(new Date(), { months: 1 });
  const defaultTimeFilter = {
    label: 'Last month',
    filter: { gte: aMonthAgo.getTime() * 1000, lte: Date.now() * 1000 },
  };

  const [timeFilter, setTimeFilter] = useState<TimeFilter>(defaultTimeFilter);
  const [openTimeFilter, setOpenTimeFilter] = useState(false);
  const [graphData, setGraphData] = useState<{ value: number; timestamp: number }[]>([]);
  const deploymentCosts = useDeploymentBilling({ timeFilter: timeFilter.filter });
  const { data, billingResponse } = deploymentCosts;
  const s3Costs = useBilling({
    getTotalCost: true,
    filter: { Dimensions: { Key: 'SERVICE', Values: ['Amazon Simple Storage Service'] } },
    startDate:
      ('gte' in timeFilter.filter && new Date(timeFilter.filter.gte / 1000).toISOString().split('T')[0]) || undefined,
    endDate:
      ('lte' in timeFilter.filter && new Date(timeFilter.filter.lte / 1000).toISOString().split('T')[0]) || undefined,
  });
  const fsxCosts = useBilling({
    getTotalCost: true,
    filter: { Dimensions: { Key: 'SERVICE', Values: ['Amazon FSx'] } },
    startDate:
      ('gte' in timeFilter.filter && new Date(timeFilter.filter.gte / 1000).toISOString().split('T')[0]) || undefined,
    endDate:
      ('lte' in timeFilter.filter && new Date(timeFilter.filter.lte / 1000).toISOString().split('T')[0]) || undefined,
  });

  useEffect(() => {
    if (billingResponse?.billingData) {
      const graph = getBillingGraphData(billingResponse?.billingData);
      setGraphData(graph);
    }
  }, [billingResponse?.billingData]);

  if (deploymentCosts.isError) {
    return <Typography>Sorry an error has occured. Please try again</Typography>;
  }

  const isLoading = deploymentCosts.isLoading || s3Costs.isLoading || fsxCosts.isLoading;
  if (isLoading) {
    return <SkeletonPage />;
  }

  const columns: Column<DeploymentWithBilling>[] = [
    {
      Header: 'Deployment Name',
      accessor: 'name',
      Cell: ({ value, row }) =>
        row.original.type === 'dcv' ? (
          <Link to={`/admin/workstations/${row.original.UUID}`} component={RouterLink} underline="hover">
            {value}
          </Link>
        ) : (
          value
        ),
    },
    { Header: 'Type', accessor: 'type' },
    {
      Header: 'Status',
      id: 'status',
      accessor: (row) => (row.isDeleted ? row.status : row.powerState),
      Cell: ({ value }: { value: string }) => startCase(value),
    },
    {
      Header: 'Cost',
      accessor: 'totalCost',
      Cell: ({ value }: { value: number }) => `$${value.toFixed(2)}`,
    },
  ];

  const totalAWSCost = billingResponse?.totalValue || 0;
  const fsxCost = fsxCosts.data?.totalValue || 0;
  const s3Cost = s3Costs.data?.totalValue || 0;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">Billing Overview</Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Chip label={timeFilter.label} icon={<AccessTime />} onClick={() => setOpenTimeFilter(true)} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', gap: 2, justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Paper sx={{ ...infoStyle }} aria-label="Total aws cost">
          <Box sx={infoActionArea}>
            <Avatar sx={{ bgcolor: blue[500] }}>
              <MoneyIcon />
            </Avatar>
            <Typography variant="h3">${totalAWSCost.toFixed(2)}</Typography>
            <Typography variant="h6">Total AWS Cost</Typography>
          </Box>
        </Paper>
        <Paper sx={{ ...infoStyle }} aria-label="Deployment Cost">
          <Box sx={infoActionArea}>
            <Avatar sx={{ bgcolor: orange[400] }}>
              <AddToQueue />
            </Avatar>
            <Typography variant="h3">${deploymentCosts.totalCost.toFixed(2)}</Typography>
            <Typography variant="h6">Deployment Cost</Typography>
          </Box>
        </Paper>
        <Paper sx={{ ...infoStyle }} aria-label="Infrastructure Cost">
          <Box sx={infoActionArea}>
            <Avatar>
              <GroupWork />
            </Avatar>
            <Typography variant="h3">
              ${(totalAWSCost - deploymentCosts.totalCost - s3Cost - fsxCost).toFixed(2)}
            </Typography>
            <Typography variant="h6">Infrastructure Cost</Typography>
          </Box>
        </Paper>
        <Paper sx={{ ...infoStyle }} aria-label="S3 Cost">
          <Box sx={infoActionArea}>
            <Avatar sx={{ bgcolor: orange[900] }}>
              <Backup />
            </Avatar>
            <Typography variant="h3">${s3Cost.toFixed(2)}</Typography>
            <Typography variant="h6">S3 Cost</Typography>
          </Box>
        </Paper>
        <Paper sx={{ ...infoStyle }} aria-label="FSX Cost">
          <Box sx={infoActionArea}>
            <Avatar sx={{ bgcolor: green[500] }}>
              <Folder />
            </Avatar>
            <Typography variant="h3">${fsxCost.toFixed(2)}</Typography>
            <Typography variant="h6">FSX Cost</Typography>
          </Box>
        </Paper>
      </Box>
      <Card
        sx={{
          minHeight: 300,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardHeader title="Daily cost" titleTypographyProps={{ align: 'center', gutterBottom: false }} />
        <CardContent
          aria-label="Daily cost"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            overflowY: 'auto',
            paddingTop: 0,
          }}
        >
          {graphData.length ? (
            <TimeSeriesLineChart
              data={graphData}
              startTime={new Date(graphData[0].timestamp).getTime()}
              endTime={new Date(graphData[graphData.length - 1].timestamp).getTime()}
              yAxisLabel="$"
              tickFormatter={{ xAxis: { dateStyle: 'short' }, yAxis: (value: number) => value.toFixed(2) }}
            />
          ) : (
            <Typography align="center">No cpu data available</Typography>
          )}
        </CardContent>
      </Card>
      <TableContainer component={Paper} sx={{ overflow: 'auto' }}>
        <Table<DeploymentWithBilling>
          columns={columns}
          data={data || []}
          tableProps={{ stickyHeader: true }}
          headerProps={{ sx: { backgroundColor: `${colors.neutral100}`, fontWeight: `${fontWeight.typographyfontweight3}`} }}
          enableSorting
        />
      </TableContainer>
      <DateTimePicker
        open={openTimeFilter}
        onClose={() => setOpenTimeFilter(false)}
        setDateTime={(value) => setTimeFilter(value)}
        alwaysSetLTE
        dateOnly
      />
    </Box>
  );
}

export default BillingDashboard;
