import { FunctionComponent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Typography, Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ProjectFormValues } from '../CreateProjectDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  bucketsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
}));

type ReviewProps = {
  form: UseFormReturn<ProjectFormValues>;
};

export const Review: FunctionComponent<ReviewProps> = ({ form }) => {
  const { getValues } = form;
  const styles = useStyles();
  const formValues = getValues();

  return (
    <div className={styles.container}>
      <Typography variant="subtitle1">Please review your details and create when ready</Typography>

      <div>
        <Typography variant="subtitle2">Project Name</Typography>
        <p>{formValues.projectName}</p>
      </div>
      <div>
        <Typography variant="subtitle2">Project Description</Typography>
        <p>{formValues.description}</p>
      </div>
      <div>
        <Typography variant="subtitle2">Selected Buckets</Typography>
        <div className={styles.bucketsContainer}>
          {formValues.buckets?.map((bucket) => (
            <Chip label={`${bucket.regionLabel} - ${bucket.bucket}`} key={bucket.bucket} />
          ))}
        </div>
      </div>
      <div>
        <Typography variant="subtitle2">Integrations</Typography>
        {formValues?.reviewPlatforms?.frameio?.enabled ? (
          <p>FrameIO enabled</p>
        ) : (
          <p>No integrations enabled for this project</p>
        )}
      </div>
      <div>
        <Typography variant="subtitle2">Users</Typography>
        <p>{formValues.users ? Object.keys(formValues.users).length : 0} users assigned to this project</p>
      </div>
    </div>
  );
};

export default Review;
