import { CircularProgress, Typography, Paper, TableContainer, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Column } from '@nekta-temp/component-library.table';
import { useTranscodeJobs, TranscodeJob } from '../../api/transcode';
import getLocalDateTime from '../../utils/getLocalDateTime';
import Loading from '../../common/Loading';
import PermissionWrapper from '../../common/PermissionWrapper';
import CreateTranscodeJob from './CreateTranscodeJob';
import { buttonColor, colors, fontWeight, sizes } from '../../theme/designtoken';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    gap: 16,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  centerContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMore: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: 8,
  },
  row: {
    cursor: 'pointer',
  },
}));

export const TranscodeJobs: FunctionComponent = () => {
  const { data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage } = useTranscodeJobs();
  const classes = useStyles();
  const [openCreateForm, setOpenCreateForm] = useState(false);
  const history = useHistory();

  const columns: Column<TranscodeJob>[] = [
    {
      Header: 'Name',
      accessor: 'transcodeJobName',
    },
    {
      Header: 'User',
      accessor: (job) => job.createdBy.userName,
    },
    {
      Header: 'Created',
      accessor: (job) =>
        getLocalDateTime(job.dateAddedInMicroSeconds / 1000, {
          timeStyle: 'medium',
          dateStyle: 'short',
        }),
    },
    {
      Header: 'Dimensions',
      accessor: (job) => `${job.width} × ${job.height}`,
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h6">Transcode jobs</Typography>
        <PermissionWrapper requiredPermissions={['transcode:createJob']}>
          <Button variant="outlined" onClick={() => setOpenCreateForm(true)} sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}>
            New
          </Button>
        </PermissionWrapper>
      </div>

      {isLoading && <Loading />}
      {data?.pages[0] && data.pages[0].transcodeJobs.length > 0 && (
        <TableContainer component={Paper}>
          <Table
            columns={columns}
            data={data.pages.flatMap((page) => page.transcodeJobs)}
            headerProps={{ sx: { backgroundColor: `${colors.neutral100}`, fontWeight: `${fontWeight.typographyfontweight3}`} }}
            rowProps={(row) => ({
              onClick: () => history.push(`/transcode/job/${row.original.UUID}`),
              hover: true,
              className: classes.row,
            })}
          />
        </TableContainer>
      )}
      {!data?.pages[0]?.transcodeJobs.length && !isLoading && !isError && (
        <div className={classes.centerContainer}>
          <Typography>No transcode jobs available</Typography>
        </div>
      )}
      {isError && !data?.pages[0]?.transcodeJobs.length && (
        <div className={classes.centerContainer}>
          <Typography>Sorry there was a problem loading transcode jobs</Typography>
        </div>
      )}
      {hasNextPage && (
        <div className={classes.loadMore}>
          {isFetchingNextPage ? (
            <CircularProgress />
          ) : (
            <Button onClick={() => fetchNextPage()} variant="outlined" sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}>
              Load More
            </Button>
          )}
        </div>
      )}
      {openCreateForm && <CreateTranscodeJob open={openCreateForm} onClose={() => setOpenCreateForm(false)} />}
    </div>
  );
};

export default TranscodeJobs;
