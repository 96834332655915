import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from '../contexts/Snackbar';
import { useApi } from '../contexts/Api';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  feedbackTable: {
    width: '100%',
    marginBottom: 20,
  },
  feedbackTableFieldCell: {
    width: '30%',
    padding: 5,
    paddingLeft: 0,
    verticalAlign: 'top',
  },
  feedbackTableValueCell: {
    width: '60%',
    padding: 5,
    paddingLeft: '10%',
  },
}));

const BugReport = ({ onClose }: { onClose: () => void }) => {
  const classes = useStyles();
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const { post } = useApi();
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [feedbackTicketUrl, setFeedbackTicketUrl] = useState<string>();
  const [formValues, setFormValues] = useState({
    platform: 'web',
    reportType: 'bug',
    reportMessage: '',
    reportTitle: '',
    pageRoute: history.location.pathname,
  });

  const onSubmit = async () => {
    setSubmitting(true);

    if (formValues.reportMessage === '' || formValues.reportTitle === '') {
      setError(true);
      setSubmitting(false);
      return;
    }

    formValues.pageRoute = history.location.pathname;

    try {
      const { data } = await post<{ message?: string; url: string }>('/report', formValues);

      openSnackbar(data.message ? data.message : 'Feedback submitted successfully!');
      setFeedbackTicketUrl(data.url);
      setIsFeedbackSubmitted(true);
    } catch (err) {
      openSnackbar('An unknown error occured. Plesae try again!');
    }

    setSubmitting(false);
  };

  const openFeedbackTicket = () => feedbackTicketUrl && window.open(feedbackTicketUrl);

  const getSubmittedFeedbackContent = () => (
    <>
      <table className={classes.feedbackTable}>
        <tbody>
          <tr>
            <td className={classes.feedbackTableFieldCell}>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Feedback Type
              </Typography>
            </td>
            <td className={classes.feedbackTableValueCell}>
              <Typography variant="body1">
                {formValues.reportType === 'bug' ? 'Bug Report' : 'Feature Request'}
              </Typography>
            </td>
          </tr>
          <tr>
            <td className={classes.feedbackTableFieldCell}>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Title
              </Typography>
            </td>
            <td className={classes.feedbackTableValueCell}>
              <Typography variant="body1">{formValues.reportTitle}</Typography>
            </td>
          </tr>
          <tr>
            <td className={classes.feedbackTableFieldCell}>
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Detail
              </Typography>
            </td>
            <td className={classes.feedbackTableValueCell}>
              <Typography variant="body1">{formValues.reportMessage}</Typography>
            </td>
          </tr>
        </tbody>
      </table>
      <DialogContentText id="alert-dialog-slide-description">
        Thank you for your feedback. To add more details / screenshots to this feedback click{' '}
        <Link onClick={openFeedbackTicket} style={{ cursor: 'pointer', fontWeight: 'bold' }} color="primary">
          here
        </Link>{' '}
        to view your ticket. If it doesn't work, you can copy and paste the url below directly into the browser
        <br />
        <br />
        <Link onClick={openFeedbackTicket} style={{ cursor: 'pointer' }} color="primary">
          {feedbackTicketUrl}
        </Link>
      </DialogContentText>
    </>
  );

  return (
    <Dialog open aria-label="bug-report">
      <DialogTitle id="alert-dialog-slide-title">Feedback</DialogTitle>
      <DialogContent>
        {!isFeedbackSubmitted ? (
          <>
            <DialogContentText id="alert-dialog-slide-description">
              For reporting bugs or requesting new feature, please fill the form below and submit <br />
            </DialogContentText>
            {error && (
              <Typography variant="body1" style={{ color: 'indianred' }}>
                Title & Detail fields are required !
              </Typography>
            )}
            <FormControl
              className={classes.formControl}
              variant="filled"
              disabled={isFeedbackSubmitted}
              color="primary"
            >
              <InputLabel id="type">Type</InputLabel>
              <Select
                color="primary"
                disabled={isFeedbackSubmitted}
                labelId="type"
                id="type"
                name="type"
                value={formValues.reportType}
                onChange={(e) => {
                  const { value } = e.target;
                  setFormValues((prevValues) => ({ ...prevValues, reportType: value }));
                }}
              >
                <MenuItem value="bug">Bug Report</MenuItem>
                <MenuItem value="feature">Feature Request</MenuItem>
              </Select>
              <FormHelperText>Select Feedback Type</FormHelperText>
            </FormControl>

            <FormControl className={classes.formControl} variant="filled">
              <TextField
                color="primary"
                disabled={isFeedbackSubmitted}
                autoFocus
                variant="filled"
                margin="dense"
                id="title"
                label="Title"
                type="text"
                name="title"
                value={formValues.reportTitle}
                onChange={(e) => {
                  const { value } = e.target;
                  setFormValues((prevValues) => ({ ...prevValues, reportTitle: value }));
                }}
                fullWidth
                helperText="Bug Report  / Feature Request Title"
              />
            </FormControl>

            <FormControl className={classes.formControl} variant="filled">
              <TextField
                color="primary"
                disabled={isFeedbackSubmitted}
                variant="filled"
                margin="dense"
                id="detail"
                label="Detail"
                type="text"
                name="detail"
                value={formValues.reportMessage}
                onChange={(e) => {
                  const { value } = e.target;
                  setFormValues((prevValues) => ({ ...prevValues, reportMessage: value }));
                }}
                fullWidth
                helperText="Bug Report  / Feature Request Details"
                multiline
                rows={4}
              />
            </FormControl>
          </>
        ) : (
          getSubmittedFeedbackContent()
        )}
      </DialogContent>

      {!isFeedbackSubmitted ? (
        <DialogActions style={{ padding: 24 }}>
          {!submitting ? (
            <>
              <Button onClick={onClose} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button onClick={onSubmit} color="primary" variant="outlined">
                Submit
              </Button>
            </>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 70 }}>
              <CircularProgress size={24} color="primary" />
            </div>
          )}
        </DialogActions>
      ) : (
        <DialogActions style={{ padding: 24 }}>
          <Button onClick={onClose} color="primary" variant="outlined">
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default BugReport;
