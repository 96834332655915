import {
  Box,
  Paper,
  Typography,
  IconButton,
  DialogActions,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import { Edit, Delete, Lan, KeyboardBackspace, Article, Monitor } from '@mui/icons-material';
import { Table, Column } from '@nekta-temp/component-library.table';
import { useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';
import { startCase } from 'lodash';
import {
  AutoscalingInstance,
  useAutoscalingDeployment,
  useDeployment,
  useDeploymentGateways,
  useDeploymentMetrics,
} from '../../../api/deployments';
import Loading from '../../../common/Loading';
import TimeSeriesLineChart from '../../../common/TimeSeriesLineChart';
import { useSnackbar } from '../../../contexts/Snackbar';
import DeleteWorkstation from '../../workstations/DeleteWorkstation';
import EditWorkstation from '../../workstations/edit/EditWorkstation';
import { createWorkstationSession } from '../../workstations/createWorkstationSession';
import { useApi } from '../../../contexts/Api';

const chartContainer = { minHeight: '300px', display: 'flex', flexDirection: 'column' } as const;
const chartContainerSpan = { ...chartContainer, gridColumnEnd: 'span 2' } as const;
const chartContent = {
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  overflowY: 'auto',
  pt: 0,
} as const;

export function AutoscalingDeployment() {
  const { deploymentId } = useParams<{ deploymentId: string }>();
  const {
    data: autoscaling,
    isLoading: isAutoscalingLoading,
    isError: isAutoscalingError,
  } = useAutoscalingDeployment(deploymentId);
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const { data: deployment, isLoading: isDeploymentLoading } = useDeployment(deploymentId, true, {
    onError: (err) => {
      if (err.response?.status === 404) {
        openSnackbar("Sorry this deployment doesn't exist", { alert: 'error' });
        history.push('/admin/deployments');
      }
    },
  });
  const { isLoading: isMetricsLoading, data: metricsData } = useDeploymentMetrics(deploymentId, true);
  const { data: gateways } = useDeploymentGateways(deploymentId);
  const { post } = useApi();
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoadingSession, setIsLoadingSession] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState<string>();
  const largeScreen = useMediaQuery('(min-width:950px)');

  if (isAutoscalingLoading || isDeploymentLoading) {
    return <Loading />;
  }

  const columns: Column<AutoscalingInstance>[] = [
    { Header: 'Instance ID', accessor: 'InstanceId' },
    { Header: 'Status', accessor: 'HealthStatus' },
    { Header: 'Availability Zone', accessor: 'AvailabilityZone' },
    { Header: 'Type', accessor: 'InstanceType' },
    { Header: 'Lifecycle State', accessor: 'LifecycleState', Cell: ({ value }) => startCase(value) },
    {
      Header: 'Logs',
      id: 'logs',
      accessor: 'InstanceId',
      Cell: ({ value }) => (
        <IconButton
          onClick={() => history.push(`/admin/deployments/${deploymentId}/${value}/logs`)}
          color="primary"
          title="View instance logs"
        >
          <Article />
        </IconButton>
      ),
    },
    {
      Header: 'Connect',
      id: 'connect',
      accessor: 'InstanceId',
      Cell: ({ value }) => (
        <IconButton
          onClick={() => setSelectedInstance(value)}
          color="primary"
          title="Connect to instance"
          sx={{ ml: 1.5 }}
        >
          <Monitor />
        </IconButton>
      ),
    },
  ];

  if (deployment && (deployment.platformOS !== 'windows' || deployment.type !== 'transcoder')) {
    columns.splice(6, 1); // remove connect column
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Paper
        variant="outlined"
        square
        sx={{ display: 'flex', height: '50px', pl: 2, pr: 2, alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Lan />
          <Typography variant="h6">{deployment?.name}</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <IconButton onClick={() => history.push('/admin/deployments')} color="primary" title="Back">
            <KeyboardBackspace />
          </IconButton>
          <IconButton
            onClick={() => setOpenEdit(true)}
            color="primary"
            aria-label="Open edit deployment"
            title="Edit deployment"
            size="large"
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={() => setOpenDelete(true)}
            color="primary"
            aria-label="Open delete deployment"
            title="Delete deployment"
            size="large"
          >
            <Delete />
          </IconButton>
        </Box>
      </Paper>
      {autoscaling && (
        <Paper sx={{ padding: 2, display: 'flex', justifyContent: 'space-around' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center' }}>
            <Typography variant="h6">Current</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6">{autoscaling.instances.length}</Typography>
            <Typography variant="body2">Actual</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6">{autoscaling.instances.length}</Typography>
            <Typography variant="body2">Desired</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6">{autoscaling.MinSize}</Typography>
            <Typography variant="body2">Min Size</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6">{autoscaling.MaxSize}</Typography>
            <Typography variant="body2">Max Size</Typography>
          </Box>
        </Paper>
      )}
      {autoscaling && (
        <Paper sx={{ padding: 2, display: 'flex', justifyContent: 'space-around' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center' }}>
            <Typography variant="h6">Default</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6">-</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6">{autoscaling.instances.length}</Typography>
            <Typography variant="body2">Desired</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6">{autoscaling.MinSize}</Typography>
            <Typography variant="body2">Min Size</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6">{autoscaling.MaxSize}</Typography>
            <Typography variant="body2">Max Size</Typography>
          </Box>
        </Paper>
      )}
      {autoscaling && autoscaling.instances.length > 0 && (
        <Paper>
          <Table<AutoscalingInstance> columns={columns} data={autoscaling.instances} />
        </Paper>
      )}
      {isAutoscalingError && (
        <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6">Sorry there has been a problem loading the autoscaling details</Typography>
        </Paper>
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'minmax(325px, 1fr) repeat(auto-fit, minmax(317px, 2fr))',
          gap: 2,
          flexGrow: 1,
        }}
      >
        <Card
          sx={
            metricsData?.cpu.MetricDataResults[0].datapoints.length && largeScreen ? chartContainerSpan : chartContainer
          }
        >
          <CardHeader title="CPU Utilization" titleTypographyProps={{ align: 'center', gutterBottom: false }} />
          <CardContent sx={chartContent} aria-label="CPU Utilization">
            {isMetricsLoading ? (
              <CircularProgress />
            ) : (
              <>
                {metricsData?.cpu.MetricDataResults[0].datapoints.length ? (
                  <TimeSeriesLineChart
                    data={metricsData?.cpu.MetricDataResults[0].datapoints}
                    startTime={new Date(`${metricsData?.cpu.MetricDataResults[0].datapoints[0].timeStamp}`).getTime()}
                    endTime={new Date(
                      `${
                        metricsData?.cpu.MetricDataResults[0].datapoints[
                          metricsData?.cpu.MetricDataResults[0].datapoints.length - 1
                        ].timeStamp
                      }`,
                    ).getTime()}
                    // endTime={new Date(`${metricsData?.cpu.endTime}Z`).getTime()}
                    yAxisUnit={metricsData?.cpu.unit === 'percent' ? '%' : metricsData?.cpu.unit}
                    yAxisLabel="CPU"
                  />
                ) : (
                  <Typography align="center">No cpu data available</Typography>
                )}
              </>
            )}
          </CardContent>
        </Card>
        <Card
          sx={
            metricsData?.memory.MetricDataResults[0].datapoints.length && largeScreen
              ? chartContainerSpan
              : chartContainer
          }
        >
          <CardHeader title="Memory Utilization" titleTypographyProps={{ align: 'center', gutterBottom: false }} />
          <CardContent sx={chartContent} aria-label="Memory Utilization">
            {isMetricsLoading ? (
              <CircularProgress />
            ) : (
              <>
                {metricsData?.memory.MetricDataResults[0].datapoints.length ? (
                  <TimeSeriesLineChart
                    data={metricsData?.memory.MetricDataResults[0].datapoints}
                    startTime={new Date(
                      `${metricsData?.memory.MetricDataResults[0].datapoints[0].timeStamp}`,
                    ).getTime()}
                    endTime={new Date(`${metricsData?.memory.endTime}Z`).getTime()}
                    yAxisUnit={metricsData?.memory.unit}
                    yAxisLabel="Memory"
                  />
                ) : (
                  <Typography align="center">No memory data available</Typography>
                )}
              </>
            )}
          </CardContent>
        </Card>
        <Card
          sx={
            metricsData?.networkIn.MetricDataResults[0].datapoints.length && largeScreen
              ? chartContainerSpan
              : chartContainer
          }
        >
          <CardHeader title="Network In" titleTypographyProps={{ align: 'center', gutterBottom: false }} />
          <CardContent sx={chartContent} aria-label="Network In">
            {isMetricsLoading ? (
              <CircularProgress />
            ) : (
              <>
                {metricsData?.networkIn.MetricDataResults[0].datapoints.length ? (
                  <TimeSeriesLineChart
                    data={metricsData.networkIn.MetricDataResults[0].datapoints}
                    startTime={new Date(
                      `${metricsData?.networkIn.MetricDataResults[0].datapoints[0].timeStamp}`,
                    ).getTime()}
                    endTime={new Date(`${metricsData?.networkIn.endTime}Z`).getTime()}
                    yAxisUnit={metricsData?.networkIn.unit}
                    yAxisLabel="Network In"
                  />
                ) : (
                  <Typography align="center">No network data available</Typography>
                )}
              </>
            )}
          </CardContent>
        </Card>
        <Card
          sx={
            metricsData?.networkOut.MetricDataResults[0].datapoints.length && largeScreen
              ? chartContainerSpan
              : chartContainer
          }
        >
          <CardHeader title="Network Out" titleTypographyProps={{ align: 'center', gutterBottom: false }} />
          <CardContent sx={chartContent} aria-label="Network Out">
            {isMetricsLoading ? (
              <CircularProgress />
            ) : (
              <>
                {metricsData?.networkOut.MetricDataResults[0].datapoints.length ? (
                  <TimeSeriesLineChart
                    data={metricsData.networkOut.MetricDataResults[0].datapoints}
                    startTime={new Date(
                      `${metricsData?.networkOut.MetricDataResults[0].datapoints[0].timeStamp}`,
                    ).getTime()}
                    endTime={new Date(`${metricsData.networkOut.endTime}Z`).getTime()}
                    yAxisUnit={metricsData?.networkOut.unit}
                    yAxisLabel="Network out"
                  />
                ) : (
                  <Typography align="center">No network data available</Typography>
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Box>
      {deployment && (
        <>
          <EditWorkstation deployment={deployment} open={openEdit} onClose={() => setOpenEdit(false)} />
          <DeleteWorkstation
            deployment={deployment}
            open={openDelete}
            onClose={() => setOpenDelete(false)}
            onSuccess={() => {
              openSnackbar('Deployment deleted successfully', { alert: 'success' });
              history.push('/admin/deployments');
            }}
          />
        </>
      )}
      <Dialog open={!!selectedInstance} aria-label="Select gateway dialog">
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            setIsLoadingSession(true);
            const { elements } = e.target as typeof e.target & {
              elements: { gateway: { value: string } };
            };
            try {
              await createWorkstationSession(post, {
                deploymentUUID: deploymentId,
                accessType: 'owner',
                gatewayUUID: elements.gateway.value,
                instanceID: selectedInstance,
                name: selectedInstance,
              });
            } catch (e) {
              openSnackbar('Sorry an error has occured creating a sessions to this instance', { alert: 'error' });
            }
            setIsLoadingSession(false);
            setSelectedInstance(undefined);
          }}
        >
          <DialogTitle>Select gateway</DialogTitle>
          <DialogContent sx={{ minWidth: '300px' }}>
            {gateways && gateways.length > 0 ? (
              <TextField
                id="gateway"
                select
                variant="standard"
                label="Gateway"
                margin="dense"
                size="small"
                name="gateway"
                fullWidth
                defaultValue={gateways[0].value}
                disabled={isLoadingSession}
              >
                {gateways.map((gateway) => (
                  <MenuItem key={gateway.value} value={gateway.value}>
                    {gateway.label}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <Typography>Sorry there isn't any gateways to connect to</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedInstance(undefined)} disabled={isLoadingSession}>
              Cancel
            </Button>
            <Button type="submit" disabled={!gateways || gateways.length === 0 || isLoadingSession}>
              {isLoadingSession ? 'Loading...' : 'Select'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default AutoscalingDeployment;
