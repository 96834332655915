import { FunctionComponent, useState } from 'react';
import { Button, Chip, Paper, TableContainer, IconButton, Link } from '@mui/material';
import { Delete, Edit, Done, Close } from '@mui/icons-material';
import { Table, Column } from '@nekta-temp/component-library.table';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { startCase } from 'lodash';
import { Deployment, useUpdateDeploymentPowerStateAdmin } from '../../api/deployments';
import getLocalDateTime from '../../utils/getLocalDateTime';
import { useUser } from '../../api/user';
import { checkPermissions, permissions } from '../../utils/permissions';
import { useApi } from '../../contexts/Api';
import { useSnackbar } from '../../contexts/Snackbar';
import { createWorkstationSession, CreateWorkstationSession } from './createWorkstationSession';
import EditWorkstation from './edit/EditWorkstation';
import DeleteWorkstation from './DeleteWorkstation';
import Box from '@mui/material/Box';
import PlayArrow from '@mui/icons-material/PlayArrow';
import CircularProgress from '@mui/material/CircularProgress';
import Stop from '@mui/icons-material/Stop';
import Sync from '@mui/icons-material/Sync';
import { buttonColor, colors, sizes, fontWeight } from '../../theme/designtoken';


const adminPermissions = [permissions.adminDeployments, permissions.userAdmin, permissions.transcodeJobs];

function hasAtLeastOnePermission(required: string[][], userPermissions: string[]) {
  return required.some((perm) => checkPermissions(userPermissions, perm));
}

export const WorkstationTable: FunctionComponent<{
  data: Deployment[];
  accessType: CreateWorkstationSession['accessType'];
  showAdditional?: boolean;
}> = ({ data, accessType, showAdditional = false }) => {
  const { data: user } = useUser();
  const [loadingSessions, setLoadingSessions] = useState<Record<string, boolean>>({});
  const { openSnackbar } = useSnackbar();
  const { post } = useApi();
  const [editWorkstation, setEditWorkstation] = useState<Deployment>();
  const [deleteWorkstation, setDeleteWorkstation] = useState<Deployment>();
  const actionProgress = { position: 'absolute', zIndex: 1, left: 6, top: 6 } as const;
  const admin = hasAtLeastOnePermission(adminPermissions, user?.permissions || []);

  const {
    isLoading: powerStateUpdating,
    mutate: updatePowerState,
    variables,
  } = useUpdateDeploymentPowerStateAdmin(admin, {
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const errData = err?.response?.data;
        if (errData?.message) {
          return openSnackbar(errData.message, { alert: 'error' });
        }
      }
    },
  });

  let columns: Column<Deployment>[] = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ row, value }) => (
        <Link
          to={`${admin || row.original.type !== 'dcv' ? '/admin' : ''}/${
            row.original.type === 'dcv' ? 'workstations' : 'deployments'
          }/${row.original.UUID}`}
          component={RouterLink}
          underline="hover"
        >
          {value}
        </Link>
      ),
    },
    {
      Header: 'Owner',
      accessor: 'ownerID',
    },
    {
      Header: 'Created',
      accessor: 'dateAddedInMicroSeconds',
      Cell: ({ value }) =>
        getLocalDateTime(value / 1000, {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
        }),
    },
    {
      Header: 'Region',
      accessor: 'regionName',
    },
    {
      Header: 'State',
      accessor: 'powerState',
      Cell: ({ row, value }) =>
        row.original.type === 'dcv' ? (
          startCase(value)
        ) : (
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
          >{`${row.original.paramInputs?.AsgDesired.v} , ${row.original.paramInputs?.AsgDesired.v} , ${row.original.paramInputs?.AsgMax.v} , ${row.original.paramInputs?.AsgMin.v}`}</Box>
        ),
    },
    {
      Header: 'Action',
      accessor: 'UUID',
      Cell: ({ row, value }) =>
        row.original.type === 'dcv' && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconButton
              onClick={() => {
                updatePowerState({ UUID: row.original.UUID, powerState: 'start' });
              }}
              color="primary"
              aria-label="Start workstation"
              title="Start workstation"
              disabled={
                powerStateUpdating ||
                row.original.powerState === 'running' ||
                row.original.powerState === 'stopping' ||
                row.original.powerState === 'pending'
              }
              size="large"
            >
              <>
                <PlayArrow />
                {powerStateUpdating && variables?.powerState === 'start' && (
                  <CircularProgress sx={actionProgress} size={24} data-testid="circular-progress" />
                )}
              </>
            </IconButton>
            <IconButton
              onClick={() => {
                updatePowerState({ UUID: row.original.UUID, powerState: 'stop' });
              }}
              color="primary"
              aria-label="Stop workstation"
              title="Stop workstation"
              disabled={
                powerStateUpdating ||
                row.original.powerState === 'stopping' ||
                row.original.powerState === 'stopped' ||
                row.original.powerState === 'pending'
              }
              size="large"
            >
              <>
                <Stop />
                {powerStateUpdating && variables?.powerState === 'stop' && (
                  <CircularProgress sx={actionProgress} size={24} data-testid="circular-progress" />
                )}
              </>
            </IconButton>
            <IconButton
              onClick={() => {
                updatePowerState({ UUID: row.original.UUID, powerState: 'reboot' });
              }}
              color="primary"
              disabled={
                powerStateUpdating ||
                row.original.powerState === 'stopped' ||
                row.original.powerState === 'stopping' ||
                row.original.powerState === 'pending'
              }
              title="Restart workstation"
              size="large"
            >
              <>
                <Sync />
                {powerStateUpdating && variables?.powerState === 'reboot' && (
                  <CircularProgress sx={actionProgress} size={24} data-testid="circular-progress" />
                )}
              </>
            </IconButton>
          </Box>
        ),
    },
    {
      Header: 'Session',
      accessor: 'showConnect',
      Cell: ({ value, row }) =>
        value ? (
          <Button
            variant="outlined"
            disabled={loadingSessions[row.original.UUID]}
            sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}
            onClick={async () => {
              try {
                const newLoadingSessions = { ...loadingSessions };
                newLoadingSessions[row.original.UUID] = true;
                setLoadingSessions(newLoadingSessions);
                await createWorkstationSession(post, {
                  deploymentUUID: row.original.UUID,
                  accessType,
                  name: row.original.name,
                });
              } catch (err) {
                openSnackbar('Sorry there was a problem creating your session');
              }
              const newLoadingSessions = { ...loadingSessions };
              newLoadingSessions[row.original.UUID] = false;
              setLoadingSessions(newLoadingSessions);
            }}
          >
            {loadingSessions[row.original.UUID] ? 'Creating Session' : 'Create Session'}
          </Button>
        ) : null,
    },
  ];

  if (admin && showAdditional) {
    const adminColumns: Column<Deployment>[] = [
      {
        Header: 'Shutdown Policy',
        accessor: 'shutdownPolicy',
        Cell: ({ row, value }) => {
          if (row.original.type !== 'dcv') {
            return 'N/A';
          }
          if (value?.enabled || value?.dateBasedShutdown) {
            return <Done aria-label="Active Shutdown Policy" />;
          }
          return <Close aria-label="No Shutdown Policy" />;
        },
      },
      {
        Header: 'Edit',
        accessor: 'UUID',
        id: 'editField',
        Cell: ({ row }) => (
          <IconButton
            onClick={() => setEditWorkstation(row.original)}
            color="primary"
            aria-label="Edit workstation"
            size="large"
          >
            <Edit />
          </IconButton>
        ),
      },
      {
        Header: 'Delete',
        accessor: 'UUID',
        id: 'delete',
        Cell: ({ row }) => (
          <IconButton
            onClick={() => setDeleteWorkstation(row.original)}
            color="primary"
            aria-label="Delete workstation"
            size="large"
          >
            <Delete />
          </IconButton>
        ),
      },
    ];
    columns = columns.concat(adminColumns);
  }

  return (
    <TableContainer component={Paper}>
      <Table<Deployment> columns={columns} data={data}  headerProps={{ sx: { backgroundColor: `${colors.neutral100}`, fontWeight: `${fontWeight.typographyfontweight3}`} }} />
      {editWorkstation && (
        <EditWorkstation
          open={!!editWorkstation}
          onClose={() => setEditWorkstation(undefined)}
          deployment={editWorkstation}
        />
      )}
      {deleteWorkstation && (
        <DeleteWorkstation
          open={!!deleteWorkstation}
          onClose={() => setDeleteWorkstation(undefined)}
          deployment={deleteWorkstation}
        />
      )}
    </TableContainer>
  );
};

export default WorkstationTable;
