import { BrowserRouter as Router, Redirect, Switch, Route as ReactRoute } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';
import { appTheme } from './theme/theme';
import { Route } from './routes/Route';
import Login from './routes/login/Login';
import PremiereProLogin from './routes/login/PremiereProLogin';
import Project from './routes/project/Project';
import Projects from './routes/projects/Projects';
import FileBrowser from './routes/fileBrowser/FileBrowser';
import Job from './routes/job/Job';
import Encoders from './routes/admin/encoders/Encoders';
import Clients from './routes/admin/clients/Clients';
import Page404 from './routes/404/404';
import Workstations from './routes/workstations/Workstations';
import Deployments from './routes/admin/deployments/Deployments';
import TranscodeJobs from './routes/transcode/TranscodeJobs';
import TranscodeJob from './routes/transcode/job/TranscodeJob';
import WorkstationDetails from './routes/workstations/detail/WorkstationDetails';
import EditJobsDashboard from './routes/insights/editJobs/EditJobsDashboard';
import BillingDashboard from './routes/insights/billing/BillingDashboard';
import { SnackbarProvider, useSnackbar } from './contexts/Snackbar';
import { ApiProvider } from './contexts/Api';
import { UploadProvider } from './contexts/Upload';
import WorkstationLogs from './routes/workstations/detail/WorkstationLogs';
import Settings from './routes/admin/settings/Settings';
import AutoscalingDeployment from './routes/admin/deployments/AutoscalingDeployment';
import AutoscalingInstanceLogs from './routes/admin/deployments/AutoscalingInstanceLogs';
import Users from './routes/admin/users/Users';
import User from './routes/admin/users/user/User';
import Roles from './routes/admin/roles/Roles';

export function Routes() {
  return (
    <Switch>
      <ReactRoute exact path="/login" component={Login} />
      <Route privateRoute={false} exact path="/premiere-pro/login">
        <PremiereProLogin />
      </Route>
      <Route exact path="/">
        <EditJobsDashboard />
      </Route>
      <Route exact path="/projects">
        <Projects />
      </Route>
      <Route exact path="/file-browser/*">
        <FileBrowser />
      </Route>
      <Route exact path="/file-browser">
        <Redirect to="/file-browser/" />
      </Route>
      <Route exact path="/project/:projectId">
        <Project />
      </Route>
      <Route exact path="/project/:projectId/job/:jobId/:tab">
        <Job />
      </Route>
      <Route exact path="/workstations" requiredPermissions="userWorkstations">
        <Workstations />
      </Route>
      <Route exact path="/workstations/:workstationId">
        <WorkstationDetails isAdmin={false} />
      </Route>
      <Route exact path="/transcode/jobs" requiredPermissions="transcodeJobs">
        <TranscodeJobs />
      </Route>
      <Route exact path="/transcode/job/:jobId" requiredPermissions={['transcode:getJob']}>
        <TranscodeJob />
      </Route>
      <Route exact path="/admin/encoders">
        <Encoders />
      </Route>
      <Route exact path="/admin/clients">
        <Clients />
      </Route>
      <Route exact path="/admin/deployments" requiredPermissions="adminDeployments">
        <Deployments />
      </Route>
      <Route exact path="/admin/deployments/:deploymentId" requiredPermissions="deploymentAutoscaling">
        <AutoscalingDeployment />
      </Route>
      <Route exact path="/admin/deployments/:deploymentId/:instanceId/logs" requiredPermissions="deploymentLogs">
        <AutoscalingInstanceLogs />
      </Route>
      <Route exact path="/admin/workstations/:workstationId" requiredPermissions="adminDeployment">
        <WorkstationDetails isAdmin={true} />
      </Route>
      <Route exact path="/admin/workstations/:workstationId/logs" requiredPermissions="deploymentLogs">
        <WorkstationLogs />
      </Route>
      <Route exact path="/insights/edit-jobs" requiredPermissions="editJobInsights">
        <EditJobsDashboard />
      </Route>
      <Route exact path="/insights/billing" requiredPermissions="billingInsights">
        <BillingDashboard />
      </Route>
      <Route exact path="/admin/settings" requiredPermissions="updateAdminSettings">
        <Settings />
      </Route>
      <Route exact path="/admin/users" requiredPermissions="userAdmin">
        <Users />
      </Route>
      <Route exact path="/admin/roles" requiredPermissions="roleAdmin">
        <Roles />
      </Route>
      <Route exact path="/admin/users/:email" requiredPermissions="userAdmin">
        <User />
      </Route>
      <Route path="*">
        <Page404 />
      </Route>
    </Switch>
  );
}

function UIProviders({ children }: { children: React.ReactNode }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <SnackbarProvider>{children}</SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

function ApiProviders({ children }: { children: React.ReactNode }) {
  const { openSnackbar } = useSnackbar();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 10,
        staleTime: 1000 * 60 * 5,
        retry: process.env.NODE_ENV !== 'test' ? 3 : false,
      },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 403) {
            openSnackbar("Sorry you don't have permission to access this resource", { alert: 'error' });
          }
        }
      },
    }),
  });
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

function App() {
  return (
    <UIProviders>
      <ApiProviders>
        <Router>
          <ApiProvider>
            <UploadProvider>
              <Routes />
            </UploadProvider>
          </ApiProvider>
        </Router>
      </ApiProviders>
    </UIProviders>
  );
}

export default App;
