import { Typography } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { Deployment, useDeploymentProperties, useEditDeployment } from '../../../api/deployments';
import Loading from '../../../common/Loading';
import TemplateForm, { TemplateFormProps } from '../../../common/TemplateForm';
import { useSnackbar } from '../../../contexts/Snackbar';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  deployment: Deployment;
};

function EditProperties({ deployment, onSuccess, onCancel }: Props) {
  const { openSnackbar } = useSnackbar();
  const { data, isLoading, isError } = useDeploymentProperties(deployment.UUID);
  const [formErrors, setFormErrors] = useState<TemplateFormProps['manualErrors']>();
  const { mutate, isLoading: isEditing } = useEditDeployment({
    onSuccess: () => {
      openSnackbar('Deployment edited successfully', { alert: 'success' });
      onSuccess();
    },
    onError: (error, variables) => {
      if (axios.isAxiosError(error)) {
        const errData = error?.response?.data;
        if (errData?.field) {
          const previousValue = variables.values[errData.field];

          return setFormErrors([
            {
              field: errData.field,
              message: errData.message,
              previousValue,
            },
          ]);
        }
        if (errData?.message) {
          return openSnackbar(errData.message, { alert: 'error' });
        }
      }
      openSnackbar('Sorry there was a problem editing this deployment', { alert: 'error' });
    },
  });

  const onSubmit: TemplateFormProps['onSubmit'] = (values) => {
    mutate({ UUID: deployment.UUID, values });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!data || isError) {
    return <Typography>Sorry there has been a problem loading this edit form</Typography>;
  }

  return (
    <TemplateForm
      onSubmit={onSubmit}
      fields={data?.inputs}
      manualErrors={formErrors}
      submitButtonText="Save"
      onCancel={onCancel}
      disabled={isEditing}
    />
  );
}

export default EditProperties;
