import { FunctionComponent } from 'react';
import { Table, Column } from '@nekta-temp/component-library.table';
import { Event } from '../../../api/deployments';
import getLocalDateTime from '../../../utils/getLocalDateTime';

type Props = {
  data: Event[];
};

export const EventTable: FunctionComponent<Props> = ({ data }) => {
  const columns: Column<Event>[] = [
    {
      Header: 'Time',
      accessor: 'dateAddedInMicroSeconds',
      Cell: ({ value }) =>
        getLocalDateTime(value / 1000, {
          dateStyle: 'short',
          timeStyle: 'short',
        }),
    },
    {
      Header: 'Title',
      accessor: 'eventTitle',
    },
  ];
  return <Table<Event> data={data} columns={columns} />;
};

export default EventTable;
