import { FunctionComponent } from 'react';
import { styled } from '@mui/system';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import { TopBar, TopBarHeight } from './TopBar';
import LeftMenu from './LeftMenu';
import { colors } from '../theme/designtoken';

const Main = styled('main')({
  overflow: 'auto',
  flexGrow: 1,
  padding: '8px 16px',
  backgroundColor: `${colors.backgroundColor}`,
});

const Layout: FunctionComponent = ({ children }) => {
  const history = useHistory();
  const isLoginRoute = history.location.pathname.endsWith('/login');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {!isLoginRoute && <TopBar />}

      <Box sx={{ display: 'flex', height: isLoginRoute ? '100vh' : `calc(100vh - ${TopBarHeight})` }}>
        {!isLoginRoute && <LeftMenu />}

        <Main>{children}</Main>
      </Box>
    </Box>
  );
};

export default Layout;
