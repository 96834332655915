import { CircularProgress, Paper, Typography, Button, styled, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useConfig } from '../../api/config';
import {
  useCurrentProject,
  useEditProject,
  EditProjectData,
  useProjectUsers,
  useEditProjectUsers,
  useDeleteProjectUser,
} from '../../api/projects';
import { useSnackbar } from '../../contexts/Snackbar';
import { useApi } from '../../contexts/Api';
import { ProjectFormValues } from '../projects/create/CreateProjectDialog';
import BasicInfo from '../projects/create/steps/BasicInfo';
import { Buckets, formatBuckets } from '../projects/create/steps/Buckets';
import { Integrations } from '../projects/create/steps/Integrations';
import { Users } from '../projects/create/steps/Users';
import Loading from '../../common/Loading';
import DeleteDialog from './DeleteDialog';

export const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  padding: 16,
});

export const UpdateProject = ({ onSuccess }: { onSuccess: () => void }) => {
  const { openSnackbar } = useSnackbar();
  const { post } = useApi();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { data: config } = useConfig();
  const { isLoading, data: project, isError } = useCurrentProject();
  const form = useForm<ProjectFormValues>({
    defaultValues: {
      projectName: project?.projectName,
      description: project?.description,
      reviewPlatforms: project?.integrations,
    },
    mode: 'onChange',
  });
  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    setValue,
  } = form;
  const { mutateAsync: editProject } = useEditProject({
    onSuccess: () => {
      openSnackbar('Project updated successfully!', { alert: 'success' });
      onSuccess();
    },
    onError: () => openSnackbar('Project failed to update', { alert: 'error' }),
  });
  const { projectId } = useParams<{ projectId: string }>();
  const {
    data: projectUsers,
    isLoading: isLoadingProjectUsers,
    isError: isErrorProjectUsers,
  } = useProjectUsers(projectId);
  const { mutateAsync: editProjectUsers } = useEditProjectUsers({
    onError: () => openSnackbar('Failed to edit project users', { alert: 'error' }),
  });
  const { mutateAsync: deleteProjectUser } = useDeleteProjectUser({
    onError: (_, { userEmail }) => openSnackbar(`Failed to remove user ${userEmail}`, { alert: 'error' }),
  });

  useEffect(() => {
    if (config && project) {
      const buckets = formatBuckets(config.awsRegionsMap, project.buckets);
      setValue('buckets', buckets, { shouldValidate: true });
    }
  }, [config, project, setValue]);

  useEffect(() => {
    if (projectUsers) {
      const users: ProjectFormValues['users'] = {};
      projectUsers.forEach(
        (user) => (users[user.user] = { roleUUID: user.roleUUID, roleName: user.roleName, email: user.user }),
      );
      setValue('users', users, { shouldValidate: true });
    }
  }, [projectUsers, setValue]);

  if (isLoading) {
    return <CircularProgress aria-label="Loading project" />;
  }

  if (isError) {
    return <Typography variant="h6">Sorry there has been a problem loading your project</Typography>;
  }

  const onSubmit = handleSubmit(async (values) => {
    const editValues: EditProjectData = { projectUUID: project?.UUID || '' };

    if (dirtyFields.projectName) {
      editValues.projectName = values.projectName;
    }

    if (dirtyFields.description) {
      editValues.description = values.description;
    }

    if (dirtyFields.buckets) {
      const buckets: EditProjectData['buckets'] = {};
      for (const bucket of values.buckets) {
        if (!buckets[bucket.region]) {
          buckets[bucket.region] = { buckets: [] };
        }
        buckets[bucket.region].buckets = [...buckets[bucket.region].buckets, bucket.bucket];
      }
      editValues.buckets = buckets;
    }

    if (dirtyFields.reviewPlatforms) {
      editValues.integrations = {
        frameio: { enabled: values.reviewPlatforms.frameio.enabled },
        moxion: { enabled: values.reviewPlatforms.moxion.enabled },
      };
      if (
        dirtyFields.reviewPlatforms?.frameio?.projectName ||
        dirtyFields.reviewPlatforms?.frameio?.team ||
        values.reviewPlatforms?.frameio?.userDefinedToken
      ) {
        try {
          const createProjectData: { teamID?: string; name?: string; token?: string } = {
            teamID: values.reviewPlatforms.frameio.team,
            name: values.reviewPlatforms.frameio.projectName,
            token: values.reviewPlatforms.frameio.token,
          };

          const { data } = await post<{ project: { approvalsFolderID: string; id: string } }>(
            '/integrations/frameio/project',
            createProjectData,
          );

          editValues.integrations = {
            frameio: { enabled: values.reviewPlatforms.frameio.enabled, project: data.project },
            moxion: { enabled: values.reviewPlatforms.moxion.enabled },
          };
        } catch (err) {
          return openSnackbar('There was a problem updating FrameIo details. Please review and try again');
        }
      }
    }

    if (dirtyFields.users && projectUsers) {
      const originalUsers: ProjectFormValues['users'] = {};
      projectUsers.forEach(
        (user) => (originalUsers[user.user] = { roleUUID: user.roleUUID, roleName: user.roleName, email: user.user }),
      );
      const userValues = Object.values(values.users);

      const deletedUsers = projectUsers.filter(
        (orgUser) => !userValues.find((allUsr) => orgUser.user === allUsr.email),
      );
      const deleteUsersPromise = deletedUsers.map(async (user) =>
        deleteProjectUser({ userEmail: user.user, projectUUID: projectId }),
      );
      try {
        await Promise.all(deleteUsersPromise);
        await editProjectUsers({ users: userValues, projectUUID: projectId });
      } catch (e) {
        return; // thrown error is handled in react query
      }
    }

    // check if there are any edit values to change
    if (Object.keys(editValues).length === 1) {
      openSnackbar('Project updated successfully!', { alert: 'success' });
      onSuccess();
      return;
    }
    try {
      await editProject(editValues);
    } catch (e) {} // thrown error is handled in react query
  });

  return (
    <Paper>
      <Form onSubmit={onSubmit}>
        <BasicInfo form={form} />
        <Buckets form={form} setDefaultBuckets={false} />
        <Integrations form={form} updateProject={true} />
        <Box sx={{}}>
          <Typography variant="body1">Users</Typography>
          {isLoadingProjectUsers ? (
            <Loading />
          ) : isErrorProjectUsers ? (
            <Typography>Sorry an error has occured loading the users</Typography>
          ) : (
            <Users form={form} />
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            style={{ background: 'red', color: 'white' }}
            variant="contained"
            disabled={isSubmitting}
            onClick={() => setOpenDeleteDialog(true)}
          >
            Delete project
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={isSubmitting || Object.keys(dirtyFields).length === 0}
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      </Form>
      {openDeleteDialog && project && <DeleteDialog onClose={() => setOpenDeleteDialog(false)} project={project} />}
    </Paper>
  );
};

export default UpdateProject;
