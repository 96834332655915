import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ShareIcon from '@mui/icons-material/LinkOutlined';
import {
  Avatar,
  Divider,
  Typography,
  Tooltip,
  IconButton,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  TextField,
  Box,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import { useProjects } from '../../api/projects';
import { useSnackbar } from '../../contexts/Snackbar';
import { getLocalDateTime } from '../../utils/getLocalDateTime';
import PermissionWrapper from '../../common/PermissionWrapper';
import CreateProjectDialog from './create/CreateProjectDialog';
import Skeleton from './Skeleton';
import { buttonColor, colors, purpleColor, radious } from '../../theme/designtoken';

function Projects() {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const { isLoading, data, isError, hasNextPage, fetchNextPage, isFetchingNextPage } = useProjects(searchTerm);
  const [openDialog, setOpenDialog] = useState(false);
  const { openSnackbar } = useSnackbar();

  const projects = useMemo(() => {
    return data ? data.pages.flatMap((page) => page.projects) : [];
  }, [data]);

  if (isError) {
    openSnackbar('Error loading projects. Please try again', { alert: 'error' });
  }

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} aria-label="Projects page">
      <Box sx={{ width: '100%', display: 'flex', padding: 1 }}>
        <Typography variant="h6">Projects</Typography>
        <TextField
          placeholder="Search..."
          value={searchTerm}
          // onKeyUp={onSearch}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          InputProps={{ endAdornment: <SearchIcon /> }}
          sx={{ paddingLeft: '24px' }}
        />
        <PermissionWrapper requiredPermissions="createProject">
          <Button
            onClick={() => {
              setOpenDialog(true);
            }}
            sx={{ marginLeft: 'auto', marginRight: 0, bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}
            color="primary"
            startIcon={<AddIcon />}
          >
            Create New Project
          </Button>
        </PermissionWrapper>
      </Box>
      {projects.length === 0 && (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
          <Typography>{searchTerm ? 'No projects found' : 'No projects created yet'}</Typography>
        </Box>
      )}
      {projects.length > 0 && (
        <>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 400px))',
              width: '100%',
              gap: 2,
              padding: 1,
            }}
          >
            {projects.map((project) => (
              <Card key={project.UUID} elevation={7} sx={{ borderRadius:  parseInt(`${radious.borderradius1}`), border: '2px solid #B15EFF'}}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="recipe" sx={{ color: '2px solid #B15EFF', bgcolor : `${purpleColor.purple1}`}}>
                      {project.projectName
                        .split(' ')
                        .map((w) => w.charAt(0))
                        .join('')
                        .substring(0, 2)
                        .toUpperCase()}
                    </Avatar>
                  }
                  title={<span style={{ fontWeight: 700 }}>{project.projectName}</span>}
                  subheader={getLocalDateTime(project.dateAddedInMicroSeconds / 1000, {
                    timeStyle: 'medium',
                    dateStyle: 'short',
                  })}
                />
                <Divider variant="middle" />
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{ wordBreak: 'break-word' }}
                    dangerouslySetInnerHTML={{ __html: project.description }}
                  />
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Tooltip title="Copy link to project" arrow>
                    <IconButton
                      aria-label="Copy link"
                      edge="start"
                      onClick={() => {
                        copy(`${window.location.protocol}//${window.location.host}/project/${project.UUID}`);
                        openSnackbar('Project link copied');
                      }}
                    >
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                  <Button
                    color="primary"
                    variant="outlined"
                    sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }}
                    onClick={async () => {
                      history.push(`/project/${project.UUID}`);
                    }}
                  >
                    View Project
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Box>
          {hasNextPage && (
            <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', padding: 2 }}>
              <Button variant="outlined" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
                {isFetchingNextPage ? 'Loading...' : 'Load More'}
              </Button>
            </Box>
          )}
        </>
      )}
      {openDialog && <CreateProjectDialog open={openDialog} onClose={() => setOpenDialog(false)} />}
    </Box>
  );
}

export default Projects;
