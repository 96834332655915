import { Box, Typography, Paper, Avatar, IconButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';
import { UserDetails, useUserDetails } from '../../../../api/user';
import Loading from '../../../../common/Loading';
import { useSnackbar } from '../../../../contexts/Snackbar';
import EditUserForm from './EditUserForm';

function getUserDisplayName(user: UserDetails) {
  if (user.firstName || user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  return user.email;
}

function User() {
  const history = useHistory();
  const { email } = useParams<{ email: string }>();
  const { data: user, isLoading, isError } = useUserDetails(email);
  const { openSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = useState(false);

  if (isLoading) {
    return <Loading aria-label="Loading user" />;
  }

  if (isError) {
    return (
      <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', flexGrow: '1' }}>
        <Typography>Sorry there has been a problem loading this user</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: '1', display: 'flex', gap: 2, flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Avatar>
            <PersonIcon />
          </Avatar>
          <Typography variant="h6">{user && getUserDisplayName(user)}</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {!isEditing && (
            <IconButton onClick={() => setIsEditing(true)} title="Edit user">
              <EditIcon />
            </IconButton>
          )}
          <IconButton onClick={() => history.push('/admin/users')} title="Go back">
            <ArrowBackIcon />
          </IconButton>
        </Box>
      </Box>
      {isEditing ? (
        <Paper sx={{ padding: 2 }}>
          {user && (
            <EditUserForm
              user={user}
              onCancel={() => setIsEditing(false)}
              onSuccess={() => {
                openSnackbar('User updated!', { alert: 'success' });
                setIsEditing(false);
              }}
            />
          )}
        </Paper>
      ) : (
        <Paper sx={{ padding: 2 }}>
          {/* <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <Box>
              <Typography variant="overline">Email</Typography>
              <Typography>{user?.email}</Typography>
            </Box>
            <Box>
              <Typography variant="overline">First Name</Typography>
              <Typography>{user?.firstName}</Typography>
            </Box>
            <Box>
              <Typography variant="overline">Last Name</Typography>
              <Typography>{user?.lastName}</Typography>
            </Box>
            <Box>
              <Typography variant="overline">Global Role</Typography>
              <Typography>{user?.roleName}</Typography>
            </Box>
            <Box>
              <Typography variant="overline">Status</Typography>
              <Typography>{user?.status}</Typography>
            </Box>
          </Box> */}
          <Box sx={{ display: 'grid', gridTemplateColumns: '125px 1fr', rowGap: 1 }}>
            <b>Email</b>
            <p>{user?.email}</p>
            <b>First name</b>
            <p>{user?.firstName}</p>
            <b>Last name</b>
            <p>{user?.lastName}</p>
            <b>Global Role</b>
            <p>{user?.roleName}</p>
            <b>Status</b>
            <p>{user?.status}</p>
          </Box>
        </Paper>
      )}
    </Box>
  );
}

export default User;
