import { useEffect, useRef, useState } from 'react';
import {
  Breadcrumbs,
  CircularProgress,
  MenuItem,
  Typography,
  Chip,
  Select,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UploadIcon from '@mui/icons-material/Upload';
import GridIcon from '@mui/icons-material/GridOn';
import ListIcon from '@mui/icons-material/List';
import HomeIcon from '@mui/icons-material/Home';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MediaPreview from '@nekta-temp/component-library.mediapreview';
import FileSearch from '@nekta-temp/component-library.filesearch';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useS3Bucket, File as FileType, useSignedUrl, useTags, useEditTags, useBuckets } from '../../api/files';
import { useCurrentProject } from '../../api/projects';
import { useSnackbar } from '../../contexts/Snackbar';
import UploadDialog from './upload/UploadDialog';
import ListView from './ListView';
import GridView from './GridView';

const loadingContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  minHeight: '500px',
} as const;

const breadcrumbStyle = { marginTop: '8px', marginBottom: '12px' } as const;
const iconButtonStyle = { maxHeight: '36px' } as const;

type FileBrowserProps = {
  download?: {
    file?: (file: FileType) => Promise<void>;
    folder?: (folderPath: string) => Promise<void>;
  };
  view?: 'grid' | 'list' | 'all';
  multiSelect?: {
    onSelect: (key: string, bucket: string) => void;
    onRemove: (key: string, bucket: string) => void;
    isSelected: (key: string, bucket: string) => boolean;
    isSelectable?: (item: string | FileType) => boolean;
    active?: boolean;
  };
  filterBuckets?: (buckets: string[]) => string[];
};

function FileBrowser({ download, view = 'all', multiSelect, filterBuckets }: FileBrowserProps) {
  const { data: currentProject, isLoading: projectLoading, isFetched: projectFetched } = useCurrentProject(false);
  const [buckets, setBuckets] = useState<string[]>([]);
  const [selectedBucket, setSelectedBucket] = useState('');
  const [searchQuery, setSearchQuery] = useState<string | Record<string, unknown>>('');
  const [prefix, setPrefix] = useState<string[]>([]);
  const [multiSelectActive, setMultiSelectActive] = useState(false);
  const { data, isLoading, isFetching, hasNextPage, fetchNextPage, isFetchingNextPage } = useS3Bucket({
    bucket: selectedBucket,
    prefix: `${prefix.join('/')}/`,
    searchQuery,
  });
  const { data: userBuckets, isLoading: isBucketsLoading } = useBuckets();
  const [selectedFile, setSelectedFile] = useState<FileType>();
  const [openBucketSelect, setOpenBucketSelect] = useState(false);
  const [isListView, setListView] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ 0: string }>();
  const bucketsLoaded = useRef<boolean>(false);
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    if (selectedBucket) {
      const path = `${selectedBucket}/${prefix.join('/')}`;
      if (params[0] !== undefined && path !== params[0]) {
        if (params[0] === '') {
          history.replace(path);
          return;
        }
        if (location.pathname.startsWith('/file-browser')) {
          history.replace(`/file-browser/${path}`);
          return;
        }
        const pathParts = location.pathname.split('file-browser');
        history.replace(`${pathParts[0]}file-browser/${path}`);
      }
    }
  }, [selectedBucket, prefix, params, location.pathname, history]);

  useEffect(() => {
    if (!bucketsLoaded.current && userBuckets && projectFetched) {
      let availableBuckets: string[] = [];
      let defaultBucket = '';

      if (currentProject) {
        availableBuckets = currentProject.projectBuckets;
        defaultBucket = currentProject.buckets.default?.buckets[0] || currentProject.projectBuckets[0];
      }
      if (userBuckets.length > 0 && !currentProject) {
        availableBuckets = userBuckets;
        defaultBucket = userBuckets[0];
      }
      if (filterBuckets) {
        availableBuckets = filterBuckets(availableBuckets);
        if (!buckets.includes(defaultBucket) && availableBuckets[0]) {
          defaultBucket = availableBuckets[0];
        }
      }
      if (params[0] !== undefined && params[0] !== '') {
        const pathParts = params[0].split('/');
        const bucket = pathParts[0];

        if (availableBuckets.includes(bucket)) {
          defaultBucket = bucket;
          const prefixParts = pathParts.slice(1);
          if (prefixParts[0] !== '') {
            setPrefix(pathParts.slice(1));
          }
        }
      }
      setBuckets(availableBuckets);
      setSelectedBucket(defaultBucket);
      bucketsLoaded.current = true;
    }
  }, [buckets, userBuckets, currentProject, filterBuckets, params, projectFetched]);

  if (isBucketsLoading || projectLoading) {
    return (
      <Box sx={loadingContainer}>
        <CircularProgress />
      </Box>
    );
  }

  if (bucketsLoaded.current && buckets.length === 0) {
    return (
      <Box sx={loadingContainer}>
        <Typography>Sorry, it appears you do not have access to any files.</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
      aria-label="File browser"
    >
      <FileSearch
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        bucketName={selectedBucket}
        useTags={useTags}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Breadcrumbs
          sx={{
            fontSize: '0.9rem',
            backgroundColor: 'transparent',
          }}
          maxItems={4}
          aria-label="breadcrumbs"
        >
          <div>
            <Chip
              label={selectedBucket}
              onClick={() => setPrefix([])}
              deleteIcon={<ExpandMoreIcon color="primary" aria-label="Switch bucket" />}
              onDelete={() => setOpenBucketSelect(true)}
              icon={<HomeIcon fontSize="small" color="primary" />}
              sx={breadcrumbStyle}
            />
            <Select
              open={openBucketSelect}
              onClose={() => setOpenBucketSelect(false)}
              onChange={(e) => {
                if (params[0]) {
                  const pathParts = location.pathname.split(selectedBucket);
                  history.replace(`${pathParts[0]}/${e.target.value}`);
                }
                setSelectedBucket(e.target.value);
                setPrefix([]);
                setSearchQuery('');
              }}
              value={selectedBucket}
              variant="standard"
              size="small"
              IconComponent={() => null}
              sx={{
                display: openBucketSelect ? 'inherit' : 'none',
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'center',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'center',
                  horizontal: 'center',
                },
              }}
            >
              {buckets.map((bucket) => (
                <MenuItem key={bucket} value={bucket}>
                  {bucket}
                </MenuItem>
              ))}
            </Select>
          </div>
          {prefix.map((folder, index) => (
            <Chip
              // eslint-disable-next-line react/no-array-index-key
              key={`${folder}-${index}`}
              label={folder}
              onClick={() => prefix.length !== index && setPrefix(prefix.slice(0, index + 1))}
              sx={breadcrumbStyle}
            />
          ))}
        </Breadcrumbs>
        <div>
          <IconButton onClick={() => setOpenUploadDialog(true)} title="Upload" sx={iconButtonStyle}>
            <UploadIcon />
          </IconButton>
          {view === 'all' && (
            <IconButton
              onClick={() => setListView(!isListView)}
              title={isListView ? 'Grid view' : 'List view'}
              sx={iconButtonStyle}
            >
              {isListView ? <GridIcon /> : <ListIcon />}
            </IconButton>
          )}
          {multiSelect && multiSelect.active === undefined && (
            <IconButton
              onClick={() => {
                setListView(true);
                setMultiSelectActive(!multiSelectActive);
              }}
              title="Select files & folders"
              sx={iconButtonStyle}
              size="large"
            >
              <CheckBoxIcon />
            </IconButton>
          )}
        </div>
      </Box>
      {isLoading || (!isFetchingNextPage && isFetching) ? (
        <Box sx={loadingContainer}>
          <CircularProgress aria-label="loading bucket contents" color="primary" />
        </Box>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!data ? (
            <Box sx={loadingContainer}>
              <Typography>No items within this bucket</Typography>
            </Box>
          ) : (
            <Box sx={{ height: hasNextPage ? '83%' : '88%', overflow: 'auto' }}>
              {view === 'list' || isListView || multiSelect?.active ? (
                <ListView
                  data={data}
                  onFolderClick={(folderName) => setPrefix([...prefix, folderName])}
                  onFileClick={setSelectedFile}
                  multiSelect={
                    multiSelect && {
                      ...multiSelect,
                      active: multiSelect.active || multiSelectActive,
                      onSelect: (key) => multiSelect.onSelect(key, selectedBucket),
                      onRemove: (key) => multiSelect.onRemove(key, selectedBucket),
                      isSelected: (key) => multiSelect.isSelected(key, selectedBucket),
                    }
                  }
                />
              ) : (
                <GridView
                  data={data}
                  onFolderClick={(folderName) => setPrefix([...prefix, folderName])}
                  onFileClick={setSelectedFile}
                  download={download}
                />
              )}
            </Box>
          )}
        </>
      )}
      {hasNextPage && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            paddingBottom: '16px',
            height: '52px',
          }}
        >
          {isFetchingNextPage ? (
            <CircularProgress />
          ) : (
            <Button onClick={() => fetchNextPage()} variant="outlined" disabled={isFetchingNextPage}>
              Load More
            </Button>
          )}
        </Box>
      )}
      <MediaPreview
        bucket={selectedBucket}
        filePath={selectedFile?.Key}
        type={selectedFile?.mediaType}
        onClose={() => setSelectedFile(undefined)}
        openSnackbar={openSnackbar}
        queries={{ useSignedUrl }}
        fileMetaData={{ file: selectedFile, useTags, useEditTags, bucketName: selectedBucket }}
      />
      <UploadDialog
        open={openUploadDialog}
        onClose={() => setOpenUploadDialog(false)}
        bucket={selectedBucket}
        path={prefix.join('/')}
      />
    </Box>
  );
}

export default FileBrowser;
