import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { Template, ShutdownPolicy as Policy } from '../../api/deployments';
import SelectRegion from './SelectRegion';
import SelectVpc from './SelectVpc';
import SelectTemplate from './SelectTemplate';
import DeploymentTemplateForm from './DeploymentTemplateForm';
import ShutdownPolicy from './ShutdownPolicy';
import { buttonColor } from '../../theme/designtoken';

type CreateDeployment = {
  open: boolean;
  onSuccess?: () => void;
  onClose?: () => void;
};

export function CreateDeploymentDialog({ open, onSuccess, onClose }: CreateDeployment) {
  const [step, setStep] = useState<number>(1);
  const [region, setRegion] = useState<string>();
  const [vpc, setVpc] = useState<string>();
  const [template, setTemplate] = useState<Template>();
  const [shutdownPolicy, setShutdownPolicy] = useState<Policy>();

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <DialogTitle sx={{ color : `${buttonColor.bgcolor}`}}>Create Deployment</DialogTitle>
      <DialogContent>
        {step === 1 && (
          <SelectRegion
            onSuccess={(selectedRegion) => {
              setRegion(selectedRegion);
              setStep(2);
            }}
          />
        )}
        {step === 2 && region && (
          <SelectVpc
            region={region}
            onSuccess={(selectedVpc) => {
              setVpc(selectedVpc);
              setStep(3);
            }}
            onPrevious={() => {
              setStep(1);
            }}
          />
        )}
        {step === 3 && (
          <SelectTemplate
            onSuccess={(selectedTemplate) => {
              setTemplate(selectedTemplate);
              setStep(selectedTemplate.type === 'dcv' ? 4 : 5);
            }}
            onPrevious={() => {
              setStep(2);
            }}
          />
        )}
        {step === 4 && (
          <ShutdownPolicy
            onSuccess={(policy) => {
              setShutdownPolicy(policy);
              setStep(5);
            }}
            onPrevious={() => {
              setStep(3);
            }}
            shutdownPolicy={shutdownPolicy}
          />
        )}
        {step === 5 && template && region && vpc && (
          <DeploymentTemplateForm
            templateId={template.UUID}
            regionName={region}
            vpcId={vpc}
            shutdownPolicy={shutdownPolicy}
            onSuccess={() => {
              onSuccess && onSuccess();
            }}
            onPrevious={() => {
              setStep(template.type === 'dcv' ? 4 : 3);
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default CreateDeploymentDialog;
