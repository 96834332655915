import { useHistory, useParams } from 'react-router-dom';
import { useDeployment } from '../../../api/deployments';
import Loading from '../../../common/Loading';
import { useSnackbar } from '../../../contexts/Snackbar';
import DeploymentLogs from '../../admin/deployments/DeploymentLogs';

export function WorkstationLogs() {
  const { workstationId } = useParams<{ workstationId: string }>();
  const { openSnackbar } = useSnackbar();
  const history = useHistory();
  const { data: deployment, isLoading } = useDeployment(workstationId, true, {
    onError: (err) => {
      if (err.response?.status === 404) {
        openSnackbar("Sorry this deployment doesn't exist", { alert: 'error' });
        history.push('/admin/deployments');
      }
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return <DeploymentLogs deploymentID={workstationId} title={deployment?.name || ''} />;
}

export default WorkstationLogs;
