import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDeployments, useGroups } from '../../api/deployments';
import { useSnackbar } from '../../contexts/Snackbar';
import DeploymentsTable from './WorkstationTable';
import { buttonColor, colors, sizes } from '../../theme/designtoken';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  groupSelect: {
    minWidth: 350,
  },
  loadMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
    width: '100%',
  },
}));

export const AllWorkstations: FunctionComponent = () => {
  const groups = useGroups();
  const [selectedGroup, setSelectedGroup] = useState('');
  const deployments = useDeployments(selectedGroup);
  const { openSnackbar } = useSnackbar();
  const classes = useStyles();
  const isLoading = groups.isLoading || deployments.isLoading;

  useEffect(() => {
    if (deployments.isError) {
      openSnackbar('Sorry there was problem fetching workstations. Please refresh and try again');
    }
  }, [deployments.isError, openSnackbar]);

  useEffect(() => {
    if (groups.data) {
      setSelectedGroup(groups.data[0]);
    }
  }, [groups.data]);

  const deploymentPages = deployments.data?.pages;
  const data = useMemo(() => {
    return deploymentPages ? deploymentPages.flatMap((page) => page.deployments) : [];
  }, [deploymentPages]);

  if (groups.isError) {
    return <Typography>Sorry there has been a problem fetching your groups. Please reload and try again</Typography>;
  }

  return (
    <div className={classes.container}>
      {isLoading ? (
        <CircularProgress aria-label="Loading workstations" />
      ) : (
        <>
          <FormControl variant="standard" className={classes.groupSelect}>
            <InputLabel htmlFor="groupSelect">Group</InputLabel>
            <Select
              id="groupSelect"
              aria-label="Group"
              value={selectedGroup}
              onChange={(event) => setSelectedGroup(event.target.value)}
            >
              {groups.data?.map((group) => (
                <MenuItem key={group} value={group}>
                  {group}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {data.length ? (
            <DeploymentsTable data={data} accessType="viewer" />
          ) : (
            <Typography>No workstations available for this group</Typography>
          )}
          {deployments.hasNextPage && (
            <div className={classes.loadMoreContainer}>
              <Button
                sx={{ marginInline: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}
                variant="outlined"
                onClick={() => deployments.fetchNextPage()}
                disabled={deployments.isFetchingNextPage}
              >
                {deployments.isFetchingNextPage ? 'Loading...' : 'Load More'}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AllWorkstations;
