import { Box, Typography, CircularProgress } from '@mui/material';
import { useCurrentProject } from '../../api/projects';
import getLocalDateTime from '../../utils/getLocalDateTime';

const BasicInfo = () => {
  const { isLoading, data: project } = useCurrentProject();

  if (isLoading || !project) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '125px 1fr', rowGap: 1 }}>
      <b>Name</b>
      <p>{project.projectName}</p>
      <b>Description</b>
      <Typography sx={{ wordBreak: 'break-word' }}>{project.description}</Typography>
      <b>Created By</b>
      <p>{project.createdBy.userName}</p>
      <b>Created Date</b>
      <p>
        {getLocalDateTime(project.dateAddedInMicroSeconds / 1000, {
          timeStyle: 'medium',
          dateStyle: 'short',
        })}
      </p>
    </Box>
  );
};

export default BasicInfo;
