import { CircularProgress, Typography, Box, CircularProgressProps } from '@mui/material';

export function CircularProgressWithLabel(props: { value: number; isError?: boolean }) {
  const { value, isError } = props;
  let color: CircularProgressProps['color'] = value === 100 ? 'success' : 'secondary';
  let progressValue = value;
  if (isError) {
    color = 'error';
    if (value === 0) {
      progressValue = 100;
    }
  }
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" color={color} value={progressValue} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
