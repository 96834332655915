import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useApi } from '../../contexts/Api';
import { useSnackbar } from '../../contexts/Snackbar';
import { generateRandomString, generateCodeChallenge, getRandomValues, base64URL } from './crypto';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function Login() {
  const history = useHistory();
  const query = useQuery();
  const { openSnackbar } = useSnackbar();
  const { authConfig, logIn } = useApi();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [stay, setStay] = useState(query.get('stay') === 'true');

  const code = query.get('code');
  const error = query.get('error');
  const errorDescription = query.get('error_description');

  async function getAuthUrl() {
    const { authorizationEndpoint, clientID, responseType, scope, code_challenge_method } = authConfig;
    const previousPath = query.get('previousPath');

    const codeVerifier = generateRandomString(128);
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    const stateValue = base64URL(getRandomValues(16));
    const loginState = btoa(JSON.stringify({ stateValue, previousPath }));

    localStorage.setItem('verifier', codeVerifier);
    localStorage.setItem('loginState', loginState);

    return `${authorizationEndpoint}?client_id=${clientID}&state=${loginState}&response_type=${responseType}&scope=${scope}&redirect_uri=${
      window.location.origin + '/login'
    }&code_challenge=${codeChallenge}&code_challenge_method=${code_challenge_method}`;
  }

  async function loginFromCode(loginCode: string) {
    setIsAuthenticating(true);
    let loginState: { previousPath: false | string } = { previousPath: false };
    const encodedState = query.get('state');
    if (encodedState) {
      loginState = JSON.parse(atob(encodedState));
    }
    const storedVerifier = localStorage.getItem('verifier');
    const success = await logIn({ code: loginCode, codeVerifier: storedVerifier || '' });
    if (!success) {
      setIsAuthenticating(false);
      openSnackbar('There was a problem with your login. Please try again');
      setStay(true);
      history.push('/login?stay=true');
      return;
    }
    if (loginState.previousPath) {
      history.push(loginState.previousPath);
      return;
    }
    history.push('/');
  }

  async function redirectToAuthUrl() {
    const url = await getAuthUrl();
    window.location.replace(url);
  }

  if (code && !isAuthenticating && !error) {
    loginFromCode(code);
  }

  if (!stay && !code && !error) {
    redirectToAuthUrl();
  }

  if (error && !stay) {
    setStay(true);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center', padding: 4 }}>
      <Typography variant="h2">
        Cloud{' '}
        <Typography variant="h2" component="b" color="secondary.main">
          Edit
        </Typography>
      </Typography>
      {isAuthenticating || !stay ? (
        <CircularProgress color="primary" />
      ) : (
        <>
          {error && (
            <>
              <Typography>Sorry an error has occured</Typography>
              {errorDescription && <Typography>{errorDescription}</Typography>}
            </>
          )}
          <Button variant="contained" onClick={() => redirectToAuthUrl()} sx={{ maxWidth: 100 }}>
            Login
          </Button>
        </>
      )}
    </Box>
  );
}

export default Login;
