import { Fragment } from 'react';
import { useHistory } from 'react-router';
import {
  Avatar,
  Chip,
  Divider,
  Typography,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Box,
} from '@mui/material';
import MarkAsCompletedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RegionIcon from '@mui/icons-material/LanguageSharp';
import { useCurrentProject } from '../../../api/projects';
import { useCompleteJob, useJobs, Jobs as Job } from '../../../api/job';
import { useConfig } from '../../../api/config';
import getLocalDateTime from '../../../utils/getLocalDateTime';
import { useSnackbar } from '../../../contexts/Snackbar';
import Skeleton from './Skeleton';
import { buttonColor, colors, purpleColor, radious, sizes } from '../../../theme/designtoken';

function EditJobs() {
  const { openSnackbar } = useSnackbar();
  const { data: config } = useConfig();
  const { data: project, isLoading: isLoadingProject } = useCurrentProject();
  const {
    data: jobs,
    isLoading: isLoadingJobs,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useJobs(project?.UUID);
  const { mutate: completeJob } = useCompleteJob({
    onSuccess: (_, vars) => openSnackbar(`Job marked as ${vars.isComplete ? 'completed' : 'uncomplete'}!`),
  });
  const history = useHistory();

  const onJobItemClick = (jobId: string) => {
    history.push(`/project/${project?.UUID}/job/${jobId}/overview`);
  };

  const getJobRegionName = (job: Job) => {
    return config?.awsRegionsMap[job.regionName] || job.regionName;
  };

  if (isLoadingJobs || isLoadingProject || !project) {
    return <Skeleton />;
  }

  return (
    <>
      <List sx={{ padding: 0 }}>
        {jobs?.pages.map((page) =>
          page.jobs.map((job, index) => {
            return (
              <Fragment key={job.UUID}>
                <ListItem button disableRipple onClick={() => onJobItemClick(job.UUID)}>
                  <ListItemAvatar>
                    <Avatar sx={{ color: '2px solid #B15EFF', bgcolor : `${purpleColor.purple1}`}}>
                      {job.jobName
                        .split(' ')
                        .map((w) => w.charAt(0))
                        .join('')
                        .substring(0, 2)
                        .toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        {job.jobName}
                        <Chip
                          label={getJobRegionName(job)}
                          size="small"
                          color="primary"
                          variant="outlined"
                          style={{ marginLeft: 10, marginTop: -4 }}
                          icon={<RegionIcon />}
                        />
                      </>
                    }
                    secondary={getLocalDateTime(job.dateAddedInMicroSeconds / 1000, {
                      timeStyle: 'medium',
                      dateStyle: 'short',
                    })}
                  />
                  <ListItemSecondaryAction>
                    {!job.isComplete ? (
                      <Tooltip title="Mark as completed">
                        <IconButton
                          aria-label="Mark as completed"
                          edge="end"
                          onClick={() =>
                            completeJob({ isComplete: true, jobUUID: job.UUID, projectUUID: project.UUID })
                          }
                        >
                          <MarkAsCompletedIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Unmark as Completed">
                        <IconButton
                          aria-label="Unmark as Completed"
                          edge="end"
                          onClick={() =>
                            completeJob({ isComplete: false, jobUUID: job.UUID, projectUUID: project.UUID })
                          }
                        >
                          <DoneAllIcon style={{ color: '#4caf50' }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                {index < page.jobs.length - 1 ? <Divider /> : null}
              </Fragment>
            );
          }),
        )}

        {jobs?.pages[0]?.jobs.length === 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: 1 }}>
            <Typography variant="body1">No jobs created</Typography>
          </Box>
        )}
      </List>
      {hasNextPage && (
        <Box sx={{ display: 'flex', justifyContent: 'center', height: '80px' }}>
          <Button variant="outlined" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
            {isFetchingNextPage ? 'Loading...' : 'Load More'}
          </Button>
        </Box>
      )}
    </>
  );
}

export default EditJobs;
