/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, FunctionComponent, useContext, useReducer } from 'react';
import { useQueryClient } from 'react-query';
import Upload, { UploadProgress, FileWithPath } from '../routes/fileBrowser/upload/upload';
import { useApi } from './Api';

type StartUploadProps = { bucket: string; path: string };
export type ProgressUpdate = {
  isError: boolean;
  loaded?: number;
  file: File;
  index: number;
  errorMessage?: string;
  isComplete?: boolean;
};
type UploadContextValue = {
  isUploading: boolean;
  filesToUpload: FileWithPath[];
  fileQueue: QueueFile[];
  addFiles: (files: File[]) => void;
  removeFile: (index: number) => void;
  clearUploads: () => void;
  // cancelUploads: () => void;
  startUpload: (props: StartUploadProps) => void;
};

const defaultValue: UploadContextValue = {
  isUploading: false,
  filesToUpload: [],
  fileQueue: [],
  addFiles: () => {},
  removeFile: () => {},
  clearUploads: () => {},
  // cancelUploads: () => {},
  startUpload: () => {},
};

const UploadContext = createContext<UploadContextValue>(defaultValue);

type QueueFile = { file: FileWithPath; status?: ProgressUpdate };

type UploadState = {
  isUploading: boolean;
  filesToUpload: FileWithPath[];
  fileQueue: QueueFile[];
};

type Action =
  | { type: 'addFiles'; files: File[] }
  | { type: 'clearUploads' }
  | { type: 'removeFile'; index: number }
  | { type: 'start' }
  | { type: 'finish' }
  | { type: 'progressUpdate'; update: UploadProgress };

function uploadReducer(state: UploadState, action: Action): UploadState {
  switch (action.type) {
    case 'addFiles':
      return { ...state, filesToUpload: [...state.filesToUpload, ...action.files] };
    case 'clearUploads':
      return { ...state, fileQueue: [] };
    case 'removeFile':
      const newFiles = [...state.filesToUpload];
      newFiles.splice(action.index, 1);
      return { ...state, filesToUpload: newFiles };
    case 'start':
      const newQueue = state.filesToUpload.map((file) => ({ file }));
      return {
        ...state,
        isUploading: true,
        filesToUpload: [],
        fileQueue: [...state.fileQueue, ...newQueue],
      };
    case 'progressUpdate':
      const index = state.fileQueue.findIndex((file) => file.file.name === action.update.file.name);
      if (index !== -1) {
        const newQueue = [...state.fileQueue];
        newQueue[index] = { ...newQueue[index], status: { ...(newQueue[index].status || {}), ...action.update } };
        return { ...state, fileQueue: newQueue };
      }
      return state;
    case 'finish':
      return { ...state, isUploading: false };
    default:
      return state;
  }
}

const UploadProvider: FunctionComponent = ({ children }) => {
  const { get } = useApi();
  const queryClient = useQueryClient();
  const [state, dispatch] = useReducer(uploadReducer, {
    isUploading: false,
    filesToUpload: [],
    fileQueue: [],
  });

  if (state.isUploading) {
    // Prompt users before they refresh / navigate away
    window.onbeforeunload = () => true;
  } else {
    window.onbeforeunload = () => undefined;
  }

  function addFiles(files: File[]) {
    dispatch({ type: 'addFiles', files });
  }

  function clearUploads() {
    dispatch({ type: 'clearUploads' });
  }

  function removeFile(index: number) {
    dispatch({ type: 'removeFile', index });
  }
  // function cancelUploads() {
  //   state.fileQueue.forEach((item) => {
  //     if (item.status?.abortController && !item.status.isComplete) {
  //       item.status.abortController.abort();
  //     }
  //   });
  // }

  async function startUpload({ bucket, path }: StartUploadProps) {
    const upload = new Upload(get);

    upload.onProgress = (update) => {
      dispatch({ type: 'progressUpdate', update });
      if (update.isComplete) {
        queryClient.invalidateQueries([bucket, `${path}/`, ''], {});
      }
    };
    const files = [...state.filesToUpload];
    dispatch({ type: 'start' });
    await upload.startUpload({ bucket, path, files });
    dispatch({ type: 'finish' });
  }

  const value = { ...state, addFiles, clearUploads, removeFile, startUpload };
  return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>;
};

// use this context sparingly it will cause many rerenders when uploading
function useUpload() {
  const context = useContext(UploadContext);
  if (context === undefined) {
    throw new Error('useUpload must be used within a UploadProvider');
  }
  return context;
}

export { UploadProvider, useUpload, UploadContext };
