import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  FormControl,
  TextField,
  Button,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDeleteJob, Job } from '../../../api/job';
import { useSnackbar } from '../../../contexts/Snackbar';

const DeleteDialog = ({ onClose, job }: { onClose: () => void; job: Job }) => {
  const [jobNameIsValid, setJobNameIsValid] = React.useState(false);
  const [jobNameVerification, setJobNameVerification] = React.useState('');
  const { openSnackbar } = useSnackbar();
  const history = useHistory();
  const { mutate, isLoading } = useDeleteJob({
    onSuccess: (_, vars) => {
      openSnackbar('Job deleted successfully!');
      history.push(`/project/${vars.projectUUID}`);
    },
    onError: (_) => {
      openSnackbar('Failed to delete job. Please try again!');
    },
  });

  return (
    <Dialog open={true} aria-label="delete-job-dialog">
      <DialogTitle>Delete Job ?</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <DialogContentText>This action is permanent and cannot be undone.</DialogContentText>
        <DialogContentText>
          If you want to proceed, please enter <b>{job.jobName}</b> in input field below.
        </DialogContentText>
        <FormControl variant="filled">
          <TextField
            variant="filled"
            label="Job Name"
            placeholder={job.jobName}
            name="jobName"
            value={jobNameVerification}
            onChange={(e) => {
              const newValue = e.target.value;
              setJobNameVerification(e.target.value);

              if (newValue === job.jobName) {
                setJobNameIsValid(true);
              } else {
                setJobNameIsValid(false);
              }
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading} variant="contained">
          Cancel
        </Button>
        {!isLoading ? (
          <Button
            disabled={!jobNameIsValid}
            onClick={() => mutate({ jobUUID: job.UUID, projectUUID: job.job_projectUUID })}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        ) : (
          <div style={{ width: '150px', textAlign: 'center' }}>
            <CircularProgress size={24} style={{ color: 'red' }} />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
