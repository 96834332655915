import { FunctionComponent, useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useHasPermission } from '../../utils/permissions';
import AllWorkstations from './AllWorkstations';
import MyWorkstations from './MyWorkstations';

export const Workstations: FunctionComponent = () => {
  const [openTab, setOpenTab] = useState(0);
  const hasPermission = useHasPermission(['deployment_user:getGroup']);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: '100%',
        width: '100%',
      }}
    >
      <Tabs value={openTab} onChange={(_, newTab) => setOpenTab(newTab)} centered variant="fullWidth">
        <Tab label="My Workstations" />
        {hasPermission && <Tab label="All Workstations" />}
      </Tabs>
      <Box sx={{ width: '100%', height: '100%' }}>
        {openTab === 0 && <MyWorkstations />}
        {openTab === 1 && <AllWorkstations />}
      </Box>
    </Box>
  );
};

export default Workstations;
