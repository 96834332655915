import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, CircularProgress, MenuItem, TextField, Typography } from '@mui/material';
import { useRegions } from '../../api/deployments';
import { Form, ActionContainer } from './styledComponents';
import { buttonColor, colors } from '../../theme/designtoken';

type SelectRegionForm = {
  region: string;
};

type SelectRegionComponent = {
  onSuccess: (region: string) => void;
};

export function SelectRegion({ onSuccess }: SelectRegionComponent) {
  const { data: regions, isLoading, isError } = useRegions();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SelectRegionForm>();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError || !regions) {
    return <Typography variant="body1">Sorry there has been a problem loading available regions</Typography>;
  }

  const onSubmit: SubmitHandler<SelectRegionForm> = async (values) => {
    onSuccess(values.region);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="region"
        control={control}
        rules={{ required: 'Please select a region' }}
        defaultValue=""
        render={({ field }) => (
          <TextField
            id="region"
            select
            variant="outlined"
            label="Region"
            margin="dense"
            {...field}
            error={!!errors?.region}
            helperText={errors?.region?.message}
            size="small"
          >
            {Object.entries(regions || {}).map(([key, value]) => (
              <MenuItem key={value} value={value}>
                {key}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <ActionContainer>
        <Button type="submit" variant="outlined" sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}>
          Next
        </Button>
      </ActionContainer>
    </Form>
  );
}

export default SelectRegion;
