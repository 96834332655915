import { Badge, IconButton } from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';
import { useEffect, useState } from 'react';
import { useUpload } from '../../../contexts/Upload';
import UploadDialog from './UploadDialog';

export function UploadBadge({ alwaysVisisble = false }: { alwaysVisisble?: boolean }) {
  const [openDialog, setOpenDialog] = useState(false);
  const { fileQueue } = useUpload();

  useEffect(() => {
    if (openDialog && !fileQueue.length) {
      setOpenDialog(false);
    }
  }, [fileQueue, openDialog]);

  if (!alwaysVisisble && !fileQueue.length) {
    return null;
  }

  const numberInProgress = fileQueue.filter((p) => p.status && !p.status.isComplete).length;
  const hasError = fileQueue.filter((p) => p.status && p.status.isError).length > 0;

  const badgeColor = hasError ? 'error' : 'secondary';

  return (
    <>
      <IconButton onClick={() => setOpenDialog(true)} color="inherit" title="Upload progress" size="large">
        <Badge color={badgeColor} overlap={undefined} badgeContent={numberInProgress}>
          <UploadIcon />
        </Badge>
      </IconButton>
      <UploadDialog open={openDialog} onClose={() => setOpenDialog(false)} />
    </>
  );
}

export default UploadBadge;
