import { useState } from 'react';
import { Box, Paper, Typography, IconButton, Button, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import { startCase } from 'lodash';
import { Job } from '../../../api/job';
import { getLocalDateTime } from '../../../utils/getLocalDateTime';
import DeleteDialog from './DeleteDialog';
import UpdateFieldDialog from './UpdateFieldDialog';

const labelStyle = { fontWeight: 500, alignSelf: 'center' } as const;
const editRowStyle = { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } as const;

function Overview({ job }: { job: Job }) {
  const [showDeleteJobDialog, setShowDeleteJobDialog] = useState(false);
  const [editField, setEditField] = useState<'name' | 'description' | 'estimate'>();

  return (
    <Paper sx={{ width: '100%', padding: 2 }}>
      <Box sx={{ display: 'grid', gridTemplateColumns: '125px 1fr', rowGap: 1.5 }}>
        <Typography sx={labelStyle}>UUID</Typography>
        <p>{job.UUID}</p>
        <Divider sx={{ gridColumn: '1/3' }} />

        <Typography sx={labelStyle}>Name</Typography>
        <Box sx={editRowStyle}>
          <p>{job.jobName}</p>{' '}
          <IconButton onClick={() => setEditField('name')} aria-label="edit name">
            <EditIcon color="primary" />
          </IconButton>
        </Box>
        <Divider sx={{ gridColumn: '1/3' }} />

        <Typography sx={labelStyle}>Description</Typography>
        <Box sx={editRowStyle}>
          <Typography sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>{job.description}</Typography>
          <IconButton onClick={() => setEditField('description')} aria-label="edit description">
            <EditIcon color="primary" />
          </IconButton>
        </Box>
        <Divider sx={{ gridColumn: '1/3' }} />

        <Typography sx={labelStyle}>Estimate</Typography>
        <Box sx={editRowStyle}>
          {job.estimateInDays ? `${job.estimateInDays} day(s)` : 'N/A'}
          <IconButton onClick={() => setEditField('estimate')} aria-label="edit estimate">
            <EditIcon color="primary" />
          </IconButton>
        </Box>
        <Divider sx={{ gridColumn: '1/3' }} />

        <Typography sx={labelStyle}>Created By</Typography>
        <p>{job.createdBy.userName}</p>
        <Divider sx={{ gridColumn: '1/3' }} />

        <Typography sx={labelStyle}>Created Date</Typography>
        <p>
          {getLocalDateTime(job.dateAddedInMicroSeconds / 1000, {
            timeStyle: 'medium',
            dateStyle: 'short',
          })}
        </p>
        <Divider sx={{ gridColumn: '1/3' }} />

        <Typography sx={labelStyle}>Status</Typography>
        <p>{startCase(job.status)}</p>
      </Box>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      <Button color="error" variant="contained" onClick={() => setShowDeleteJobDialog(true)}>
        Delete Job
      </Button>
      {showDeleteJobDialog && <DeleteDialog job={job} onClose={() => setShowDeleteJobDialog(false)} />}
      {editField && (
        <UpdateFieldDialog
          job={job}
          field={editField}
          onSuccess={() => setEditField(undefined)}
          onClose={() => setEditField(undefined)}
        />
      )}
    </Paper>
  );
}

export default Overview;
