import { useQuery, UseQueryResult } from 'react-query';
import { useApi, Post, Get } from '../contexts/Api';

type FrameIoTeam = {
  name: string;
  id: string;
};

async function fetchFrameIoTeams(post: Post, token?: string) {
  const { data } = await post<{ teams: FrameIoTeam[] }>('/integrations/frameio/teams', { token });
  return data.teams;
}

export function useFrameIoTeams(token?: string): UseQueryResult<FrameIoTeam[] | undefined> {
  const { post } = useApi();
  return useQuery(['frameIoTeams', token], () => fetchFrameIoTeams(post, token), {
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 2,
  });
}

type PlatformDefaults = {
  team: {
    name: string;
    id: string;
  };
  project: {
    name: string;
  };
};

async function fetchIntegrationDefaults(get: Get, platform: string) {
  const { data } = await get<PlatformDefaults>('/integrations/default', { params: { platform } });
  return data;
}

export function useIntegrationDefaults(platform: string): UseQueryResult<PlatformDefaults | undefined> {
  const { get } = useApi();
  return useQuery(['integrationDefaults', platform], () => fetchIntegrationDefaults(get, platform), {
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 2,
  });
}

type ApprovalPlatforms = {
  frameio: { enabled: boolean };
  moxion: { enabled: boolean };
};

async function fetchApprovalPlatforms(get: Get) {
  const { data } = await get<ApprovalPlatforms>('/integrations/approval/platforms');
  return data;
}

export function useApprovalPlatforms(): UseQueryResult<ApprovalPlatforms> {
  const { get } = useApi();
  return useQuery('approvalPlatforms', () => fetchApprovalPlatforms(get), {
    cacheTime: 1000 * 60 * 10,
    staleTime: 1000 * 60 * 2,
  });
}
