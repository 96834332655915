import axios from 'axios';
import { Deployment, useEditShutdownPolicy } from '../../../api/deployments';
import ShutdownPolicy from '../../../common/createDeployment/ShutdownPolicy';
import { useSnackbar } from '../../../contexts/Snackbar';

type Props = {
  onSuccess: () => void;
  onCancel: () => void;
  deployment: Deployment;
};

function EditShutdownPolicy({ deployment, onSuccess, onCancel }: Props) {
  const { openSnackbar } = useSnackbar();
  const { mutate } = useEditShutdownPolicy({
    onSuccess: () => {
      openSnackbar('Deployment edited successfully', { alert: 'success' });
      onSuccess();
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        const errData = error?.response?.data;

        if (errData?.message) {
          return openSnackbar(errData.message, { alert: 'error' });
        }
      }
      openSnackbar('Sorry there was a problem editing this shutdown policy', { alert: 'error' });
    },
  });

  return (
    <ShutdownPolicy
      onPrevious={onCancel}
      onSuccess={(values) => mutate({ UUID: deployment.UUID, values })}
      previousButtonText="Cancel"
      successButtonText="Save"
      shutdownPolicy={deployment.shutdownPolicy}
    />
  );
}

export default EditShutdownPolicy;
