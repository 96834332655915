import React from 'react';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import MaterialSkeleton from '@mui/material/Skeleton';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirectangularion: 'column',
  },
  header: {
    display: 'flex',
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  headerTitle: {
    marginTop: theme.spacing(0),
  },
  projectsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0),
  },
  projectCard: {
    flexBasis: '32.333333%',
    marginBottom: theme.spacing(2),
  },

  headerActions: {
    marginLeft: 'auto',
  },
  card: {
    marginBottom: theme.spacing(2),
    width: '32%',
    position: 'relative',
  },
  cardActions: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
  },
  cardContent: {
    paddingBottom: 68,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },

  primaryFontColor: {
    color: theme.palette.primary.main,
  },
  search: {
    position: 'relative',
    borderRadius: 0,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    fontSize: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '30ch',
  },
  loadMoreContainer: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    height: 150,
    margin: theme.spacing(1, 0),
    width: '100%',
  },
}));

const Skeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          <Typography variant="h6">Projects</Typography>
        </div>
        <MaterialSkeleton
          animation="wave"
          variant="rectangular"
          height={33}
          width={300}
          style={{ marginLeft: 24 }}
          aria-label="Loading projects"
        />
      </div>
      <div className={classes.projectsContainer}>
        <div className={classes.projectCard}>
          <MaterialSkeleton animation="wave" variant="rectangular" height={150} />
        </div>
        <div className={classes.projectCard}>
          <MaterialSkeleton animation="wave" variant="rectangular" height={150} />
        </div>
        <div className={classes.projectCard}>
          <MaterialSkeleton animation="wave" variant="rectangular" height={150} />
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
