import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Typography, CircularProgress, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WorkstationTable from '../../workstations/WorkstationTable';
import { useAdminDeployments } from '../../../api/deployments';
import CreateDeploymentDialog from '../../../common/createDeployment/CreateDeploymentDialog';
import { buttonColor, colors, sizes } from '../../../theme/designtoken';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    height: '100%',
    width: '100%',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  loadMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
    width: '100%',
  },
}));

export const Deployments: FunctionComponent = () => {
  const classes = useStyles();
  const [shouldPollDeployments, setShouldPollDeployments] = useState(false);
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage, isError } = useAdminDeployments({
    refetchInterval: shouldPollDeployments ? 30000 : false,
    refetchIntervalInBackground: true,
  });
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const deploymentPages = data?.pages;
  const deployments = useMemo(() => {
    return deploymentPages ? deploymentPages.flatMap((page) => page.deployments) : [];
  }, [deploymentPages]);

  useEffect(() => {
    if (deployments.length) {
      let updatingState = false;
      deployments.forEach((deployment) => {
        if (deployment.powerState === 'pending' || deployment.status === 'deploying') {
          updatingState = true;
          return;
        }
      });
      setShouldPollDeployments(updatingState);
    }
  }, [deployments]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Typography variant="h6">Deployments</Typography>
        <Button variant="outlined" onClick={() => setOpenCreateDialog(true)} sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}>
          Create Deployment
        </Button>
      </div>
      {isLoading ? (
        <CircularProgress aria-label="Loading deployments" />
      ) : (
        <>
          {deployments.length ? (
            <WorkstationTable data={deployments} accessType="viewer" showAdditional={true} />
          ) : (
            <Typography>No deployments available</Typography>
          )}
        </>
      )}
      {isError && (
        <Typography>Sorry there has been a problem fetching deployments. Please reload and try again</Typography>
      )}
      {hasNextPage && (
        <div className={classes.loadMoreContainer}>
          <Button variant="outlined" onClick={() => fetchNextPage()} disabled={isFetchingNextPage} sx={{ marginInline: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}>
            {isFetchingNextPage ? 'Loading...' : 'Load More'}
          </Button>
        </div>
      )}
      {openCreateDialog && (
        <CreateDeploymentDialog
          open={openCreateDialog}
          onClose={() => setOpenCreateDialog(false)}
          onSuccess={() => setOpenCreateDialog(false)}
        />
      )}
    </div>
  );
};

export default Deployments;
