import { Controller, get, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Button, TextField, styled, Checkbox, FormControlLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { isEqual } from 'lodash';
import { Field } from '../api/deployments';
import { buttonColor, colors } from '../theme/designtoken';

export const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  height: '100%',
});

export const ActionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: 8,
});

type FieldValue = string | unknown[] | { value: string; label: string } | number | boolean;
export type TemplateFieldForm = {
  [key: string]: FieldValue;
};

export type TemplateFormProps = {
  fields: Field[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (values: TemplateFieldForm, ignoreWarnings: boolean) => any | Promise<any>;
  submitButtonText?: string;
  manualErrors?: { field: string; message: string; previousValue: FieldValue; warning?: boolean }[];
  onCancel?: () => void;
  cancelButtonText?: string;
  disabled?: boolean;
};

export function TemplateForm({
  onSubmit,
  fields,
  submitButtonText = 'Submit',
  manualErrors,
  onCancel,
  cancelButtonText = 'Cancel',
  disabled = false,
}: TemplateFormProps) {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<TemplateFieldForm>();
  const [ignoreWarnings, setIgnoreWarnings] = useState(false);
  const [warnings, setWarnings] = useState<string[]>([]);

  useEffect(() => {
    if (manualErrors) {
      const newWarnings: string[] = [];
      manualErrors.forEach((error) => {
        setError(error.field, {
          type: 'manual',
          message: error.message,
        });
        if (error.warning) {
          newWarnings.push(error.field);
        }
      });
      setWarnings(newWarnings);
    }
  }, [manualErrors, setError]);

  function getFieldDefaultValue(field: Field) {
    if (field.defaultValue === 0 || field.defaultValue) {
      return field.defaultValue;
    }
    if (field.type === 'select') {
      if (field.multiSelect) {
        return [];
      }
      return field.valueOptions[0];
    }
    return '';
  }

  function validateManualErrors(field: string, value: FieldValue) {
    const error = manualErrors?.find((error) => error.field === field);
    if (error) {
      if (error.warning && ignoreWarnings) {
        return true;
      }
      // if the value has changed since the error has been set
      if (!isEqual(error.previousValue, value)) {
        return true;
      }
      return error.message;
    }
    return true;
  }

  return (
    <Form onSubmit={handleSubmit((values) => onSubmit(values, ignoreWarnings))}>
      {fields.map((field) => (
        <Controller
          key={field.name}
          name={field.name}
          control={control}
          defaultValue={getFieldDefaultValue(field)}
          rules={{ validate: (val) => validateManualErrors(field.name, val), ...field.validation }}
          render={({ field: fieldRender }) => {
            if (field.type === 'select') {
              const { onChange, value, ...props } = fieldRender;
              return (
                <Autocomplete<{ value: string; label: string }, boolean>
                  id={field.name}
                  options={field.valueOptions}
                  multiple={!!field.multiSelect}
                  onChange={(e, data) => {
                    onChange(data);
                  }}
                  value={value as { value: string; label: string }}
                  {...props}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={field.label}
                      helperText={get(errors, field.name)?.message || field.helperText}
                      error={!!get(errors, field.name)}
                      variant="outlined"
                      margin="dense"
                      disabled={disabled}
                    />
                  )}
                />
              );
            }
            return (
              <TextField
                label={field.label}
                id={field.name}
                variant="outlined"
                margin="dense"
                {...fieldRender}
                error={!!get(errors, field.name)}
                helperText={get(errors, field.name)?.message || field.helperText}
                type={field.type}
                disabled={disabled}
              />
            );
          }}
        />
      ))}
      {warnings.length > 0 && (
        <FormControlLabel
          control={
            <Checkbox checked={ignoreWarnings} onChange={() => setIgnoreWarnings(!ignoreWarnings)} color="default" />
          }
          label="Ignore warnings"
          sx={{ alignSelf: 'flex-end' }}
        />
      )}
      <ActionContainer>
        {onCancel && (
          <Button onClick={onCancel} variant="outlined" disabled={isSubmitting || disabled} sx={{ bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}>
            {cancelButtonText}
          </Button>
        )}
        <Button type="submit" variant="outlined" disabled={isSubmitting || disabled}  sx={{ bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}>
          {submitButtonText}
        </Button>
      </ActionContainer>
    </Form>
  );
}

export default TemplateForm;
