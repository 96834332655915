import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query';
import { useApi, Get, Put, Post, Delete } from '../contexts/Api';

export type User = {
  UUID: string;
  allusers: number;
  avatarURL: string;
  dateAddedInMicroSeconds: number;
  email: string;
  firstName: string;
  lastName: string;
  orgUUID: 'default';
  status: 'active' | 'deactivated' | 'pending';
  type: 'user';
  roleUUID: string;
  permissions: string[];
};

async function fetchUser(get: Get) {
  const { data } = await get<{ user: User }>('/me');
  return data.user;
}

export function useUser() {
  const { get } = useApi();
  return useQuery('user', () => fetchUser(get), {
    cacheTime: 1000 * 60 * 60 * 24, // 24hours
    staleTime: 1000 * 60 * 60 * 24, // 24hours
    keepPreviousData: true,
  });
}

export type Users = { email: string; roleUUID: string | null; firstName: string; lastName: string }[];

async function getUsers(get: Get) {
  const { data } = await get<{ users: Users }>('/users/search');
  return data.users;
}

export function useUsers() {
  const { get } = useApi();
  return useQuery('users', () => getUsers(get));
}

export type UserDetails = {
  UUID: string;
  allusers: 1;
  avatarURL: string;
  dateAddedInMicroSeconds: number;
  email: string;
  firstName: string;
  lastName: string;
  orgUUID: string;
  roleName?: string;
  roleUUID?: string;
  status: 'active' | 'deactivated' | 'pending';
  type: 'user';
};
async function getUserDetails(get: Get, email: string) {
  const { data } = await get<{ user: UserDetails }>(`/user/${email}`);
  return data.user;
}

export function useUserDetails(email: string) {
  const { get } = useApi();
  return useQuery(['userDetails', email], () => getUserDetails(get, email));
}

export type EditUserParams = {
  email: string;
  body: {
    firstName?: string;
    lastName?: string;
    status?: UserDetails['status'];
    avatarURL?: string;
    roleUUID?: string;
  };
};
type EditUserResponse = { message?: string };

async function editUserDetails(put: Put, { email, body }: EditUserParams) {
  const { data } = await put<EditUserResponse>(`/user/${email}`, body);
  return data;
}

export function useEditUserDetails(
  options?: UseMutationOptions<EditUserResponse, AxiosError<{ message?: string }>, EditUserParams, unknown>,
) {
  const { put } = useApi();
  const queryClient = useQueryClient();
  return useMutation((params) => editUserDetails(put, params), {
    ...options,
    onSuccess: (data, variables, context) => {
      const roles = queryClient.getQueryData<Role[]>(['roles', 'global']);
      queryClient.setQueryData<UserDetails | undefined>(['userDetails', variables.email], (oldData) => {
        if (oldData) {
          if (variables.body.roleUUID && roles) {
            const role = roles.find((role) => role.UUID === variables.body.roleUUID);
            if (role) {
              oldData.roleName = role.roleName;
            }
          }
          return { ...oldData, ...variables.body };
        }
        /* istanbul ignore next */
        return undefined;
      });
      queryClient.invalidateQueries('users');
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

export type Role = { UUID: string; roleName: string; roleDescription: string };
type RoleParams = { roleType?: string };
async function getRoles(get: Get, params?: RoleParams) {
  const { data } = await get<{ roles: Role[] }>('/roles/search', { params });
  return data.roles;
}

export function useRoles(params?: RoleParams) {
  const { get } = useApi();
  return useQuery(['roles', params?.roleType], () => getRoles(get, params));
}

export type RoleDetails = {
  UUID: string;
  dateAddedInMicroSeconds: number;
  role: 1;
  permissions: string[];
  roleName: string;
  roleDescription: string;
  roleType: 'global' | 'project';
  type: 'role';
};
async function getRole(get: Get, uuid: string) {
  const { data } = await get<{ role: RoleDetails }>(`/roles/${uuid}`);
  return data.role;
}
export function useRole(uuid: string) {
  const { get } = useApi();
  return useQuery(['role', uuid], () => getRole(get, uuid));
}

export type EditRoleParams = {
  roleUUID: string;
  body: {
    roleName?: string;
    roleDescription?: string;
    permissions?: string[];
  };
};

async function editRole(put: Put, params: EditRoleParams) {
  const { data } = await put<RoleDetails>(`/roles/${params.roleUUID}`, params.body);
  return data;
}

export function useEditRole(
  options?: UseMutationOptions<RoleDetails, AxiosError<{ message?: string }>, EditRoleParams, unknown>,
) {
  const { put } = useApi();
  const queryClient = useQueryClient();
  return useMutation((params) => editRole(put, params), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(['role', data.UUID], () => data);
      queryClient.setQueryData<Role[]>(['roles', data.roleType], (oldData) => {
        if (oldData) {
          const index = oldData.findIndex((role) => role.UUID === variables.roleUUID);
          if (variables.body.roleName) {
            oldData[index].roleName = variables.body.roleName;
          }
          if (variables.body.roleDescription) {
            oldData[index].roleDescription = variables.body.roleDescription;
          }
          return oldData;
        }
        /* istanbul ignore next */
        return [];
      });
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

type DeleteRoleParams = { UUID: string };
export type DeleteRoleResponse = { affectedUsers: Users; role: RoleDetails };
async function deleteRole(del: Delete, params: DeleteRoleParams) {
  const { data } = await del<DeleteRoleResponse>(`/roles/${params.UUID}`);
  return data;
}

export function useDeleteRole(
  options?: UseMutationOptions<DeleteRoleResponse, AxiosError<{ message?: string }>, DeleteRoleParams, unknown>,
) {
  const { delete: del } = useApi();
  const queryClient = useQueryClient();
  return useMutation((params) => deleteRole(del, params), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<Role[]>(['roles', data.role.roleType], (oldData) => {
        if (oldData) {
          const index = oldData.findIndex((role) => role.UUID === variables.UUID);
          if (index > -1) {
            oldData.splice(index, 1);
          }
          return oldData;
        }
        /* istanbul ignore next */
        return [];
      });
      if (data.role.roleType === 'global') {
        queryClient.setQueryData<Users>('users', (oldData) => {
          if (oldData) {
            data.affectedUsers.forEach((user) => {
              const index = oldData.findIndex((oldUser) => oldUser.email === user.email);
              if (index > -1) {
                oldData[index].roleUUID = null;
              }
            });
            return oldData;
          }
          /* istanbul ignore next */
          return [];
        });
      }
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

type UpdateUserRoleParams = {
  roleUUID: string | null;
  user: string;
};

type UpdateUserRoleResponse = { message: string };

async function updateUserRole(put: Put, params: UpdateUserRoleParams) {
  const { data } = await put<UpdateUserRoleResponse>(`/user/${params.user}/role`, { roleUUID: params.roleUUID });
  return data;
}

export function useUpdateUserRole(
  options?: UseMutationOptions<UpdateUserRoleResponse, unknown, UpdateUserRoleParams, unknown>,
) {
  const { put } = useApi();
  const queryClient = useQueryClient();
  return useMutation((params) => updateUserRole(put, params), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<Users>('users', (oldData) => {
        if (oldData) {
          const updatedUsers = [...oldData];
          const userIndex = updatedUsers.findIndex((user) => user.email === variables.user);
          updatedUsers[userIndex].roleUUID = variables.roleUUID;
          return updatedUsers;
        }
        /* istanbul ignore next */
        return [];
      });
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

export type Permission = {
  scope: 'project' | 'global';
  value: string;
  desc: string;
  category: string;
  permission: string;
};
export type PermissionApiResult = { permissions: { scope: Permission['scope']; value: string; desc: string }[] };
type PermissionFilters = { scope?: Permission['scope'] };

async function getPermissions(get: Get, { scope }: PermissionFilters) {
  const { data } = await get<PermissionApiResult>('/permissions');
  if (scope) {
    data.permissions = data.permissions.filter((perm) => perm.scope === scope);
  }
  const permissionCategories: { name: string; permissions: Permission[] }[] = [];
  const categoryIndexes: Record<string, number> = {};
  const permissions = data.permissions.map((permission) => {
    const updated = {
      ...permission,
      category: permission.value.split(':')[0],
      permission: permission.value.split(':')[1],
    };

    if (categoryIndexes[updated.category] || categoryIndexes[updated.category] === 0) {
      permissionCategories[categoryIndexes[updated.category]].permissions.push(updated);
    } else {
      categoryIndexes[updated.category] =
        permissionCategories.push({ name: updated.category, permissions: [updated] }) - 1;
    }
    return updated;
  });

  return { permissions, permissionCategories };
}

export function usePermissions({ scope }: PermissionFilters = {}) {
  const { get } = useApi();
  return useQuery(['permissions', scope], () => getPermissions(get, { scope }));
}

export type CreateRoleBody = {
  name: string;
  description: string;
  roleType: 'global' | 'project';
  permissions: string[];
};

type CreateRoleResponse = { message: 'success'; UUID: string };

async function createUserRole(post: Post, body: CreateRoleBody) {
  const { data } = await post<CreateRoleResponse>('/role', body);
  return data;
}

export function useCreateUserRole(options?: UseMutationOptions<CreateRoleResponse, unknown, CreateRoleBody, unknown>) {
  const { post } = useApi();
  const queryClient = useQueryClient();
  return useMutation((params) => createUserRole(post, params), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<Role[]>(['roles', variables.roleType], (oldData) => {
        if (oldData) {
          return [...oldData, { UUID: data.UUID, roleName: variables.name, roleDescription: variables.description }];
        }
        return [];
      });
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}
