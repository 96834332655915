import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@mui/lab';
import {
  Paper,
  Typography,
  Chip,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Box,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useJobEvents } from '../../../api/job';
import { getLocalDateTime } from '../../../utils/getLocalDateTime';
import CircularProgressWithLabel from '../../../common/CircularProgressWithLabel';

const tableCellStyle = {
  border: 'none',
  padding: '8px 16px',
  width: '200px',
  wordWrap: 'break-word',
} as const;

const EventsTimeline = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const { isLoading, data, isError, hasNextPage, fetchNextPage, isFetchingNextPage } = useJobEvents(jobId);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
        <Typography>Sorry an error has occured. Please go back and try again.</Typography>
      </Box>
    );
  }

  if (!data || data.pages[0]?.status.length < 1) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150 }}>
        No events available
      </Box>
    );
  }

  return (
    <>
      <Timeline position="right" sx={{ padding: 0 }} aria-label="timeline">
        {data.pages
          .flatMap((page) => page.status)
          .map((event) => (
            <TimelineItem key={event.UUID}>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                  {getLocalDateTime(event.dateAddedInMicroSeconds / 1000, {
                    timeStyle: 'short',
                    dateStyle: 'short',
                  })}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" variant="outlined" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ padding: '10px 16px 6px 0px', minWidth: '50%' }}>
                <Paper elevation={0} sx={{ borderRadius: 0, padding: '10px 10px', overflow: 'hidden' }}>
                  <Table sx={{ marginTop: 1 }} aria-label="caption table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" sx={tableCellStyle}>
                          <b>Event</b>
                        </TableCell>
                        <TableCell align="left" sx={{ ...tableCellStyle, width: '85%' }}>
                          {event.slug !== 'progress' ? event.message : event.statusDetails}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row" sx={tableCellStyle}>
                          <b>User</b>
                        </TableCell>
                        <TableCell align="left" sx={{ ...tableCellStyle, width: '85%' }}>
                          {event.userName}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row" sx={tableCellStyle}>
                          <b>Tags</b>
                        </TableCell>
                        <TableCell align="left" sx={{ ...tableCellStyle, width: '85%' }}>
                          <Chip
                            size="small"
                            label={event.eventType}
                            sx={{ textTransform: 'capitalize' }}
                            variant="outlined"
                          />
                        </TableCell>
                      </TableRow>
                      {event.versionName && (
                        <TableRow>
                          <TableCell component="th" scope="row" sx={tableCellStyle}>
                            <b>Version Name</b>
                          </TableCell>
                          <TableCell align="left" sx={{ ...tableCellStyle, width: '85%' }}>
                            {event.versionName}
                          </TableCell>
                        </TableRow>
                      )}
                      {event.slug === 'progress' && (
                        <TableRow>
                          <TableCell component="th" scope="row" sx={tableCellStyle}>
                            <b>Progress</b>
                          </TableCell>
                          <TableCell align="left" sx={{ ...tableCellStyle, width: '85%' }}>
                            <CircularProgressWithLabel value={event.progress || 0} />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>

      {hasNextPage && (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: '80px' }}>
          {!isFetchingNextPage ? (
            <Button variant="outlined" onClick={() => fetchNextPage()}>
              Load More
            </Button>
          ) : (
            <CircularProgress color="primary" />
          )}
        </Box>
      )}
    </>
  );
};

export default EventsTimeline;
