import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, FormControl, TextField, Button } from '@mui/material';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { ProjectDetails, useDeleteProject } from '../../api/projects';
import { useSnackbar } from '../../contexts/Snackbar';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    padding: theme.spacing(3),
  },
  formControl: {
    width: '100%',
  },
}));

const DeleteDialog = ({ onClose, project }: { onClose: () => void; project: ProjectDetails }) => {
  const classes = useStyles();
  const [projectNameIsValid, setProjectNameIsValid] = useState(false);
  const [projectNameVerification, setProjectNameVerification] = useState('');
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const { mutate: deleteProject, isLoading } = useDeleteProject({
    onSuccess: () => {
      openSnackbar('Project deleted successfully');
      history.push('/projects');
    },
    onError: () => openSnackbar('Failed to delete the project'),
  });

  return (
    <Dialog open={true} aria-label="delete-project-dialog">
      <DialogTitle>Delete Project ?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This action is permanent and cannot be undone.
          <br />
          <br />
          If you want to proceed, please enter <b>{project.projectName}</b> in input field below.
        </DialogContentText>
        <FormControl variant="filled" className={classes.formControl}>
          <TextField
            variant="filled"
            label="Project Name"
            placeholder={project.projectName}
            name="projectName"
            value={projectNameVerification}
            onChange={(e) => {
              const newValue = e.target.value;
              setProjectNameVerification(e.target.value);

              if (newValue === project.projectName) {
                setProjectNameIsValid(true);
              } else {
                setProjectNameIsValid(false);
              }
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} color="primary" disabled={isLoading}>
          Cancel
        </Button>
        {!isLoading ? (
          <Button
            disabled={!projectNameIsValid}
            color="error"
            variant="contained"
            onClick={() => deleteProject({ projectUUID: project.UUID })}
          >
            Delete
          </Button>
        ) : (
          <div style={{ width: '150px', textAlign: 'center' }}>
            <CircularProgress size={24} style={{ color: 'red' }} />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
