import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FunctionComponent } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useCurrentProject } from '../../../api/projects';
import { useConfig } from '../../../api/config';
import { useCreateJob } from '../../../api/job';
import { useSnackbar } from '../../../contexts/Snackbar';

type CreateJobProps = { open: boolean; onClose: () => void };

type CreateJobForm = {
  jobName: string;
  description: string;
  regionName: string;
  estimateInDays: number;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    height: '100%',
    width: '100%',
    paddingTop: 8,
  },
}));

export const CreateJobDialog: FunctionComponent<CreateJobProps> = ({ open, onClose }) => {
  const { data: config, isLoading: isConfigLoading } = useConfig();
  const { data: project, isLoading: isProjectLoading } = useCurrentProject();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateJobForm>();
  const { openSnackbar } = useSnackbar();
  const { mutate: createJob } = useCreateJob({
    onSuccess: () => {
      openSnackbar('Job created successfully!');
      onClose();
    },
    onError: () => openSnackbar('An error occured while trying to create new job. Please try again'),
  });
  const classes = useStyles();
  const projectRegions = project?.projectRegions.map((region) => {
    const label = config?.awsRegionsMap[region];
    return { value: region, label };
  });

  const onSubmit: SubmitHandler<CreateJobForm> = async (values) => {
    if (project) {
      createJob({ ...values, projectUUID: project.UUID });
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" data-testid="create-job-dialog">
      <DialogTitle>New Edit Job</DialogTitle>
      {isConfigLoading || isProjectLoading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={classes.container}>
            <Controller
              name="jobName"
              control={control}
              defaultValue=""
              rules={{
                required: 'Please enter a job name',
                maxLength: {
                  message: 'Must be less than 40 characters',
                  value: 40,
                },
                pattern: {
                  message: 'Must only be alphanumberic characters',
                  value: RegExp('^([a-zA-Z0-9 _-]+)$'),
                },
              }}
              render={({ field }) => (
                <TextField
                  id="jobName"
                  label="Job name"
                  variant="outlined"
                  error={!!errors?.jobName}
                  helperText={errors?.jobName?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              defaultValue=""
              rules={{
                required: 'Please enter a job description',
                maxLength: {
                  message: 'Description must be less than 256 characters',
                  value: 256,
                },
              }}
              render={({ field }) => (
                <TextField
                  id="description"
                  label="Job description"
                  variant="outlined"
                  error={!!errors?.description}
                  helperText={errors?.description?.message}
                  multiline
                  rows={3}
                  {...field}
                />
              )}
            />
            <Controller
              name="regionName"
              control={control}
              rules={{ required: 'Please select a region' }}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  id="regionName"
                  select
                  variant="outlined"
                  label="Region"
                  {...field}
                  error={!!errors?.regionName}
                  helperText={errors?.regionName?.message}
                >
                  {projectRegions?.map((region) => (
                    <MenuItem key={region.value} value={region.value}>
                      {region.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="estimateInDays"
              control={control}
              defaultValue={1}
              rules={{
                required: 'Please enter an estimate',
              }}
              render={({ field }) => (
                <TextField
                  id="estimateInDays"
                  label="Estimate (days)"
                  variant="outlined"
                  error={!!errors?.estimateInDays}
                  helperText={errors?.estimateInDays?.message}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">day(s)</InputAdornment>,
                  }}
                  {...field}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Create job
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export default CreateJobDialog;
