/* eslint-disable jsx-a11y/media-has-caption */
import { FunctionComponent, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Tab, Tabs, Button, Paper, CircularProgress, Typography, Box } from '@mui/material';
import { VideoModal } from '@nekta-temp/component-library.mediapreview';
import Comments from '@nekta-temp/component-library.versioncomments';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/styles';
import {
  useVersions,
  useComments,
  useCommentAnnotation,
  useCompleteComment,
  Version,
  useVersionFPS,
} from '../../../api/versions';
import { useSnackbar } from '../../../contexts/Snackbar';
import { useApi } from '../../../contexts/Api';
import { VersionTable } from './VersionTable';

const useStyles = makeStyles(() => ({
  tabPanel: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

function VersionTab({
  type,
  setVersionForComments,
  setVideoSource,
}: {
  type: 'approval' | 'submission';
  setVersionForComments: (version: Version) => void;
  setVideoSource: (url: string) => void;
}) {
  const { jobId } = useParams<{ projectId: string; jobId: string }>();
  const { isLoading, data, fetchNextPage, isFetchingNextPage, hasNextPage, isError } = useVersions({
    type,
    jobId,
  });
  const { openSnackbar } = useSnackbar();
  const { get } = useApi();

  const preview = async (versionUUID: string) => {
    try {
      const { data: previewData } = await get<{ message?: string; url: string }>(`/version/${versionUUID}/view`);
      return setVideoSource(previewData.url);
    } catch (err) {
      return openSnackbar('An error occured fetching the preview. Please try again!');
    }
  };

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex' }}>
        <>
          <Box sx={{ width: '100%' }}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Paper elevation={0} sx={{ width: '100%' }}>
                  <VersionTable
                    versions={data?.pages.flatMap((page) => page.versions) || []}
                    openComments={(version) => {
                      setVersionForComments(version);
                    }}
                    openPreview={preview}
                    type={type}
                  />
                </Paper>
                {data?.pages[0].versions.length === 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '40px',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography color="secondary">{`No ${type} versions created yet`}</Typography>
                  </Box>
                )}
                {isError && (
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '40px',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography color="secondary">
                      An error occured while trying to fetch versions. Please try reloading.
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
        </>
      </Box>
      {hasNextPage && (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: '80px' }}>
          {!isFetchingNextPage ? (
            <Button variant="outlined" onClick={() => fetchNextPage()}>
              Load More
            </Button>
          ) : (
            <CircularProgress color="secondary" />
          )}
        </Box>
      )}
    </>
  );
}

function Versions() {
  const { openSnackbar } = useSnackbar();
  const theme = useTheme();

  const [videoSource, setVideoSource] = useState<string>();
  const [selectedTab, setSelectedTab] = useState<'approval' | 'submission'>('approval');
  const [versionForComments, setVersionForComments] = useState<Version>();

  const { data: versionFPS } = useVersionFPS({
    versionID: versionForComments?.UUID || '',
    enabled: versionForComments && !versionForComments.fps ? true : false,
  });
  useEffect(() => {
    if (versionForComments && !versionForComments.fps && versionFPS) {
      setVersionForComments({ ...versionForComments, fps: versionFPS });
    }
  }, [versionForComments, versionFPS]);

  const TabPanel: FunctionComponent<{ value: string; index: number }> = (props) => {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== selectedTab}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        className={classes.tabPanel}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
      >
        {value === selectedTab && children}
      </div>
    );
  };

  return (
    <Box sx={{ width: '100% ' }}>
      <Tabs
        value={selectedTab}
        onChange={(_, newValue) => setSelectedTab(newValue)}
        indicatorColor="secondary"
        TabIndicatorProps={{ children: <span /> }}
        sx={{
          backgroundColor: theme.palette.background.paper,
          indicator: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            '& > span': {
              backgroundColor: theme.palette.secondary.main,
            },
          },
        }}
      >
        <Tab
          label="Approvals"
          value="approval"
          sx={{
            textTransform: 'none',
            opacity: 0.4,
            fontWeight: theme.typography.fontWeightBold,
            '&:focus': {
              opacity: 1,
            },
          }}
        />
        <Tab
          label="Submissions"
          value="submission"
          sx={{
            textTransform: 'none',
            opacity: 0.4,
            fontWeight: theme.typography.fontWeightBold,
            '&:focus': {
              opacity: 1,
            },
          }}
        />
      </Tabs>

      <TabPanel value="approval" index={0}>
        <VersionTab type="approval" setVersionForComments={setVersionForComments} setVideoSource={setVideoSource} />
      </TabPanel>
      <TabPanel value="submission" index={0}>
        <VersionTab type="submission" setVersionForComments={setVersionForComments} setVideoSource={setVideoSource} />
      </TabPanel>

      {versionForComments && (
        <Comments
          version={versionForComments}
          onClose={() => setVersionForComments(undefined)}
          openSnackbar={openSnackbar}
          queries={{ useComments, useCommentAnnotation, useCompleteComment }}
        />
      )}
      {videoSource && <VideoModal mediaSource={videoSource} onModalClose={() => setVideoSource(undefined)} />}
    </Box>
  );
}

export default Versions;
