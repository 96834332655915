import { add } from 'date-fns';

function getCookieValue(name: string) {
  const cookies = document.cookie.split('; ');
  const cookie = cookies.find((cook) => cook.startsWith(`${name}=`));
  if (cookie) {
    return cookie.split('=')[1];
  }
  return undefined;
}

export const getAccessToken = () => getCookieValue('accessToken');
export const getRefreshToken = () => getCookieValue('refreshToken');
export const getIdToken = () => getCookieValue('idToken');

export function clearTokenCookies() {
  document.cookie = 'refreshToken=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie = 'idToken=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie = 'accessToken=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT';
}

export const setAccessToken = (accessToken: string, expiresSeconds: number) =>
  (document.cookie = `accessToken=${accessToken};path=/;max-age=${expiresSeconds}`);
const nowPlusMonth = add(Date.now(), { months: 1 });
export const setRefreshToken = (refreshToken: string, expires: Date = nowPlusMonth) =>
  (document.cookie = `refreshToken=${refreshToken};path=/;expires=${expires}`);

export const setIdToken = (idToken: string, expires: Date = nowPlusMonth) =>
  (document.cookie = `idToken=${idToken};path=/;expires=${expires}`);
