import { Box, Button, MenuItem, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { EditUserParams, useEditUserDetails, UserDetails, useRoles } from '../../../../api/user';
import Loading from '../../../../common/Loading';
import { useSnackbar } from '../../../../contexts/Snackbar';

type Props = {
  user: UserDetails;
  onSuccess?: () => void;
  onCancel?: () => void;
};

const userStatuses = ['active', 'deactivated', 'pending'];

type EditUserFormBody = EditUserParams['body'];

function EditUserForm({ user, onSuccess, onCancel }: Props) {
  const { control, handleSubmit } = useForm<EditUserFormBody>({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      status: user.status,
      roleUUID: user.roleUUID || '',
    },
  });
  const { data: roles, isLoading } = useRoles({ roleType: 'global' });
  const { openSnackbar } = useSnackbar();
  const { mutate, isLoading: isMutating } = useEditUserDetails({
    onSuccess,
    onError: (error) => openSnackbar(error.response?.data.message || 'Sorry an error has occured', { alert: 'error' }),
  });

  const submitHandler = handleSubmit((values) => {
    mutate({ body: values, email: user.email });
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <form onSubmit={submitHandler}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Controller
          name="firstName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField label="First name" {...field} error={!!fieldState.error} disabled={isMutating} />
          )}
          rules={{ minLength: 2 }}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField label="Last name" {...field} error={!!fieldState.error} disabled={isMutating} />
          )}
          rules={{ minLength: 2 }}
        />
        <Controller
          name="roleUUID"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              label="Global role"
              {...field}
              error={!!fieldState.error}
              select
              disabled={isMutating || !roles || roles?.length === 0}
            >
              {roles?.map((role) => (
                <MenuItem key={role.UUID} value={role.UUID}>
                  {role.roleName}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="status"
          control={control}
          render={({ field, fieldState }) => (
            <TextField label="Status" {...field} error={!!fieldState.error} select disabled={isMutating}>
              {userStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Box sx={{ marginLeft: 'auto', display: 'flex', gap: 2 }}>
          {onCancel && (
            <Button onClick={onCancel} disabled={isMutating}>
              Cancel
            </Button>
          )}
          <Button type="submit" variant="contained" color="primary" disabled={isMutating}>
            Save
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default EditUserForm;
