import { Typography, Paper, Divider, List, ListSubheader, Button, ListItem, ListItemText, Box } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRunTranscodeJob, useTranscodeJob, useTranscodeJobFiles } from '../../../api/transcode';
import getLocalDateTime from '../../../utils/getLocalDateTime';
import { useSnackbar } from '../../../contexts/Snackbar';
import Loading from '../../../common/Loading';
import TranscodeJobFile from './TranscodeJobFile';
import { buttonColor, colors, purpleColor, radious } from '../../../theme/designtoken';

export const TranscodeJob: FunctionComponent = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const { openSnackbar } = useSnackbar();
  const [refetchInterval, setRefetchInterval] = useState<false | number>(false);
  const { data: job, isLoading: isJobLoading, isError: isJobError } = useTranscodeJob({ jobId });
  const {
    data: files,
    isLoading: isFilesLoading,
    isError: isFilesError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useTranscodeJobFiles({ jobId, refetchInterval });
  const { mutate: transcodeJob, isLoading: startingTranscode } = useRunTranscodeJob({
    onSuccess: () => {
      openSnackbar('Transcode job started successfully');
      setRefetchInterval(15000);
    },
    onError: () => openSnackbar('Failed to start transcode job'),
  });

  if (isJobLoading || isFilesLoading) {
    return <Loading aria-label="loading transcode job" />;
  }

  if (!job || isJobError) {
    return (
      <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h6">Sorry there seems to be a problem fetching this job</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">Transcode job</Typography>
        <Button variant="outlined" onClick={() => transcodeJob(jobId)} disabled={startingTranscode} sx={{ bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }}>
          Run all files
        </Button>
      </Box>
      <Paper elevation={0} sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6" sx={{ paddingBottom: 1 }}>
            {job.transcodeJobName}
          </Typography>
          <Box sx={{ display: 'grid', gridTemplateColumns: '0.2fr 1fr', gap: 1 }}>
            <Typography>User</Typography>
            <Typography>{job.createdBy.userName}</Typography>
            <Typography>Created</Typography>
            <Typography>
              {getLocalDateTime(job.dateAddedInMicroSeconds / 1000, {
                timeStyle: 'medium',
                dateStyle: 'short',
              })}
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              Dimensions
            </Typography>
            <Typography>
              {job.width} x {job.height}
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              LUT file
            </Typography>
            <Typography>{job.lut?.s3Key || 'N/A'}</Typography>
          </Box>
        </Box>
        <Divider />
        <List
          subheader={
            <ListSubheader>
              <Typography variant="h6">
                {isFilesError ? 'Sorry there was a problem loading the jobs files' : 'Files'}
              </Typography>
            </ListSubheader>
          }
        >
          {files?.pages.map((page) =>
            page.files.map((file) => <TranscodeJobFile file={file} jobId={jobId} key={file.UUID} />),
          )}
          {hasNextPage && (
            <ListItem button alignItems="center" onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
              <ListItemText primaryTypographyProps={{ align: 'center' }}>
                {isFetchingNextPage ? 'Loading...' : 'Load More'}
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Paper>
    </Box>
  );
};

export default TranscodeJob;
