import { Snackbar, IconButton, AlertColor, Alert, SnackbarOrigin } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createContext, useContext, useState, useMemo, FunctionComponent } from 'react';

type Options = { alert?: AlertColor; anchorOrigin?: SnackbarOrigin };

const defaultValue = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openSnackbar: (_message: string, options?: Options) => {},
};

const SnackbarContext = createContext(defaultValue);

const SnackbarProvider: FunctionComponent = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [options, setOptions] = useState<Options>();

  function closeSnackbar() {
    setMessage('');
    setOpen(false);
    setOptions(undefined);
  }

  const value = useMemo(
    () => ({
      ...defaultValue,
      openSnackbar: (text: string, options?: Options) => {
        setMessage(text);
        setOpen(true);
        setOptions(options);
      },
    }),
    [setMessage, setOpen],
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {options?.alert ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={() => closeSnackbar()}
          anchorOrigin={options?.anchorOrigin || { vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={options.alert} elevation={6} variant="filled" onClose={() => closeSnackbar()}>
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          message={message}
          key={message}
          autoHideDuration={6000}
          onClose={() => closeSnackbar()}
          anchorOrigin={options?.anchorOrigin || { vertical: 'bottom', horizontal: 'center' }}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar()}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      )}
    </SnackbarContext.Provider>
  );
};

function useSnackbar() {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
}

export { SnackbarProvider, useSnackbar, SnackbarContext };
