import { Box, Button, List, ListItem, ListItemText, Paper, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from 'react';
import { useRoles, Role } from '../../../api/user';
import { buttonColor, colors } from '../../../theme/designtoken';
import Loading from '../../../common/Loading';
import PermissionWrapper from '../../../common/PermissionWrapper';
import CreateRoleDialog from './CreateRoleDialog';
import EditUsersRoles from './EditUsersRole';
import EditRoleDialog from './EditRoleDialog';


function Roles() {
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [editRole, setEditRole] = useState<Role>();
  const { data: globalRoles, isLoading: isLoadingGlobalRoles } = useRoles({ roleType: 'global' });
  const { data: projectRoles, isLoading: isLoadingProjectRoles } = useRoles({ roleType: 'project' });

  if (isLoadingGlobalRoles || isLoadingProjectRoles) {
    return <Loading />;
  }

  return (
    <Box sx={{ flexGrow: '1', display: 'flex', gap: 2, flexDirection: 'column', maxHeight: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">Roles</Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <PermissionWrapper requiredPermissions="createRole">
            <Button variant="outlined" onClick={() => setOpenRoleDialog(true)} sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}>
              Create Role
            </Button>
          </PermissionWrapper>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', gap: 2, maxHeight: '50%' }}>
        <Paper sx={{ padding: 2, flex: 1 }} aria-label="Global roles">
          <Typography variant="h6" sx={{ pb: 1 , color: '#7202C6'}}>
            Global roles
          </Typography>
          <List dense sx={{ ml: 0, maxHeight: '30vh', overflow: 'auto' }}>
            {globalRoles?.map((role) => (
              <ListItem
                key={role.UUID}
                disablePadding
                secondaryAction={
                  <PermissionWrapper requiredPermissions="updateRole">
                    <IconButton title={`Edit ${role.roleName}`} onClick={() => setEditRole(role)} size="small">
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </PermissionWrapper>
                }
              >
                <ListItemText primary={role.roleName} secondary={role.roleDescription} />
              </ListItem>
            ))}
          </List>
        </Paper>
        <Paper sx={{ padding: 2, flex: 1 }} aria-label="Project roles">
          <Typography variant="h6" sx={{ pb: 1, color: '#7202C6' }}>
            Project roles
          </Typography>
          <List dense sx={{ ml: 0, maxHeight: '30vh', overflow: 'auto' }}>
            {projectRoles?.map((role) => (
              <ListItem
                key={role.UUID}
                disablePadding
                secondaryAction={
                  <PermissionWrapper requiredPermissions="updateRole">
                    <IconButton title={`Edit ${role.roleName}`} onClick={() => setEditRole(role)} size="small">
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </PermissionWrapper>
                }
              >
                <ListItemText primary={role.roleName} secondary={role.roleDescription} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>
      <PermissionWrapper requiredPermissions="updateUserRoles">
        <Paper sx={{ padding: 2, flex: 1 }}>
          <Typography variant="h6" sx={{ pb: 1, color: '#7202C6' }}>
            Assign global roles
          </Typography>
          <EditUsersRoles />
        </Paper>
      </PermissionWrapper>
      <CreateRoleDialog open={openRoleDialog} onClose={() => setOpenRoleDialog(false)} />
      {editRole && (
        <EditRoleDialog
          role={editRole}
          open={!!editRole}
          onClose={() => setEditRole(undefined)}
          onSuccess={() => setEditRole(undefined)}
        />
      )}
    </Box>
  );
}

export default Roles;
