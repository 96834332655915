import { useHasPermission, Permission } from '../utils/permissions';

type PermissionWrapperProps = { requiredPermissions: Permission; children: React.ReactNode; projectID?: string };

export function PermissionWrapper({ requiredPermissions, children, projectID }: PermissionWrapperProps) {
  const hasPermission = useHasPermission(requiredPermissions, projectID);

  if (hasPermission) {
    return <>{children}</>;
  }

  return null;
}

export default PermissionWrapper;
