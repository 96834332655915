import { FunctionComponent } from 'react';
import { TextField } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ProjectFormValues } from '../CreateProjectDialog';

type BasicInfoProps = {
  form: UseFormReturn<ProjectFormValues>;
};

export const BasicInfo: FunctionComponent<BasicInfoProps> = ({ form }) => {
  const {
    control,
    formState: { errors },
  } = form;
  return (
    <>
      <Controller
        name="projectName"
        control={control}
        defaultValue=""
        rules={{
          required: 'Project name is required',
          maxLength: { message: 'Project name must be less than 40 characters', value: 40 },
          pattern: {
            message: 'Must only be alphanumberic characters',
            value: RegExp('^[a-zA-Z0-9][a-zA-Z0-9-_ ]*[a-zA-Z0-9]$'),
          },
        }}
        render={({ field }) => (
          <TextField
            id="projectName"
            label="Project name"
            variant="outlined"
            error={!!errors?.projectName}
            helperText={errors?.projectName?.message}
            {...field}
            autoFocus
            fullWidth
            margin="dense"
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        defaultValue=""
        rules={{
          required: 'Project description is required',
          maxLength: { message: 'Project description must be less than 40 characters', value: 256 },
        }}
        render={({ field }) => (
          <TextField
            id="description"
            label="Project description"
            variant="outlined"
            error={!!errors?.description}
            helperText={errors?.description?.message}
            {...field}
            multiline
            fullWidth
            rows={3}
            margin="dense"
          />
        )}
      />
    </>
  );
};

export default BasicInfo;
