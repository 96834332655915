import { useState } from 'react';
import {
  Avatar,
  Button,
  Stack,
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from '@mui/material';
import BackArrowIcon from '@mui/icons-material/ArrowBackOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/AddOutlined';
import { useParams } from 'react-router-dom';
import { useCurrentProject } from '../../api/projects';
import FileBrowser from '../fileBrowser/FileBrowser';
import PermissionWrapper from '../../common/PermissionWrapper';
import EditJobs from './editJobs/EditJobs';
import CreateEditJob from './editJobs/CreateJobDialog';
import Skeleton from './Skeleton';
import UpdateProject from './UpdateProject';
import BasicInfo from './BasicInfo';
import { purpleColor, radious} from '../../theme/designtoken';

const Project = () => {
  const { isLoading, data: project } = useCurrentProject();
  const { projectId } = useParams<{ projectId: string }>();
  const [editView, setEditView] = useState(false);
  const [openCreateJob, setOpenCreateJob] = useState(false);

  if (isLoading || !project) {
    return <Skeleton />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, marginBottom: 2, marginTop: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar sx={{ color: '2px solid #B15EFF', bgcolor : `${purpleColor.purple1}`, marginTop: '3px'}}>
            {project.projectName
              .split(' ')
              .map((w) => w.charAt(0))
              .join('')
              .substring(0, 2)
              .toUpperCase()}
          </Avatar>
          <Typography variant="h6">{project.projectName}</Typography>
        </Box>
        {editView ? (
          <Button
            variant="outlined"
            onClick={() => {
              setEditView(false);
            }}
            startIcon={<BackArrowIcon />}
            sx={{ maxHeight: '35px', alignSelf: 'center' }}
          >
            Go Back
          </Button>
        ) : (
          <Box sx={{ display: 'flex', gap: 1, maxHeight: '35px', alignSelf: 'center' }}>
            <PermissionWrapper requiredPermissions="createEditJob" projectID={projectId}>
              <IconButton title="Create edit job" onClick={() => setOpenCreateJob(true)} color="primary">
                <AddIcon />
              </IconButton>
            </PermissionWrapper>
            <PermissionWrapper requiredPermissions="updateProject" projectID={projectId}>
              <IconButton title="Project settings" onClick={() => setEditView(true)} color="primary">
                <SettingsIcon />
              </IconButton>
            </PermissionWrapper>
          </Box>
        )}
      </Box>

      {editView ? (
        <UpdateProject onSuccess={() => setEditView(false)} />
      ) : (
        <Stack spacing={2}>
          <Accordion defaultExpanded disableGutters sx={{ borderRadius:  parseInt(`${radious.borderradius1}`), border: '2px solid #B15EFF'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Basic Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <BasicInfo />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded disableGutters sx={{ borderRadius:  parseInt(`${radious.borderradius1}`), border: '2px solid #B15EFF'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Edit Jobs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <EditJobs />
            </AccordionDetails>
          </Accordion>
          <Accordion disableGutters sx={{ '& .MuiAccordion-region': { height: '100%' }, borderRadius:  parseInt(`${radious.borderradius1}`), border: '2px solid #B15EFF'}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                '& .Mui-expanded': { margin: 0 },
                '& .MuiAccordionSummary-root': { '& .Mui-expanded': { minHeight: '45px' } },
              }}
            >
              <Typography variant="h6">File Browser</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ minHeight: '50vh', height: '100%' }}>
              <FileBrowser />
            </AccordionDetails>
          </Accordion>
        </Stack>
      )}
      <CreateEditJob open={openCreateJob} onClose={() => setOpenCreateJob(false)} />
    </>
  );
};

export default Project;
