import { FunctionComponent, useEffect, useState } from 'react';
import { Controller, UseFormReturn, get } from 'react-hook-form';
import { TextField, Chip, Autocomplete } from '@mui/material';
import { ProjectFormValues } from '../CreateProjectDialog';
import { useConfig, Config } from '../../../../api/config';

export function formatBuckets(regions: Config['awsRegionsMap'], buckets: Config['s3Config']['mediaBuckets']) {
  const formattedBuckets: ProjectFormValues['buckets'] = [];
  Object.keys(buckets).forEach((region) => {
    if (region !== 'default') {
      const regionLabel = regions[region];
      buckets[region].buckets.forEach((bucket) => {
        formattedBuckets.push({ region, regionLabel, bucket });
      });
    }
  });
  return formattedBuckets;
}

type BucketProps = {
  form: UseFormReturn<ProjectFormValues>;
  setDefaultBuckets?: boolean;
};

export const Buckets: FunctionComponent<BucketProps> = ({ form, setDefaultBuckets = true }) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = form;
  const { data: config } = useConfig();
  const [buckets, setBuckets] = useState<ProjectFormValues['buckets']>([]);

  useEffect(() => {
    if (config && buckets.length === 0) {
      const formattedBuckets = formatBuckets(config.awsRegionsMap, config.s3Config.mediaBuckets);
      setBuckets(formattedBuckets);
      if (setDefaultBuckets) {
        const defaultBuckets = formattedBuckets.filter(
          (bucket) => config.s3Config.mediaBuckets.default.buckets.indexOf(bucket.bucket) !== -1,
        );
        setValue('buckets', defaultBuckets, { shouldValidate: true });
      }
    }
  }, [buckets, config, setDefaultBuckets, setValue]);

  return (
    <Controller
      name="buckets"
      rules={{ validate: (val) => val?.length > 0 || 'At least 1 bucket is required' }}
      control={control}
      defaultValue={[]}
      render={({ field }) => {
        const { onChange, ...props } = field;
        return (
          <Autocomplete
            options={buckets}
            multiple
            getOptionLabel={(bucket) => bucket.bucket}
            isOptionEqualToValue={(option, value) => option.bucket === value.bucket && option.region === value.region}
            groupBy={(bucket) => bucket.regionLabel}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={`${option.regionLabel} - ${option.bucket}`} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select buckets"
                variant="outlined"
                error={!!errors?.buckets}
                helperText={errors?.buckets ? get(errors, 'buckets.message') : null}
              />
            )}
            onChange={(e, data) => onChange(data)}
            {...props}
          />
        );
      }}
    />
  );
};

export default Buckets;
