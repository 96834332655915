import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import {
  clearTokenCookies,
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setIdToken,
  setRefreshToken,
} from '../api/token';
import { AuthConfig, fetchAuthConfig } from '../api/config';

axios.defaults.withCredentials = true;

export type Get = <T>(path: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;
export type Post = <T>(
  path: string,
  body: Record<string, unknown>,
  config?: AxiosRequestConfig,
) => Promise<AxiosResponse<T>>;
export type Put = <T>(
  path: string,
  body: Record<string, unknown>,
  config?: AxiosRequestConfig,
) => Promise<AxiosResponse<T>>;
export type Delete = <T>(path: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>;

type apiContext = {
  isAuthenticated: boolean;
  config: { url: string; key: string };
  authConfig: AuthConfig;
  logOut: (stayOnLoginPage?: boolean) => void;
  logIn: ({ code, codeVerifier }: { code: string; codeVerifier: string }) => Promise<boolean>;
  get: Get;
  post: Post;
  put: Put;
  delete: Delete;
};

const defaultValue: apiContext = {
  isAuthenticated: true,
  config: { url: process.env.REACT_APP_BACKEND_API_URL || '', key: process.env.REACT_APP_BACKEND_API_KEY || '' },
  authConfig: {} as AuthConfig,
  logOut: () => null,
  logIn: async () => false,
  get: async () => ({} as AxiosResponse<never>),
  post: async () => ({} as AxiosResponse<never>),
  put: async () => ({} as AxiosResponse<never>),
  delete: async () => ({} as AxiosResponse<never>),
};

const ApiContext = createContext<apiContext>(defaultValue);

export type ApiConfig = { key: string; url: string };

const ApiProvider: FunctionComponent = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [config, setConfig] = useState<{ url: string; key: string }>();
  const [authConfig, setAuthConfig] = useState<AuthConfig>();
  const history = useHistory();

  useEffect(() => {
    async function fetchApiConfig(): Promise<ApiConfig> {
      try {
        const { data } = await axios.get<ApiConfig>('/config.json');
        let apiConfig = {
          url: process.env.REACT_APP_BACKEND_API_URL || '',
          key: process.env.REACT_APP_BACKEND_API_KEY || '',
        };
        if (data) {
          apiConfig = data;
        }
        setConfig(apiConfig);
        return apiConfig;
      } catch {
        const apiConfig = {
          url: process.env.REACT_APP_BACKEND_API_URL || '',
          key: process.env.REACT_APP_BACKEND_API_KEY || '',
        };
        setConfig(apiConfig);
        return apiConfig;
      }
    }

    if (!config) {
      fetchApiConfig();
    }
  }, [config]);

  useEffect(() => {
    async function fetchData(apiConfig: { url: string; key: string }) {
      const auth = await fetchAuthConfig(apiConfig);
      setAuthConfig(auth);
    }
    if (config) {
      fetchData(config);
    }
  }, [config]);

  function logOut(stayOnLoginPage = false) {
    if (isAuthenticated) {
      clearTokenCookies();
      setIsAuthenticated(false);
      if (history.location.pathname !== '/login') {
        if (history.location.pathname !== '/') {
          history.push(`/login?previousPath=${history.location.pathname}&stay=${stayOnLoginPage}`);
          return;
        }
        history.push(`/login?stay=${stayOnLoginPage}`);
      }
    }
  }

  async function logIn({ code, codeVerifier }: { code: string; codeVerifier: string }) {
    if (authConfig) {
      try {
        // const formData = new FormData();
        const payload = new URLSearchParams();
        payload.append('grant_type', 'authorization_code');
        payload.append('client_id', authConfig.clientID);

        // if configuration has client secret send it
        if (authConfig.clientSecret) {
          payload.append('client_secret', authConfig.clientSecret);
        }

        payload.append('code_verifier', codeVerifier);
        payload.append('code', code);
        payload.append('redirect_uri', window.location.origin + history.location.pathname);
        const { data } = await axios.post<{
          access_token: string;
          id_token: string;
          expires_in: number;
          refresh_token: string;
          scope: string;
          token_type: string;
        }>(authConfig.tokenEndpoint, payload, {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          withCredentials: true,
        });
        if (data.access_token) {
          setAccessToken(data.access_token, data.expires_in);
          setRefreshToken(data.refresh_token);
          setIdToken(data.id_token);
          setIsAuthenticated(true);
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  async function refreshToken() {
    if (authConfig) {
      const { tokenEndpoint, clientID } = authConfig;
      const token = getRefreshToken();

      if (!tokenEndpoint || !clientID || !token) {
        logOut();
        return false;
      }

      const payloadString = `grant_type=refresh_token&client_id=${clientID}&refresh_token=${token}`;
      try {
        const { data } = await axios.post<{
          access_token: string;
          id_token: string;
          expires_in: number;
          scope: string;
          token_type: 'Bearer';
        }>(tokenEndpoint, payloadString, {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
        });

        setAccessToken(data.access_token, data.expires_in);
        setIdToken(data.id_token);
        return data;
      } catch (err) {
        logOut();
        return null;
      }
    }
  }

  async function getToken() {
    const token = getAccessToken();
    if (!token) {
      const response = await refreshToken();
      if (!response || !response.access_token) {
        logOut(true);
        return false;
      }
      return response.access_token;
    }

    return token;
  }

  async function get<T = never>(path: string, requestConfig?: AxiosRequestConfig) {
    const token = await getToken();
    const requestOptions = {
      ...requestConfig,
      headers: {
        Authorization: `Bearer ${token}`,
        'x-api-key': config?.key || '',
      },
      withCredentials: true,
    };
    const url = `${config?.url}${path}`;
    return axios.get<T>(url, requestOptions);
  }

  async function post<T>(path: string, body: Record<string, unknown>, requestConfig?: AxiosRequestConfig) {
    const token = await getToken();
    const requestOptions = {
      ...requestConfig,
      headers: {
        Authorization: `Bearer ${token}`,
        'x-api-key': config?.key || '',
      },
      withCredentials: true,
    };
    const url = `${config?.url}${path}`;

    return axios.post<T>(url, body, requestOptions);
  }

  async function put<T>(path: string, body: Record<string, unknown>, requestConfig?: AxiosRequestConfig) {
    const token = await getToken();
    const requestOptions = {
      ...requestConfig,
      headers: {
        Authorization: `Bearer ${token}`,
        'x-api-key': config?.key || '',
      },
      withCredentials: true,
    };
    const url = `${config?.url}${path}`;

    return axios.put<T>(url, body, requestOptions);
  }

  async function deleteApi<T>(path: string, requestConfig?: AxiosRequestConfig) {
    const token = await getToken();
    const requestOptions = {
      ...requestConfig,
      headers: {
        Authorization: `Bearer ${token}`,
        'x-api-key': config?.key || '',
      },
      withCredentials: true,
    };
    const url = `${config?.url}${path}`;
    return axios.delete<T>(url, requestOptions);
  }

  if (!config || !authConfig) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <CircularProgress color="primary" aria-label="Loading app" />
      </Box>
    );
  }

  const value: apiContext = {
    isAuthenticated,
    config,
    authConfig,
    logOut,
    logIn,
    get,
    post,
    put,
    delete: deleteApi,
  };

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

function useApi() {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within a ApiProvider');
  }
  return context;
}

export { ApiProvider, useApi, ApiContext };
