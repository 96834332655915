import { FunctionComponent } from 'react';
import { Paper, TableContainer, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Table, Column } from '@nekta-temp/component-library.table';
import { useEncoders, Encoder, useResumeEncoder, usePauseEncoder } from '../../../api/connector';
import { useConfig } from '../../../api/config';
import { useSnackbar } from '../../../contexts/Snackbar';
import Loading from '../../../common/Loading';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  groupSelect: {
    minWidth: 350,
  },
  loadMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
    width: '100%',
  },
}));

function formatBytes(bytes: number) {
  const decimals = 2;

  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}

export const Encoders: FunctionComponent = () => {
  const classes = useStyles();
  const { openSnackbar } = useSnackbar();
  const { isLoading, isError, data } = useEncoders();
  const { data: config, isLoading: configLoading } = useConfig();
  const { mutate: resumeEncoder, isLoading: isResuming } = useResumeEncoder({
    onSuccess: () => openSnackbar('Encoder resumed successfully!'),
    onError: (data) => {
      data?.response?.data?.message
        ? openSnackbar(data.response.data.message)
        : openSnackbar('An unknown error has occured during executing. Plesae try again!');
    },
  });
  const { mutate: pauseEncoder, isLoading: isPausing } = usePauseEncoder({
    onSuccess: () => openSnackbar('Encoder paused successfully!'),
    onError: (data) => {
      data?.response?.data?.message
        ? openSnackbar(data.response.data.message)
        : openSnackbar('An unknown error has occured during executing. Plesae try again!');
    },
  });

  const columns: Column<Encoder>[] = [
    {
      Header: 'Instance Name',
      accessor: 'EC2InstanceId',
    },
    {
      Header: 'Region',
      accessor: 'regionName',
      Cell: ({ value }) => {
        if (value) {
          return config?.awsRegionsMap[value] ? config?.awsRegionsMap[value] : value;
        }
        return '';
      },
    },
    {
      Header: 'Type',
      accessor: 'EC2InstanceType',
    },
    {
      Header: 'Total Memory',
      accessor: 'totalmem',
      Cell: ({ value }) => formatBytes(value),
    },
    {
      Header: 'Free Memory',
      accessor: 'freemem',
      Cell: ({ value }) => formatBytes(value),
    },
    {
      Header: '',
      accessor: 'paused',
      Cell: ({ value, row }) =>
        value ? (
          <Button onClick={() => resumeEncoder(row.original.identifier)} disabled={isResuming}>
            {isResuming ? 'Resuming...' : 'Resume'}
          </Button>
        ) : (
          <Button onClick={() => pauseEncoder(row.original.identifier)} disabled={isPausing}>
            {isPausing ? 'Pausing...' : 'Pause'}
          </Button>
        ),
    },
  ];

  return (
    <div className={classes.container}>
      <Typography variant="h6">Encoders</Typography>
      {isLoading || configLoading ? (
        <Loading aria-label="Loading encoders" />
      ) : (
        <>
          {data?.length ? (
            <TableContainer component={Paper}>
              <Table<Encoder> columns={columns} data={data} />
            </TableContainer>
          ) : (
            <Typography>No connected encoders</Typography>
          )}
          {isError && <Typography>Sorry there was a problem loading the encoders</Typography>}
        </>
      )}
    </div>
  );
};

export default Encoders;
