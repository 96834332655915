import axios, { AxiosError } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { useApi, Get, Put } from '../contexts/Api';

type CommentPlatForm = {
  name: string;
  enabled: boolean;
  enableCompleteMarker: boolean;
  approvalButtonText: string;
};

type Buckets = {
  [key: string]: {
    bucketName: string;
  };
};

type MediaBuckets = {
  default: {
    buckets: string[];
  };
  [key: string]: {
    buckets: string[];
  };
};

type S3Config = {
  conform: {
    approvalDestinationPrefix: string;
    buckets: Buckets;
    submissionDestinationPrefix: string;
  };
  editExtension: string;
  editPrefix: string;
  mediaBuckets: MediaBuckets;
  mediaConvertProxies: {
    minHeight: number;
    reduceSizeOfProxiesBy: number;
  };
  proxies: {
    buckets: Buckets;
  };
  serviceBuckets: Buckets;
  viewingExtension: string;
  viewingPrefix: string;
};

export type Config = {
  help: {
    fileBrowser: string;
    jobs: string;
    projects: string;
    relink: string;
    timeline: string;
    versions: string;
  };
  accountName: string;
  env: string;
  commentsPlatform: CommentPlatForm[];
  s3Config: S3Config;
  awsRegionsMap: Record<string, string>;
  awsRegions: Record<string, string>;
  logoURL?: string;
};

type ConfigResponse = {
  items: Config;
};

async function fetchConfig(get: Get) {
  const { data } = await get<ConfigResponse>('/v2/configuration/frontend');
  return data.items;
}

export function useConfig(): UseQueryResult<Config> {
  const { get } = useApi();
  return useQuery('config', () => fetchConfig(get), { cacheTime: 8.64e7, staleTime: Infinity }); // 24hours cache time
}

type UpdateConfigResponse = { message?: string };
async function updateConfig(put: Put, body: Partial<Config>) {
  const { data } = await put<UpdateConfigResponse>('/admin/configuration/settings', body);
  return data;
}

export function useUpdateConfig(
  options?: UseMutationOptions<UpdateConfigResponse, AxiosError<UpdateConfigResponse>, Partial<Config>, unknown>,
): UseMutationResult<UpdateConfigResponse, AxiosError<UpdateConfigResponse>, Partial<Config>, unknown> {
  const { put } = useApi();
  const queryClient = useQueryClient();
  return useMutation((body) => updateConfig(put, body), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('config');
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

export type AuthConfig = {
  PKCE: boolean;
  authorizationEndpoint: string;
  clientID: string;
  clientSecret?: string;
  code_challenge_method: string;
  currentRegion: string;
  logoutEndpoint: string;
  logoutRedirect: boolean;
  logoutTokenKey: string;
  redirectUrlForPremierePro: string;
  responseType: string;
  scope: string;
  tokenEndpoint: string;
};

export async function fetchAuthConfig({ url, key }: { url: string; key: string }) {
  const { data } = await axios.get<AuthConfig>(`${url}/bootstrap/oauth`, {
    headers: { 'x-api-key': key },
  });
  return data;
}
