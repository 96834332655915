import {
  UseQueryResult,
  useQuery,
  useMutation,
  UseMutationResult,
  useQueryClient,
  UseMutationOptions,
} from 'react-query';
import { useApi, Get, Put, Delete } from '../contexts/Api';

export type Client = {
  EC2InstanceId: null | string;
  EC2InstanceType: null | string;
  EC2IpAddress: null | string;
  EC2RegionName: null | string;
  UUID: string;
  arch: 'x64';
  connectorType: 'premierePro' | 'desktop';
  cpuThreads: number;
  dateAdded: string;
  dateAddedInMicroSeconds: number;
  eventType: string;
  freemem: number;
  hostName: string;
  hostname: string;
  identifier: string;
  ip: string;
  isAuthenticated: boolean;
  jobName?: string;
  jobUUID?: string;
  platform: 'win32';
  projectName?: string;
  projectUUID?: string;
  release: string;
  systemType_client: string;
  totalmem: number;
  type: 'Windows_NT';
  user?: string;
};

export type ClientsResponse = { clients: Client[] };

async function fetchClients(get: Get) {
  const { data } = await get<{ clients: Client[] }>('/connector/clients');
  return data.clients;
}

export function useClients(): UseQueryResult<Client[]> {
  const { get } = useApi();
  return useQuery('clients', () => fetchClients(get), {
    cacheTime: 1000 * 60 * 10, //10mins
    staleTime: 1000 * 60 * 5, //5mins
  });
}

export type Encoder = {
  EC2InstanceId: null | string;
  EC2InstanceType: null | string;
  EC2IpAddress: null | string;
  regionName: null | string;
  UUID: string;
  cpuThreads: number;
  dateAdded: string;
  dateAddedInMicroSeconds: number;
  eventType: string;
  freemem: number;
  hostName: string;
  hostname: string;
  identifier: string;
  ip: string;
  platform: 'win32';
  systemType_client: string;
  totalmem: number;
  type: 'Windows_NT';
  paused: boolean;
  connectorType: string;
  release: string;
  arch: 'x64';
  isEC2: boolean;
};

export type EncodersResponse = { encoders: Encoder[] };

async function fetchEncoders(get: Get) {
  const { data } = await get<EncodersResponse>('/connector/backend');
  return data.encoders;
}

export function useEncoders(): UseQueryResult<Encoder[]> {
  const { get } = useApi();
  return useQuery('encoders', () => fetchEncoders(get), {
    cacheTime: 1000 * 60 * 10, //10mins
    staleTime: 1000 * 60 * 5, //5mins
  });
}

async function pauseEncoder(put: Put, id: string) {
  const { data } = await put<{ message: string }>(`/connector/backend/pause/${id}`, {});
  return data;
}

export function usePauseEncoder(
  options?: UseMutationOptions<{ message: string }, { response?: { data?: { message?: string } } }, string, unknown>,
): UseMutationResult<{ message: string }, unknown, string, unknown> {
  const { put } = useApi();
  const queryClient = useQueryClient();
  return useMutation((id) => pauseEncoder(put, id), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('encoders');
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}

async function resumeEncoder(deleteApi: Delete, id: string) {
  const { data } = await deleteApi<{ message: string }>(`/connector/backend/pause/${id}`);
  return data;
}

export function useResumeEncoder(
  options?: UseMutationOptions<{ message: string }, { response?: { data?: { message?: string } } }, string, unknown>,
): UseMutationResult<{ message: string }, unknown, string, unknown> {
  const queryClient = useQueryClient();
  const { delete: deleteApi } = useApi();
  return useMutation((id) => resumeEncoder(deleteApi, id), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('encoders');
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
  });
}
