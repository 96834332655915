import { Post } from '../../contexts/Api';

export type CreateWorkstationSession = {
  deploymentUUID: string;
  accessType: 'owner' | 'viewer';
  name?: string;
  gatewayUUID?: string;
  instanceID?: string;
};

export async function createWorkstationSession(post: Post, workstation: CreateWorkstationSession): Promise<void> {
  const { data } = await post<BlobPart>('/session/authentication', workstation);

  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `access-${workstation.name ? workstation.name : workstation.accessType}.dcv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
