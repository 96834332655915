import {
  DesktopWindows,
  KeyboardBackspace,
  Search,
  AccessTime,
  Refresh,
  FormatListBulleted,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  TableContainer,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Table, Column } from '@nekta-temp/component-library.table';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LogEvents, useDeploymentLogList, useDeploymentLogs } from '../../../api/deployments';
import Loading from '../../../common/Loading';
import getLocalDateTime from '../../../utils/getLocalDateTime';
import DateTimePickerDialog, { TimeFilter } from '../../insights/DateTimePickerDialog';

type LogParams = {
  title: string;
  deploymentID: string;
  instanceID?: string;
};

export function DeploymentLogs({ deploymentID, title, instanceID }: LogParams) {
  const history = useHistory();
  const [timeFilter, setTimeFilter] = useState<TimeFilter>();
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [openTimeFilter, setOpenTimeFilter] = useState(false);
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [openSelectLogDialog, setOpenSelectLogDialog] = useState(false);
  const [selectedLog, setSelectedLog] = useState<{ value: string; label: string }>();
  const { data: logList } = useDeploymentLogList(deploymentID);
  const {
    data: logs,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    isFetching,
    isError,
    refetch,
  } = useDeploymentLogs({
    deploymentID,
    instanceID,
    logName: selectedLog?.value,
    searchTerm,
    startTime: (timeFilter && 'gte' in timeFilter.filter && timeFilter.filter.gte / 1000) || undefined,
    endTime: (timeFilter && 'lte' in timeFilter.filter && timeFilter.filter.lte / 1000) || undefined,
  });

  const columns: Column<LogEvents>[] = [
    {
      Header: 'Timestamp',
      accessor: 'timestamp',
      Cell: ({ value }) => getLocalDateTime(value, { timeStyle: 'medium', dateStyle: 'short' }),
      defaultCanSort: true,
    },
    {
      Header: 'Message',
      accessor: 'message',
      defaultCanSort: false,
      Cell: ({ value }) => (
        <Typography sx={{ whiteSpace: 'pre' }} variant="subtitle2">
          {value}
        </Typography>
      ),
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  const logEvents = logs?.pages.flatMap((page) => page.events);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
      <Paper
        elevation={0}
        variant="outlined"
        square
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}
      >
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <DesktopWindows />
          <Typography variant="h6">{title} - Logs</Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <IconButton onClick={() => history.goBack()} color="primary" title="Back">
            <KeyboardBackspace />
          </IconButton>
          <IconButton onClick={() => refetch()} color="primary" title="Refresh logs">
            <Refresh />
          </IconButton>
          {selectedLog && (
            <Chip
              label={selectedLog.label}
              icon={<FormatListBulleted fontSize="small" />}
              onClick={() => setOpenSelectLogDialog(true)}
              onDelete={() => setSelectedLog(undefined)}
            />
          )}
          {logList && logList?.length > 0 && !selectedLog && (
            <IconButton onClick={() => setOpenSelectLogDialog(true)} color="primary" title="Select log">
              <FormatListBulleted />
            </IconButton>
          )}
          {searchTerm ? (
            <Chip
              label={searchTerm}
              icon={<Search />}
              onClick={() => setOpenSearchDialog(true)}
              onDelete={() => setSearchTerm(undefined)}
            />
          ) : (
            <IconButton onClick={() => setOpenSearchDialog(true)} color="primary" title="Search">
              <Search />
            </IconButton>
          )}
          {timeFilter ? (
            <Chip
              label={timeFilter.label}
              icon={<AccessTime />}
              onClick={() => setOpenTimeFilter(true)}
              onDelete={() => setTimeFilter(undefined)}
            />
          ) : (
            <IconButton onClick={() => setOpenTimeFilter(true)} color="primary" title="Filter time">
              <AccessTime />
            </IconButton>
          )}
          <Button disabled={!hasPreviousPage || logs?.pages[0].events.length === 0} onClick={() => fetchPreviousPage()}>
            Previous
          </Button>
          <Button
            disabled={!hasNextPage || logs?.pages[logs.pages.length - 1].events.length === 0}
            onClick={() => fetchNextPage()}
          >
            Next
          </Button>
        </Box>
      </Paper>
      {logEvents?.length ? (
        <TableContainer component={Paper} sx={{ position: 'relative', zIndex: 0 }}>
          {isFetching && (
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                width: '100%',
              }}
            >
              <LinearProgress color="primary" />
            </Box>
          )}
          <Box sx={{ zIndex: 0, opacity: isFetching ? 0.5 : 1, overflow: 'auto', height: '100%' }}>
            <Table
              data={logEvents}
              columns={columns}
              tableProps={{ stickyHeader: true }}
              headerProps={{ sx: { backgroundColor: grey[300] } }}
            />
          </Box>
        </TableContainer>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: '1' }}>
          <Typography>
            {isError ? 'Sorry an error has occured loading the logs' : "Sorry we haven't found any logs"}
          </Typography>
        </Box>
      )}

      <DateTimePickerDialog
        open={openTimeFilter}
        onClose={() => setOpenTimeFilter(false)}
        setDateTime={(value) => setTimeFilter(value)}
        alwaysSetLTE
      />
      <Dialog open={openSearchDialog} aria-label="Search log dialog">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const { elements } = e.target as typeof e.target & {
              elements: { search: { value: string } };
            };
            setSearchTerm(elements.search.value);
            setOpenSearchDialog(false);
          }}
        >
          <DialogContent>
            <TextField autoFocus name="search" fullWidth variant="standard" placeholder="Search logs" />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenSearchDialog(false)}>Cancel</Button>
            <Button type="submit">Search</Button>
          </DialogActions>
        </form>
      </Dialog>
      {logList && logList?.length > 0 && (
        <Dialog open={openSelectLogDialog} aria-label="Select log dialog">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const { elements } = e.target as typeof e.target & {
                elements: { log: { value: number } };
              };
              setSelectedLog(logList[elements.log.value]);
              setOpenSelectLogDialog(false);
            }}
          >
            <DialogTitle>Select log group</DialogTitle>
            <DialogContent sx={{ minWidth: '300px' }}>
              <TextField
                id="logList"
                select
                variant="standard"
                label="Log group"
                margin="dense"
                size="small"
                name="log"
                fullWidth
                defaultValue={0}
              >
                {logList.map((log, index) => (
                  <MenuItem key={log.value} value={index}>
                    {log.label}
                  </MenuItem>
                ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenSelectLogDialog(false)}>Cancel</Button>
              <Button type="submit">Select</Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Box>
  );
}

export default DeploymentLogs;
