import { useState, useEffect } from 'react';
import { LinearProgress, Typography, Button, Box } from '@mui/material';

const PPRO_LOGIN_CODE_EXPIRATION_TIME = 180;

function CodeExpiryCountdown({ requestNewCode }: { requestNewCode: () => void }) {
  const [countdown, setCountdown] = useState({ seconds: PPRO_LOGIN_CODE_EXPIRATION_TIME, percentage: 100 });

  useEffect(() => {
    const interval =
      countdown.seconds > 0 &&
      setInterval(() => {
        const secondsRemaining = countdown.seconds - 1;
        setCountdown({
          seconds: secondsRemaining,
          percentage: secondsRemaining / (PPRO_LOGIN_CODE_EXPIRATION_TIME / 100),
        });
      }, 1000);
    return () => {
      interval && clearInterval(interval);
    };
  }, [countdown]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 1,
        gap: 1,
      }}
    >
      <LinearProgress variant="determinate" value={countdown.percentage} sx={{ width: '100%' }} />
      {countdown.seconds !== 0 && (
        <Typography variant="body2" color="textSecondary">
          {`${countdown.seconds} seconds till code expires`}
        </Typography>
      )}
      {countdown.seconds === 0 && (
        <>
          <Typography variant="body2" color="error">
            Code has expired
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              requestNewCode();
              setCountdown({ seconds: PPRO_LOGIN_CODE_EXPIRATION_TIME, percentage: 100 });
            }}
            sx={{ marginTop: 1 }}
          >
            Request new code
          </Button>
        </>
      )}
    </Box>
  );
}

export default CodeExpiryCountdown;
