import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, CircularProgress, MenuItem, TextField, Typography } from '@mui/material';
import { useVpcs } from '../../api/deployments';
import { Form, ActionContainer } from './styledComponents';
import { buttonColor, colors, sizes } from '../../theme/designtoken';

type SelectVpcForm = {
  vpc: string;
};

type SelectVpcComponent = {
  region: string;
  onSuccess: (vpc: string) => void;
  onPrevious: () => void;
};

export function SelectVpc({ region, onSuccess, onPrevious }: SelectVpcComponent) {
  const { data: vpcs, isLoading, isError } = useVpcs(region);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SelectVpcForm>();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError || !vpcs) {
    return <Typography variant="h6">Sorry there has been a problem loading available vpcs</Typography>;
  }

  const onSubmit: SubmitHandler<SelectVpcForm> = async (values) => {
    onSuccess(values.vpc);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="vpc"
        control={control}
        rules={{ required: 'Please select a vpc' }}
        defaultValue=""
        render={({ field }) => (
          <TextField
            id="vpc"
            select
            variant="outlined"
            label="VPC"
            margin="dense"
            {...field}
            error={!!errors?.vpc}
            helperText={errors?.vpc?.message}
          >
            {vpcs.map((vpc) => (
              <MenuItem key={vpc.id} value={vpc.id}>
                {vpc.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
      <ActionContainer>
        <Button variant="outlined" onClick={() => onPrevious()} sx={{ bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }}>
          Back
        </Button>
        <Button type="submit" variant="outlined" sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }}>
          Next
        </Button>
      </ActionContainer>
    </Form>
  );
}

export default SelectVpc;
