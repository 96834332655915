import React, { FunctionComponent } from 'react';
import { InfiniteData } from 'react-query';
import { List, ListItem, ListItemIcon, ListItemText, Card, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  Videocam as VideoIcon,
  Audiotrack as AudioIcon,
  InsertDriveFile as FileIcon,
  Image as ImageIcon,
  Folder as FolderIcon,
} from '@mui/icons-material';
import { BucketResponse, File } from '../../api/files';

function getFileIcon(type: File['mediaType']) {
  if (type === 'audio') {
    return <AudioIcon />;
  }
  if (type === 'video') {
    return <VideoIcon />;
  }
  if (type === 'image') {
    return <ImageIcon />;
  }
  return <FileIcon />;
}

const useStyles = makeStyles((theme) => ({
  listCard: {
    maxHeight: '100%',
    paddingBottom: '16px',
    overflow: 'auto',
  },
  itemDetails: (props: { detailsClickable: boolean }) => ({
    display: 'flex',
    cursor: 'pointer',
    flexGrow: 1,
    alignItems: 'center',
    borderRadius: 4,
    paddingLeft: props.detailsClickable ? 4 : 0,
    '&:hover': {
      backgroundColor: props.detailsClickable ? theme.palette.action.hover : 'transparent',
    },
  }),
}));

export const ListItemClickable: FunctionComponent<{
  clickable: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ clickable, onClick, children }) => {
  if (clickable) {
    return (
      <ListItem button onClick={onClick}>
        {children}
      </ListItem>
    );
  }

  return <ListItem>{children}</ListItem>;
};

export const ListView: FunctionComponent<{
  data: InfiniteData<BucketResponse>;
  onFileClick: (file: File) => void;
  onFolderClick: (folderName: string) => void;
  multiSelect?: {
    onSelect: (key: string) => void;
    onRemove: (key: string) => void;
    isSelected: (key: string) => boolean;
    isSelectable?: (item: string | File) => boolean;
    active: boolean;
  };
}> = ({ data, onFileClick, onFolderClick, multiSelect }) => {
  const classes = useStyles({ detailsClickable: !!multiSelect?.active });
  const isSelectable = multiSelect?.isSelectable || (() => true);
  return (
    <Card className={classes.listCard}>
      <List>
        {data.pages.map((page) => {
          const folders = page.folders.map((folder, index) => {
            const folderPath = folder.split('/').filter((val) => val !== '');
            const folderName = folderPath[folderPath.length - 1];
            return (
              <ListItem
                button={!multiSelect?.active as true}
                onClick={() => !multiSelect?.active && onFolderClick(folderName)}
                key={`${folder}-${index}`}
              >
                {multiSelect?.active && isSelectable(folder) && (
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={multiSelect.isSelected(folder)}
                      onClick={() =>
                        multiSelect.isSelected(folder) ? multiSelect.onRemove(folder) : multiSelect.onSelect(folder)
                      }
                      aria-label={`select ${folderName}`}
                    />
                  </ListItemIcon>
                )}
                <div
                  className={classes.itemDetails}
                  onClick={() => multiSelect?.active && onFolderClick(folderName)}
                  onKeyPress={() => multiSelect?.active && onFolderClick(folderName)}
                  role={multiSelect?.active ? 'button' : 'div'}
                >
                  <ListItemIcon>
                    <FolderIcon />
                  </ListItemIcon>
                  <ListItemText primary={folderName} />
                </div>
              </ListItem>
            );
          });
          const files = page.files.map((file) => (
            <ListItemClickable
              clickable={file.playable && !multiSelect?.active}
              onClick={() => onFileClick(file)}
              key={file.Key}
            >
              {multiSelect?.active && isSelectable(file) && (
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={multiSelect.isSelected(file.Key)}
                    onClick={() =>
                      multiSelect.isSelected(file.Key) ? multiSelect.onRemove(file.Key) : multiSelect.onSelect(file.Key)
                    }
                    aria-label={`select ${file.fileName}`}
                  />
                </ListItemIcon>
              )}
              <div
                className={classes.itemDetails}
                onClick={() => file.playable && multiSelect?.active && onFileClick(file)}
                onKeyPress={() => file.playable && multiSelect?.active && onFileClick(file)}
                role={file.playable && multiSelect?.active ? 'button' : 'div'}
              >
                <ListItemIcon>{getFileIcon(file.mediaType)}</ListItemIcon>

                <ListItemText primary={file.fileName} />
              </div>
            </ListItemClickable>
          ));
          return folders.concat(files);
        })}
      </List>
    </Card>
  );
};

export default ListView;
