import { FunctionComponent } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, TextField, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Job, useEditJob } from '../../../api/job';
import { useSnackbar } from '../../../contexts/Snackbar';

type UpdateProps = {
  job: Job;
  field: 'name' | 'description' | 'estimate';
  onClose: () => void;
  onSuccess: () => void;
};

const fieldMap = {
  name: {
    field: 'jobName',
    title: 'Update Job Name',
    label: 'Name',
    rules: {
      required: 'Please enter a job name',
      maxLength: {
        message: 'Must be less than 40 characters',
        value: 40,
      },
      pattern: {
        message: 'Must only be alphanumberic characters',
        value: RegExp('^([a-zA-Z0-9 _-]+)$'),
      },
    },
    textFieldProps: {},
  },
  description: {
    field: 'description',
    title: 'Update Job Description',
    label: 'Description',
    rules: {
      required: 'Please enter a job description',
      maxLength: {
        message: 'Description must be less than 256 characters',
        value: 256,
      },
    },
    textFieldProps: { multiline: true, rows: 4 },
  },
  estimate: {
    field: 'estimateInDays',
    title: 'Update Job Estimate',
    label: 'Estimate (days)',
    rules: {
      required: 'Please enter an estimate',
    },
    textFieldProps: { type: 'number' },
  },
};

const UpdateFieldDialog: FunctionComponent<UpdateProps> = ({ job, field, onClose, onSuccess }) => {
  const { openSnackbar } = useSnackbar();
  const { mutate, isLoading } = useEditJob({
    onSuccess: () => {
      openSnackbar('Job Updated');
      onSuccess();
    },
    onError: (error) => {
      openSnackbar(error.response?.data.message || 'Failed to update job. Please try again!');
    },
  });
  const fieldDetails = fieldMap[field];
  const defaultValues: Record<string, unknown> = {};
  defaultValues[fieldDetails.field] = job[fieldDetails.field as keyof Job];
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm({ defaultValues });

  const onSubmit = async (values: Record<string, unknown>) => {
    mutate({ id: job.UUID, body: values });
  };

  return (
    <Dialog open={true} fullWidth aria-label="update job dialog">
      <DialogTitle>{fieldDetails.title}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name={fieldDetails.field}
            control={control}
            rules={fieldDetails.rules}
            render={({ field }) => (
              <TextField
                id={fieldDetails.field}
                label={fieldDetails.label}
                variant="outlined"
                error={!!errors?.[fieldDetails.field]}
                helperText={errors?.[fieldDetails.field]?.message}
                fullWidth
                {...fieldDetails.textFieldProps}
                {...field}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" variant="outlined">
            Cancel
          </Button>
          {isLoading ? (
            <div style={{ width: 70, marginLeft: 8, textAlign: 'center' }}>
              <CircularProgress size={24} color="primary" />
            </div>
          ) : (
            <Button type="submit" color="primary" variant="outlined" disabled={!isDirty}>
              Update
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateFieldDialog;
