import React, { FunctionComponent, useState, useEffect } from 'react';
import { InfiniteData } from 'react-query';
import makeStyles from '@mui/styles/makeStyles';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { BucketResponse, File as FileType } from '../../api/files';
import Folder from './Folder';
import File from './File';

const itemWidth = 192;
const itemHeight = 135;
const itemGap = 20;

const useStyles = makeStyles(() => ({
  itemGrid: {
    height: '100%',
    paddingBottom: '16px',
  },
  row: {
    display: 'grid',
    gap: `${20}px`,
    gridTemplateColumns: `repeat(auto-fit, minmax(${itemWidth}px, ${itemWidth}px))`,
  },
}));

export const GridView: FunctionComponent<{
  data: InfiniteData<BucketResponse>;
  onFileClick: (file: FileType) => void;
  onFolderClick: (folderName: string) => void;
  download?: { file?: (file: FileType) => void; folder?: (folderPath: string) => void };
}> = ({ data, onFileClick, onFolderClick, download }) => {
  const classes = useStyles();
  const [items, setItems] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const foldersAndfiles = data?.pages.map((page) => {
      const folders = page.folders.map((folder, index) => {
        const folderPath = folder.split('/').filter((val) => val !== '');
        const folderName = folderPath[folderPath.length - 1];
        return (
          <Folder
            name={folderName}
            path={folder}
            key={`${folder}-${index}`}
            onClick={() => onFolderClick(folderName)}
            download={download?.folder}
          />
        );
      });
      const files = page.files.map((file) => (
        <File file={file} key={file.Key} openPreview={onFileClick} download={download?.file} />
      ));
      return folders.concat(files);
    });
    setItems(foldersAndfiles.flat());
  }, [data, download, onFileClick, onFolderClick]);

  const Row = ({
    data,
    index,
    style,
  }: {
    data: { itemsPerRow: number };
    index: number;
    style: React.CSSProperties;
  }) => {
    const { itemsPerRow } = data;
    const fromIndex = index * itemsPerRow;
    const toIndex = Math.min(fromIndex + itemsPerRow, items?.length);
    const row: JSX.Element[] = [];
    for (let i = fromIndex; i < toIndex; i++) {
      row.push(items[i]);
    }
    return (
      <div style={style} className={classes.row}>
        {row}
      </div>
    );
  };

  return (
    <div className={classes.itemGrid}>
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => {
          const itemsPerRow = Math.floor(width / (itemWidth + itemGap)) || 1;
          const rowCount = Math.ceil(items?.length / itemsPerRow);
          return (
            <>
              <List
                className="List"
                height={height}
                itemCount={rowCount}
                itemSize={itemHeight + itemGap}
                width={width}
                itemData={{ itemsPerRow }}
              >
                {Row}
              </List>
            </>
          );
        }}
      </AutoSizer>
    </div>
  );
};

export default GridView;
