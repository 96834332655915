import { useParams, useHistory } from 'react-router-dom';
import { useDeployment } from '../../../api/deployments';
import Loading from '../../../common/Loading';
import { useSnackbar } from '../../../contexts/Snackbar';
import DeploymentLogs from './DeploymentLogs';

export function AutoscalingInstanceLogs() {
  const { deploymentId, instanceId } = useParams<{ deploymentId: string; instanceId: string }>();
  const { openSnackbar } = useSnackbar();
  const history = useHistory();
  const { data: deployment, isLoading } = useDeployment(deploymentId, true, {
    onError: (err) => {
      if (err.response?.status === 404) {
        openSnackbar("Sorry this deployment doesn't exist", { alert: 'error' });
        history.push('/admin/deployments');
      }
    },
  });

  if (isLoading) {
    return <Loading aria-label="Loading instance logs" />;
  }

  return (
    <DeploymentLogs deploymentID={deploymentId} title={`${deployment?.name} ${instanceId}`} instanceID={instanceId} />
  );
}

export default AutoscalingInstanceLogs;
