import MaterialSkeleton from '@mui/material/Skeleton';

const Skeleton = () => {
  return (
    <>
      <div style={{ marginBottom: 20 }} aria-label="Loading project...">
        <MaterialSkeleton animation="wave" height={200} variant="rectangular" />
      </div>

      <div style={{ marginBottom: 20 }}>
        <MaterialSkeleton animation="wave" height={250} variant="rectangular" />
      </div>

      <div style={{ marginBottom: 20 }}>
        <MaterialSkeleton animation="wave" height={200} variant="rectangular" />
      </div>
    </>
  );
};

export default Skeleton;
