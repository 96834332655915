import axios from 'axios';
import { CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';
import { DeploymentBody, ShutdownPolicy, useCreateDeployment, useDeploymentTemplate } from '../../api/deployments';
import { useSnackbar } from '../../contexts/Snackbar';
import TemplateForm, { TemplateFormProps } from '../TemplateForm';

type SelectTemplateComponent = {
  templateId: string;
  regionName: string;
  vpcId: string;
  shutdownPolicy?: ShutdownPolicy;
  onSuccess: () => void;
  onPrevious: () => void;
};

export function DeploymentTemplateForm({
  onSuccess,
  onPrevious,
  templateId,
  regionName,
  vpcId,
  shutdownPolicy,
}: SelectTemplateComponent) {
  const { data: template, isLoading, isError } = useDeploymentTemplate({ templateId, regionName, vpcId });
  const [formErrors, setFormErrors] = useState<TemplateFormProps['manualErrors']>();
  const { openSnackbar } = useSnackbar();
  const { isLoading: creatingDeployment, mutate: createDeployment } = useCreateDeployment({
    onSuccess: () => onSuccess(),
    onError: (err, variables) => {
      if (axios.isAxiosError(err)) {
        const errData = err?.response?.data;
        if (errData?.field) {
          const previousValue = variables[errData.field];
          if (typeof previousValue === 'object' && 'daysForShutdown' in previousValue) {
            return;
          }
          return setFormErrors([
            {
              message: errData.message,
              field: errData.field,
              previousValue,
              warning: errData.warning,
            },
          ]);
        }
        if (errData?.message) {
          return openSnackbar(errData.message, { alert: 'error' });
        }
      }
      openSnackbar('Sorry an error has occured trying to create the deployment', { alert: 'error' });
    },
  });

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError || !template) {
    return <Typography variant="body1">Sorry there has been a problem loading this template</Typography>;
  }

  const onSubmit: TemplateFormProps['onSubmit'] = async (values, ignoreWarnings) => {
    const body: DeploymentBody = {
      regionName,
      vpcID: vpcId,
      deploymentTemplateUUID: templateId,
      acceptWarning: ignoreWarnings,
      ...values,
    };
    if (shutdownPolicy) {
      body.shutdownPolicy = shutdownPolicy;
    }
    createDeployment(body);
  };

  return (
    <TemplateForm
      fields={template.fields}
      onSubmit={onSubmit}
      onCancel={onPrevious}
      submitButtonText="Create"
      cancelButtonText="Back"
      manualErrors={formErrors}
      disabled={creatingDeployment}
    />
  );
}

export default DeploymentTemplateForm;
