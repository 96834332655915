import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgressWithLabel from '../../../common/CircularProgressWithLabel';
import { useUpload, ProgressUpdate } from '../../../contexts/Upload';

type Props =
  | {
      open: boolean;
      onClose: () => void;
      path: string;
      bucket: string;
    }
  | { open: boolean; onClose: () => void };

function getProgressPercentage(fileSize: number, progress?: ProgressUpdate) {
  if (!progress || !progress.loaded) {
    return 0;
  }
  return (progress.loaded / fileSize) * 100;
}

function UploadProgressDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { fileQueue, clearUploads, isUploading } = useUpload();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>File upload</DialogTitle>
      <DialogContent>
        {fileQueue.length ? (
          <List dense>
            {fileQueue.map(({ file, status }, index) => (
              <ListItem key={file.name + index} sx={{ paddingLeft: 0, gap: 2 }}>
                <ListItemText primaryTypographyProps={{ noWrap: true }} secondary={status?.errorMessage}>
                  {file.name}
                </ListItemText>
                <CircularProgressWithLabel
                  value={getProgressPercentage(file.size, status)}
                  isError={status?.isError || false}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>
            There are no files uploading. To upload a file open the file uploader from the File Browser
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        {!isUploading && fileQueue.length > 0 && <Button onClick={() => clearUploads()}>Clear</Button>}
        <Button onClick={() => onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function UploadDialog(props: Props) {
  const { filesToUpload, fileQueue, isUploading, addFiles, clearUploads, removeFile, startUpload } = useUpload();
  if ('bucket' in props) {
    const { bucket, path, onClose, open } = props;

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>File upload</DialogTitle>
        <DialogContent>
          <Typography>Destination</Typography>
          <Typography sx={{ paddingBottom: 2 }}>{`${bucket}/${path}`}</Typography>
          <input
            hidden={true}
            id="fileInput"
            name="fileInput"
            data-testid="fileInput"
            type="file"
            multiple
            onChange={(event) => {
              event.target.files && addFiles(Array.from(event.target.files));
            }}
          />
          <label htmlFor="fileInput">
            <Button component="span" sx={{ paddingLeft: 0 }}>
              Select files
            </Button>
          </label>
          <input
            hidden={true}
            id="folderInput"
            name="folderInput"
            data-testid="folderInput"
            type="file"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore directory is valid
            webkitdirectory=""
            multiple
            onChange={(event) => {
              event.target.files && addFiles(Array.from(event.target.files));
            }}
          />
          <label htmlFor="folderInput">
            <Button component="span">Select folder</Button>
          </label>
          {fileQueue.length > 0 && !isUploading && <Button onClick={() => clearUploads()}>Clear Uploads</Button>}
          <List dense>
            {fileQueue.map(({ file, status }, index) => (
              <ListItem key={file.name + index} sx={{ paddingLeft: 0, gap: 2 }}>
                <ListItemText
                  primaryTypographyProps={{ noWrap: true }}
                  secondary={status?.errorMessage || file.webkitRelativePath}
                >
                  {file.name}
                </ListItemText>
                <CircularProgressWithLabel value={getProgressPercentage(file.size, status)} isError={status?.isError} />
              </ListItem>
            ))}
            {filesToUpload.map((file, index) => (
              <ListItem key={file.name + index} sx={{ paddingLeft: 0, gap: 2 }}>
                <ListItemText primaryTypographyProps={{ noWrap: true }} secondary={file.webkitRelativePath}>
                  {file.name}
                </ListItemText>
                <IconButton onClick={() => removeFile(index)} title="Remove file">
                  <CancelIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
          <Button disabled={!filesToUpload.length} onClick={() => startUpload({ bucket, path })}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return <UploadProgressDialog open={props.open} onClose={props.onClose} />;
}

export default UploadDialog;
