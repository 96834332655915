import { DateTimePicker, LocalizationProvider, DatePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Button,
} from '@mui/material';
import { isAfter, sub, isEqual, isSameDay } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSnackbar } from '../../contexts/Snackbar';
import getLocalDateTime from '../../utils/getLocalDateTime';
import { buttonColor, colors, sizes } from '../../theme/designtoken';

export type TimeFilter = { label: string; filter: { gte: number } | { lte: number } | { gte: number; lte: number } };

type Props = {
  open: boolean;
  setDateTime: (filter: TimeFilter) => void;
  onClose: () => void;
  defaultPeriod?: Period;
  setInitialValue?: boolean;
  alwaysSetLTE?: boolean; // always set the less than value if the query needs the now date for last month etc.
  dateOnly?: boolean; // only allow users to select dates not time
};

type Period = 'week' | 'month' | 'custom';

const periodLabel = { week: 'Last week', month: 'Last month' };

export function DateTimePickerDialog({
  open,
  onClose,
  setDateTime,
  setInitialValue,
  defaultPeriod,
  alwaysSetLTE,
  dateOnly,
}: Props) {
  const { openSnackbar } = useSnackbar();
  const [locale, setLocale] = useState<Locale>();
  const [period, setPeriod] = useState<Period>(defaultPeriod || 'month');
  const [fromDate, setFromDate] = useState<Date>(sub(new Date(), { months: 1 }));
  const [toDate, setToDate] = useState<Date>(new Date());

  useEffect(() => {
    // dynamically import locale
    const importLocaleFile = async () => {
      try {
        const localeToSet = await import(`date-fns/locale/${navigator.language}/index.js`);
        setLocale(localeToSet.default);
      } catch (_) {}
    };
    if (process.env.NODE_ENV !== 'test') {
      importLocaleFile();
    }
  }, []);

  useEffect(() => {
    if (setInitialValue && defaultPeriod) {
      updateRange(defaultPeriod);
    }
  }, [setInitialValue, defaultPeriod]);

  function updateRange(newPeriod: Period) {
    switch (newPeriod) {
      case 'week':
        setFromDate(sub(new Date(), { weeks: 1 }));
        setToDate(new Date());
        setPeriod(newPeriod);
        break;
      case 'month':
        setFromDate(sub(new Date(), { months: 1 }));
        setToDate(new Date());
        setPeriod(newPeriod);
        break;
      default:
        setPeriod(newPeriod);
    }
  }

  function setTimeRange() {
    if (period !== 'custom' && !alwaysSetLTE) {
      setDateTime({ label: periodLabel[period], filter: { gte: fromDate.getTime() * 1000 } });
      return onClose();
    }
    if (isAfter(fromDate, toDate)) {
      return openSnackbar('To date must be after the from date', { alert: 'error' });
    }
    if (isEqual(fromDate, toDate) || (dateOnly && isSameDay(fromDate, toDate))) {
      return openSnackbar('To date and from date cannot be the same', { alert: 'error' });
    }
    let label = `${getLocalDateTime(fromDate.getTime())} - ${getLocalDateTime(toDate.getTime())}`;
    if (alwaysSetLTE && period !== 'custom') {
      label = periodLabel[period];
    }
    setDateTime({
      label,
      filter: { gte: fromDate.getTime() * 1000, lte: toDate.getTime() * 1000 },
    });
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select a time period</DialogTitle>
      <LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
        <DialogContent sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
          <RadioGroup
            aria-label="time period"
            name="controlled-radio-buttons-group"
            value={period}
            onChange={(event) => updateRange(event.target.value as Period)}
            row
          >
            <FormControlLabel value="week" control={<Radio />} label="Last week" />
            <FormControlLabel value="month" control={<Radio />} label="Last month" />
            <FormControlLabel value="custom" control={<Radio />} label="Custom" />
          </RadioGroup>
          {period === 'custom' && (
            <Box sx={{ display: 'flex', gap: 2 }}>
              {!dateOnly ? (
                <>
                  <DateTimePicker
                    label="From"
                    renderInput={(params) => {
                      return <TextField {...params} />;
                    }}
                    onChange={(date) => date && setFromDate(date)}
                    value={fromDate}
                    disableFuture
                  />
                  <DateTimePicker
                    label="To"
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(date) => date && setToDate(date)}
                    value={toDate}
                  />
                </>
              ) : (
                <>
                  <DatePicker
                    label="From"
                    value={fromDate}
                    onChange={(date) => date && setFromDate(date)}
                    renderInput={(params) => <TextField {...params} />}
                    disableFuture
                  />
                  <DatePicker
                    label="To"
                    value={toDate}
                    onChange={(date) => date && setToDate(date)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button sx={{ marginInline: 'auto', bgcolor: `${buttonColor.bgcolor}`, color: `${colors.backgroundColor}` }} variant="contained" onClick={setTimeRange}>
            Set
          </Button>
        </DialogActions>
      </LocalizationProvider>
    </Dialog>
  );
}

export default DateTimePickerDialog;
