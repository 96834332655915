import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography,
  CircularProgress,
  ListItem,
  ListItemText,
  Collapse,
  List,
  IconButton,
  MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/HighlightOff';
import React, { FunctionComponent } from 'react';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { fetchBucketContents } from '../../api/files';
import { useAddTranscodeJobFiles, useCreateTranscodeJob, useTranscodeOutputs } from '../../api/transcode';
import { useApi, Get } from '../../contexts/Api';
import { useSnackbar } from '../../contexts/Snackbar';
import FileBrowser from '../fileBrowser/FileBrowser';
import { buttonColor, colors } from '../../theme/designtoken';

type CreateTranscodeJobProps = {
  open: boolean;
  onClose: () => void;
};

type CreateTranscodeForm = {
  name: string;
  width: number;
  height: number;
  outputType: string;
};

const useStyles = makeStyles(() => ({
  dimensions: {
    display: 'flex',
    gap: 8,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px 0px 24px',
  },
  fileBrowserDialog: {
    minWidth: '80%',
    minHeight: '80%',
  },
  loadingFiles: {
    display: 'flex',
    gap: 8,
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
  },
  contents: {
    maxHeight: 'calc(100% - 180px)',
    display: 'flex',
    gap: 8,
    flexDirection: 'column',
  },
  maxHeight100: {
    maxHeight: '100%',
  },
}));

async function fetchFolderContents(get: Get, selected: { [key: string]: string[] }) {
  const allFiles: { [key: string]: string[] } = {};

  const result = Object.keys(selected).map(async (bucket) => {
    const folders = selected[bucket].filter((item) => item.charAt(item.length - 1) === '/');
    const files = selected[bucket].filter((item) => item.charAt(item.length - 1) !== '/');
    const promises = folders.map(async (folder) => {
      const result = await fetchBucketContents(get, bucket, folder, '*');
      return result.files;
    });
    const filesFromFolders = await Promise.all(promises);
    const keys = filesFromFolders.flat().map((file) => file.Key);
    const uniqueSet = new Set(keys.concat(files));
    allFiles[bucket] = [...Array.from(uniqueSet)];
  });
  await Promise.all(result);

  return allFiles;
}

const lutFileTypes = ['cube', '3dl'];

export const CreateTranscodeJob: FunctionComponent<CreateTranscodeJobProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const { openSnackbar } = useSnackbar();
  const { get } = useApi();
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: string[] }>({});
  const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: string[] }>({});
  const [selectedLut, setSelectedLut] = useState<{ key: string; bucket: string }>();
  const [step, setStep] = useState(1);
  const [selectedCount, setSelectedCount] = useState(0);
  const [showFiles, setShowFiles] = useState(false);
  const { mutateAsync: createTranscodeJob } = useCreateTranscodeJob();
  const { mutateAsync: addTranscodeJobFiles } = useAddTranscodeJobFiles();
  const { data: outputFormats } = useTranscodeOutputs();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateTranscodeForm>({
    defaultValues: { name: '', height: 1080, width: 1920, outputType: '' },
  });
  const history = useHistory();

  const onSubmit: SubmitHandler<CreateTranscodeForm> = async (data) => {
    try {
      if (Object.keys(selectedFiles).length === 0) {
        return openSnackbar('You must have files selected to transcode');
      }
      const result = await createTranscodeJob({
        ...data,
        lut: selectedLut ? { s3Key: selectedLut.key, bucketName: selectedLut.bucket } : undefined,
      });
      let files: {
        s3Key: string;
        bucketName: string;
      }[] = [];
      Object.keys(selectedFiles).forEach((bucket) => {
        const bucketFiles = selectedFiles[bucket].map((file) => ({ s3Key: file, bucketName: bucket }));
        files = files.concat(bucketFiles);
      });
      await addTranscodeJobFiles({ jobUUID: result.UUID, files });
      openSnackbar('Transcode job created!');
      history.push(`/transcode/job/${result.UUID}`);
    } catch (err) {
      openSnackbar('Sorry there was a problem creating your transcode job');
    }
  };
  async function nextButtonHandler() {
    if (step === 1) {
      setStep(2);
      const files = await fetchFolderContents(get, selectedItems);
      setSelectedFiles(files);
      setStep(3);
    }
    if (step === 4) {
      setStep(3);
    }
  }

  if (step === 1) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xl"
        scroll="paper"
        className={classes.fileBrowserDialog}
        fullWidth
      >
        <div className={classes.title}>
          <Typography variant="h6">Select files to transcode</Typography>
          <Typography variant="h6">{selectedCount} selected items</Typography>
        </div>
        <DialogContent>
          <FileBrowser
            view="list"
            multiSelect={{
              onSelect: (key, bucket) => {
                if (selectedItems[bucket]) {
                  selectedItems[bucket].push(key);
                } else {
                  selectedItems[bucket] = [key];
                }
                setSelectedItems({ ...selectedItems });
                setSelectedCount(selectedCount + 1);
              },
              onRemove: (key, bucket) => {
                const index = selectedItems[bucket].indexOf(key);
                selectedItems[bucket].splice(index, 1);
                setSelectedItems({ ...selectedItems });
                setSelectedCount(selectedCount - 1);
              },
              isSelected: (key, bucket) => selectedItems[bucket]?.includes(key) || false,
              isSelectable: (item) => {
                if (typeof item === 'string') {
                  return true;
                }

                if (item.mediaType === 'video') {
                  return true;
                }
                return false;
              },
              active: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }} onClick={() => onClose()} variant="contained">
            Cancel
          </Button>
          <Button sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }} variant="contained" disabled={selectedCount === 0} onClick={async () => await nextButtonHandler()}>
            Next
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (step === 2) {
    return (
      <Dialog open={open}>
        <DialogContent className={classes.loadingFiles}>
          <CircularProgress size={80} />
          <Typography>Loading selected files</Typography>
        </DialogContent>
      </Dialog>
    );
  }

  if (step === 3) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
        <div className={classes.maxHeight100}>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.maxHeight100}>
            <DialogTitle>Create transcode job</DialogTitle>
            <DialogContent className={classes.contents}>
              <Controller
                name="name"
                control={control}
                rules={{ required: 'Name is required' }}
                render={({ field }) => (
                  <TextField
                    label="Name"
                    id="name"
                    variant="outlined"
                    margin="dense"
                    {...field}
                    error={!!errors?.name}
                    helperText={errors?.name?.message}
                    fullWidth
                  />
                )}
              />
              <div className={classes.dimensions}>
                <Controller
                  name="width"
                  control={control}
                  rules={{ required: 'Width is required' }}
                  render={({ field }) => (
                    <TextField
                      label="Width"
                      id="width"
                      variant="outlined"
                      margin="dense"
                      type="number"
                      {...field}
                      error={!!errors?.width}
                      helperText={errors?.width?.message}
                    />
                  )}
                />

                <Controller
                  name="height"
                  control={control}
                  rules={{ required: 'Height is required' }}
                  render={({ field }) => (
                    <TextField
                      label="Height"
                      id="height"
                      variant="outlined"
                      margin="dense"
                      type="number"
                      {...field}
                      error={!!errors?.height}
                      helperText={errors?.height?.message}
                    />
                  )}
                />
              </div>
              <Controller
                name="outputType"
                control={control}
                rules={{ required: 'Output is required' }}
                render={({ field }) => (
                  <TextField
                    select
                    label="Output"
                    id="output"
                    variant="outlined"
                    margin="dense"
                    type="number"
                    {...field}
                    error={!!errors?.outputType}
                    helperText={errors?.outputType?.message}
                  >
                    {outputFormats?.map((output) => (
                      <MenuItem value={output.value} key={output.value}>
                        {output.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Button variant="outlined" onClick={() => setStep(4)} fullWidth sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }}>
                {selectedLut ? selectedLut.key : 'Select LUT file'}
              </Button>
              <List disablePadding>
                <ListItem button onClick={() => setShowFiles(!showFiles)}>
                  <ListItemText primary="Selected Files" />
                  {showFiles ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={showFiles} timeout="auto">
                  <List disablePadding>
                    {Object.keys(selectedFiles).map((bucket) => {
                      return selectedFiles[bucket].map((file, index) => (
                        <ListItem key={file}>
                          <ListItemText primary={file} />
                          <IconButton
                            onClick={() => {
                              selectedFiles[bucket].splice(index, 1);
                              if (selectedFiles[bucket].length === 0) {
                                delete selectedFiles[bucket];
                              }
                              setSelectedFiles({ ...selectedFiles });
                            }}
                            aria-label={`Remove ${file}`}
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItem>
                      ));
                    })}
                  </List>
                </Collapse>
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setStep(1)} variant="contained" sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }}>
                Back
              </Button>
              <Button type="submit" variant="contained" disabled={isSubmitting} sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }}>
                {isSubmitting ? 'Creating...' : 'Create'}
              </Button>
            </DialogActions>
          </form>
        </div>
      </Dialog>
    );
  }

  if (step === 4) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xl"
        scroll="paper"
        className={classes.fileBrowserDialog}
        fullWidth
      >
        <DialogTitle>Select LUT file</DialogTitle>
        <DialogContent>
          <FileBrowser
            view="list"
            multiSelect={{
              onSelect: (key, bucket) => {
                setSelectedLut({ key, bucket });
              },
              onRemove: () => {
                setSelectedLut(undefined);
              },
              isSelected: (key, bucket) => selectedLut?.key === key && selectedLut?.bucket === bucket,
              isSelectable: (item) => {
                if (typeof item === 'string') {
                  return false;
                }

                const keyParts = item.fileName.split('.');
                if (lutFileTypes.includes(keyParts[keyParts.length - 1])) {
                  return true;
                }
                return false;
              },
              active: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStep(3)} variant="contained" sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }}>
            Back
          </Button>
          <Button variant="contained" disabled={selectedCount === 0} onClick={async () => await nextButtonHandler()} sx={{ marginLeft: 'auto', bgcolor: `${buttonColor.bgcolor}`,  color: `${colors.backgroundColor}` }}>
            Next
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return null;
};

export default CreateTranscodeJob;
