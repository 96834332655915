import { ListItem, ListItemText, Chip, Typography, Popover, Box } from '@mui/material';
import PlayIcon from '@mui/icons-material/PlayCircleFilled';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { FunctionComponent, useState, useEffect } from 'react';
import { startCase } from 'lodash';
import { useRunTranscodeJobFile, TranscodeJobFile as File, useTranscodeJobFile } from '../../../api/transcode';
import { useSnackbar } from '../../../contexts/Snackbar';
import CircularProgressWithLabel from '../../../common/CircularProgressWithLabel';

export const FileStatus: FunctionComponent<{ originalFile: File; updatingFile?: File; refetch: () => void }> = ({
  originalFile,
  updatingFile,
  refetch,
}) => {
  const file = updatingFile || originalFile;
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLSpanElement) | null>(null);
  const open = Boolean(anchorEl);
  const hasStatusDetails = file.statusDetails && file.statusDetails.length > 0;

  const handlePopoverOpen: React.MouseEventHandler<HTMLSpanElement> = (event) => {
    if (!hasStatusDetails) {
      return;
    }
    if (updatingFile && event.currentTarget) {
      refetch();
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography
        aria-owns={open ? 'status-details-popover' : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
        sx={{ cursor: hasStatusDetails ? 'pointer' : 'default', display: 'flex', gap: 1 }}
      >
        {startCase(file.transcodeStatus)} {hasStatusDetails && <InfoIcon />}
      </Typography>
      <Popover
        id="status-details-popover"
        data-testid="status-details-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: '12px' }}>
          {file.statusDetails.map((status) => (
            <Box
              sx={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'space-between' }}
              key={`${file.UUID}${status.label}`}
            >
              <Typography>{startCase(status.label)} </Typography>
              <CircularProgressWithLabel value={status.statusPercent || 0} isError={status.statusTag === 'failed'} />
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export const TranscodeJobFile: FunctionComponent<{ file: File; jobId: string }> = ({ file, jobId }) => {
  const { openSnackbar } = useSnackbar();
  const [getStatusUpdates, setGetStatusUpdates] = useState(false);
  const [refetchInterval, setRefetchInterval] = useState(15000);

  const { mutate: runFileTranscode, isLoading: isLoadingFileTranscode } = useRunTranscodeJobFile({
    onSuccess: () => {
      openSnackbar('Transcode job started on file successfully');
      setGetStatusUpdates(true);
    },
    onError: () => openSnackbar('Failed to start file transcode job'),
  });
  const { data, refetch } = useTranscodeJobFile({
    jobId,
    fileId: file.UUID,
    enabled: getStatusUpdates,
    refetchInterval,
  });

  useEffect(() => {
    if (data?.transcodeStatus !== 'inProgress' && data?.transcodeStatus !== 'addedToQueue') {
      setGetStatusUpdates(false);
    }
    if (data?.transcodeStatus === 'inProgress') {
      setRefetchInterval(5000);
    }
  }, [data]);

  useEffect(() => {
    if (file.transcodeStatus === 'inProgress') {
      setRefetchInterval(5000);
      setGetStatusUpdates(true);
    }
    if (file.transcodeStatus === 'addedToQueue') {
      setRefetchInterval(30000);
      setGetStatusUpdates(true);
    }
  }, [file.transcodeStatus]);

  return (
    <ListItem
      key={file.UUID}
      ContainerProps={{ 'aria-label': file.s3Key }}
      data-testid={file.s3Key}
      sx={{ display: 'grid', gridTemplateColumns: '0.8fr 0.2fr 71px' }}
    >
      <ListItemText primary={file.s3Key.split('/').pop()} secondary={file.fullS3Key} />
      <FileStatus originalFile={file} updatingFile={data} refetch={refetch} />
      <Chip
        variant="outlined"
        onClick={() =>
          runFileTranscode({
            jobId,
            jobFileId: file.UUID,
            s3Key: file.s3Key,
            bucketName: file.bucketName,
          })
        }
        label="Run"
        title="Run transcode job on file"
        avatar={<PlayIcon />}
        disabled={
          isLoadingFileTranscode || file.transcodeStatus === 'inProgress' || file.transcodeStatus === 'addedToQueue'
        }
      />
    </ListItem>
  );
};

export default TranscodeJobFile;
